import { Second, Time24HourLeadingZero } from "@utils/dateTime";
import clsx from "clsx";

type Props = {
  id: string;
  label: string;
  step?: Second; // Set to 60 or greater to only allow minutes.
  time: Time24HourLeadingZero;
  required?: boolean;
  onChange: (time: string) => void;
  onLabelClick?: () => void;
  className?: string;
};
/**
 * Uses the native time input element.
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
 */
export function TimePicker({
  id,
  label,
  step = 1,
  time,
  required = false,
  onChange,
  onLabelClick,
  className,
}: Props) {
  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700"
        onClick={onLabelClick}
      >
        {label}
        {required && (
          <span className="ml-1 text-gray-500 text-sm font-light">*</span>
        )}
      </label>
      <input
        className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        id={id}
        type="time"
        step={step}
        value={time}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}
