export enum AlertType {
  Success = "success",
  Failure = "failure",
  Info = "info",
}

interface Props {
  type: AlertType;
  title: string;
  text: string;
}

type ColorScheme = {
  dark: string;
  mid: string;
  light: string;
  text: string;
};

export const CustomAlert = ({ type, title, text }: Props) => {
  let colorScheme: ColorScheme = {
    dark: "bg-indigo-900",
    mid: "bg-indigo-800",
    light: "bg-indigo-500",
    text: "bg-indigo-100",
  };

  switch (type) {
    case AlertType.Success:
      colorScheme = {
        dark: "bg-green-900",
        mid: "bg-green-800",
        light: "bg-green-500",
        text: "text-green-100",
      };
      break;
    case AlertType.Info:
      colorScheme = {
        dark: "bg-yellow-900",
        mid: "bg-yellow-800",
        light: "bg-yellow-500",
        text: "text-yellow-100",
      };
      break;
    case AlertType.Failure:
      colorScheme = {
        dark: "bg-red-900",
        mid: "bg-red-800",
        light: "bg-red-500",
        text: "text-red-100",
      };
      break;
  }

  return (
    <div className={`${colorScheme.dark} text-center py-4 lg:px-4 rounded-xl`}>
      <div
        className={`p-2 ${colorScheme.mid} items-center ${colorScheme.text} leading-none lg:rounded-full flex lg:inline-flex`}
        role="alert"
      >
        <span
          className={`flex rounded-full ${colorScheme.light} uppercase px-2 py-1 text-xs font-bold mr-3`}
        >
          {title}
        </span>
        <span className="mr-2 text-left flex-auto text-sm">{text}</span>
      </div>
    </div>
  );
};
