import clsx from "clsx";
import { useState } from "react";
import { Icon } from "../Icon";

type Props = { copyValue: string; label?: string };

export const MinimalCopyButton = ({ copyValue, label = "" }: Props) => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <button
      className="relative flex items-center cursor-pointer"
      data-bs-toggle="tooltip"
      onClick={() => {
        navigator.clipboard.writeText(copyValue);
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 600);
      }}
    >
      <Icon
        size={5}
        icon={showSuccess ? "copySuccess" : "copy"}
        color={
          showSuccess ? "text-emerald-700" : "text-gray-500 hover:text-gray-800"
        }
      />

      <div
        className={clsx(
          "text-slate-400 text-xs",
          "absolute transition left-[-10px] w-fit h-fit",
          showSuccess ? "top-[-17px] opacity-1" : "top-[-30px] opacity-0"
        )}
      >
        Copied
      </div>
      <span className="ml-1 text-xs text-gray-500">{label}</span>
    </button>
  );
};
