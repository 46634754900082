import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon } from "../Icon";

type Props = {
  hasItems: boolean;
  noItemsText: string;
  items: React.ReactNode;
  maxHeight?: string;
  className?: string;
  minHeight?: string;
  noItemsClassName?: string;
  label?: React.ReactNode;
  itemsContainerClassName?: string;
  onClick?: () => void;
};

export const OptionsArrayContainer = ({
  onClick,
  hasItems,
  label,
  items,
  noItemsText,
  noItemsClassName,
  itemsContainerClassName,
  className,
  maxHeight,
  minHeight,
}: Props) => {
  return (
    <div
      className={clsx("flex flex-col w-full h-auto", className)}
      onClick={() => onClick && onClick()}
    >
      {label && (
        <div className="text-md font-medium text-gray-600 mb-[3px]">
          {label}
        </div>
      )}
      <div
        className={clsx(
          "relative h-auto overflow-hidden w-full border border-gray-300",
          "rounded-md min-w-[150px] bg-slate-50 group",
          onClick &&
            "hover:border-slate-400 hover:bg-white bg-white/80 cursor-pointer"
        )}
      >
        <div
          className={clsx(
            "flex relative h-full w-full rounded-md min-w-[150px]"
          )}
        >
          {onClick && (
            <div
              className={clsx(
                "absolute flex justify-center items-center right-1 top-1 z-[10] p-1",
                "bg-blue-500/20 group-hover:bg-blue-500 rounded-full"
              )}
            >
              <Icon icon="edit" size={4} color="text-white" />
            </div>
          )}
          {hasItems ? (
            <div
              className={clsx(
                "relative flex flex-wrap gap-1 p-1 h-full w-full text-sm",
                "overflow-y-auto overflow-x-hidden min-h-[33px]",
                hasItems ? maxHeight ?? "max-h-[120px]" : minHeight,
                getScrollbarStyle("gray"),
                itemsContainerClassName
              )}
            >
              {items}
            </div>
          ) : (
            <div
              className={clsx(
                "flex pl-3 items-center justify-center text-sm text-gray-500",
                "w-full text-center flex-wrap leading-4",
                minHeight ?? "h-[33px]",
                onClick && "cursor-pointer group-hover:text-slate-700",
                noItemsClassName
              )}
            >
              {noItemsText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
