import { config } from "config";
import cookie from "cookie";
import JsCookie from "js-cookie";
import { GetServerSidePropsContext } from "next";
import { getOrigin } from "./browser";

/**
 * js-cookie with customized read and write functions to handle objects by
 * converting to/from JSON strings.
 * The use of a prefix of `j:` is a standard used by cookie-parser, the
 * expressjs middleware used on the GraphQL side.
 * https://github.com/expressjs/cookie-parser#cookieparsersecret-options
 *
 * After importing this function, use like normal js-cookie, using the
 * set/get/remove functions.
 */
export const Cookies = JsCookie.withConverter({
  read: (value, name) => {
    const readValue = JsCookie.converter.read(value, name);
    return value.slice(0, 2) === "j:" ? readValue.slice(2) : readValue;
  },
  write: (value, name) => {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed !== "object") {
        throw new Error();
      }
      return JsCookie.converter.write("j:" + JSON.stringify(parsed), name);
    } catch (_) {}
    return JsCookie.converter.write(value, name);
  },
});

/**
 * Set cookie attributes that make cookies compatible with Apollo Studio in
 * local dev mode. For deployed tiers, utilize the correct env var.
 *
 * @param domain offered as an override, necessary to clear legacy cookies.
 *
 * @returns
 */
export function getCookieOptions(domain = config.cookieDomain) {
  const localDevMode = getOrigin().includes("localhost");
  const options: Cookies.CookieAttributes = localDevMode
    ? { sameSite: "none", secure: true }
    : { sameSite: "strict", domain };
  return options;
}

/**
 * A note on cookie removal:
 * One of the strangest aspects of Cookies is that it doesn't allow you to
 * remove a cookie by the name alone. No, you need to specify the same
 * "attributes" that were used in its creation.
 *
 * We've decided to update the attribute "domain" of our cookies to work
 * better, so, as a result, we need to handle both the "legacy" and "new"
 * versions of our cookies.
 *
 * So, we need to remove the "new" version and the "legacy" versions.
 *
 * We probably need to do this for the next 365 days. So, if you're reading this
 * in March 2024 you can probably some of the code specified below along with
 * the Doppler variable NEXT_PUBLIC_WEB_COOKIE_DOMAIN_LEGACY. Remove
 * `legacyCookieDomain` as well.
 */

export const removeCognitoCookies = () => {
  for (const [key] of Object.entries(JsCookie.get())) {
    if (key.includes("Cognito")) {
      JsCookie.remove(key, {
        domain: config.cookieDomain,
        secure: config.cookieSecure,
        path: "/",
      });

      // Remove the legacy cookie style (using the legacy cookie domain).
      // If you're reading this after March 2024 you can probably remove the code below.
      JsCookie.remove(key, {
        domain: config.legacyCookieDomain,
        secure: config.cookieSecure,
        path: "/",
      });
    }
  }
};

export const removeSpoofCookie = () => {
  JsCookie.remove("spoof-user", getCookieOptions());

  // Remove the legacy cookie style (found using getOrigin() and some regex).
  // If you're reading this after March 2024 you can probably remove the code below.
  JsCookie.remove(
    "spoof-user",
    getCookieOptions(
      getOrigin().replace(/^.*?([^\.]+?\.live|localhost).*$/, "$1")
    )
  );
};

export const removeCognitoCookiesServerSide = (
  context: GetServerSidePropsContext
) => {
  const cookies = cookie.parse(context.req.headers.cookie || "");
  for (const key in cookies) {
    if (key.includes("Cognito")) {
      context.res.setHeader(
        "Set-Cookie",
        `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
      );
    }
  }
};
