import clsx from "clsx";
import { Icon } from "../Icon";
import { Badge } from "./Badge";

type Props = { className?: string };

export const TestingUseOnlyBadge = ({ className }: Props) => (
  <Badge
    className={clsx(
      "text-amber-900 select-none whitespace-nowrap gap-1 bg-amber-200",
      className
    )}
  >
    <Icon icon="testing" size={3} color="text-amber-900" />
    Test
  </Badge>
);
