import { assertUnreachable } from "@utils/types";
import { AbsentType, GradeType } from "./types";

export const absentKeys: AbsentType[] = [
  "readingAbsentFromAssessment",
  "languageArtsAbsentFromAssessment",
  "classworkAbsentFromAssessment",
];

export const gradeKeys: GradeType[] = [
  "readingGrade",
  "languageArtsGrade",
  "classworkGrade",
  "participationGrade",
];

export const GRADE_TYPE_TO_ABSENT_TYPE_MAP: Record<
  GradeType,
  AbsentType | null
> = {
  participationGrade: null,
  languageArtsGrade: "languageArtsAbsentFromAssessment",
  readingGrade: "readingAbsentFromAssessment",
  classworkGrade: "classworkAbsentFromAssessment",
};

export const elaGradeOptions = gradeKeys.filter((gradeKey) => {
  switch (gradeKey) {
    case "readingGrade":
    case "languageArtsGrade":
    case "participationGrade":
      return true;
    case "classworkGrade":
      return false;
    default:
      assertUnreachable(gradeKey, "gradeKey");
  }
});

export const generalGradeOptions = gradeKeys.filter((gradeKey) => {
  switch (gradeKey) {
    case "classworkGrade":
    case "participationGrade":
      return true;
    case "readingGrade":
    case "languageArtsGrade":
      return false;
    default:
      assertUnreachable(gradeKey, "gradeKey");
  }
});

export const gradeTitles: Record<GradeType, string> = {
  readingGrade: "Reading",
  languageArtsGrade: "Language Arts",
  classworkGrade: "Classwork",
  participationGrade: "Participation",
};
