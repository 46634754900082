export enum UserEventFilter {
  Teaching = "Teaching",
  InstructionalSupport = "Substituting",
  Mentoring = "Mentoring",
}

export enum EngagementEventFilter {
  Archived = "Show Archived Cohorts",
}

export type EventFilters = UserEventFilter | EngagementEventFilter;

export type EventFilterOptions = { [key in EventFilters]?: boolean };
