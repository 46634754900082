import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { format } from "date-fns";
import { OptionsArrayContainer } from "../Inputs/OptionsArrayContainer";

type Props = {
  excludedDays: Date[];
  noDaysMessage: string;
  onClick?: () => void;
};

export const ExcludedDaysScrollContainer = ({
  excludedDays,
  noDaysMessage,
  onClick,
}: Props) => {
  const renderExcludedDayItems = excludedDays
    .sort((a, b) => a.getTime() - b.getTime())
    .map((day, index) => (
      <span
        key={index}
        className={clsx(
          "justify-center !w-[100px]",
          optionsArrayContainerItemStyle("edit")
        )}
      >
        {format(day, "MMM d, yyyy")}
      </span>
    ));

  return (
    <OptionsArrayContainer
      onClick={onClick}
      noItemsText={noDaysMessage}
      hasItems={excludedDays.length > 0}
      items={renderExcludedDayItems}
    />
  );
};
