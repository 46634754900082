import clsx from "clsx";
import { Tooltip } from "../Tooltip";

type Props = {
  toggleState: boolean;
  handleToggle: (toggleState: boolean) => void;
  disabled?: boolean;
  className?: string;
  label?: string;
  tooltip?: React.ReactNode;
  onIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  bgColor?: string;
  height?: number;
  width?: number;
  tooltipLocation?: "right" | "left";
};

export const ToggleIconButton = ({
  toggleState,
  handleToggle,
  disabled = false,
  className,
  label,
  tooltip,
  onIcon,
  offIcon,
  bgColor = "bg-blue-500",
  height = 28,
  width = 58,
  tooltipLocation = "left",
}: Props) => {
  const renderTooltip = (
    <Tooltip
      className="w-5 h-5 text-blue-600 cursor-help"
      content={<div className="block w-48 text-sm text-center">{tooltip}</div>}
      tooltipProps={{
        place: "bottom",
      }}
    />
  );

  return (
    <div
      className={clsx(
        "flex items-center gap-2",
        disabled && "opacity-50",
        className
      )}
      style={{ height: `${height}px` }}
    >
      {label && <span>{label}</span>}
      {tooltip && tooltipLocation === "left" && renderTooltip}
      <div
        className={clsx(
          "h-full relative rounded-full",
          "transition-color duration-300 ease-in-out",
          toggleState ? bgColor : "bg-gray-300",
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
        style={{ width: `${width}px` }}
        onClick={() => !disabled && handleToggle(!toggleState)}
      >
        <div
          className={clsx(
            "absolute rounded-full bg-white top-[1px]",
            "transition-left duration-300 ease-in-out"
          )}
          style={{
            height: `${height - 2}px`,
            width: `${height - 2}px`,
            left: `${toggleState ? width - height + 1 : 1}px`,
          }}
        />
        {offIcon && (
          <div
            className={clsx(
              "absolute left-0",
              "transition-color duration-300 ease-in-out",
              "flex items-center justify-center",
              toggleState ? "text-white/[0.6]" : "text-slate-500"
            )}
            style={{
              height: `${height}px`,
              width: `${height}px`,
            }}
          >
            <div className="flex relative items-center justify-center w-[55%] h-[55%]">
              {offIcon}
            </div>
          </div>
        )}

        {onIcon && (
          <div
            className={clsx(
              "absolute right-0",
              "transition-color duration-300 ease-in-out",
              "flex items-center justify-center",
              toggleState ? "text-slate-900" : "text-slate-900"
            )}
            style={{
              height: `${height}px`,
              width: `${height}px`,
            }}
          >
            <div className="flex relative items-center justify-center w-[50%] h-[50%]">
              {onIcon}
            </div>
          </div>
        )}
      </div>
      {tooltip && tooltipLocation === "right" && renderTooltip}
    </div>
  );
};
