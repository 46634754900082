import clsx from "clsx";

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  rounded?: "left" | "right" | "none";
  currentPage?: boolean;
  children: React.ReactNode;
};

export const PageButton = ({
  onClick,
  disabled = false,
  rounded = "none",
  currentPage = false,
  children,
}: Props) => (
  <button
    onClick={onClick}
    disabled={disabled || currentPage}
    className={clsx(
      "relative inline-flex items-center px-4 py-2 text-sm font-semibold",
      rounded === "left"
        ? "rounded-l-md"
        : rounded === "right"
        ? "rounded-r-md"
        : null,
      disabled ? "text-gray-600" : "text-gray-900",
      disabled && !currentPage && "hover:bg-gray-50 cursor-not-allowed",
      currentPage
        ? "z-10 bg-blue-500 text-white cursor-default"
        : "text-gray-900 hover:bg-gray-50"
    )}
    type="button"
  >
    {children}
  </button>
);
