import clsx from "clsx";
import { useState } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

export type Filter = {
  name: string;
  selected: boolean;
  show?: boolean;
  tooltip: React.ReactNode;
  onClick: (newState: boolean) => void;
};

type Props = { filters: Filter[] };

export const Filters = ({ filters }: Props) => {
  const [showFilters, setShowFilters] = useState(true);

  const renderFilter = (filter: Filter) => (
    <Tooltip
      key={filter.name}
      content={
        <div className="flex flex-col w-fit flex-wrap text-center z-[4000]">
          {filter.tooltip}
        </div>
      }
      tooltipProps={{ delayShow: 700 }}
    >
      <div
        className={clsx(
          "flex items-center justify-center text-center whitespace-nowrap h-7 px-3 w-fit",
          "rounded-lg cursor-pointer z-70",
          filter.selected
            ? "bg-slate-500 text-white"
            : "bg-slate-100 hover:bg-slate-200"
        )}
        onClick={() => filter.onClick(!filter.selected)}
      >
        {filter.name}
      </div>
    </Tooltip>
  );

  return (
    <div className="relative flex items-center justify-end h-full w-fit gap-x-[6px] text-sm text-slate-700 z-[60]">
      {showFilters &&
        filters
          .filter(({ show }) => show ?? true)
          .map((filter) => renderFilter(filter))}
      <div
        className="cursor-pointer"
        onClick={() => setShowFilters(!showFilters)}
      >
        <Icon icon="filters" color="text-slate-500" />
      </div>
    </div>
  );
};
