import { AdminMode } from "types/global";
import { localStorageUtils } from "./browser";
import { AccountAccessRole } from "./routes/types";

const ADMIN_MODE_STORAGE_KEY = "adminMode";
const ACCESS_ROLE_STORAGE_KEY = "accessRole";

// ADMIN MODE STORAGE

const getAdminModeKey = () => {
  const adminMode = localStorageUtils.getItem(ADMIN_MODE_STORAGE_KEY);
  return adminMode ? (adminMode as AdminMode) : null;
};

const setAdminModeKey = (mode: AdminMode) =>
  localStorageUtils.setItem(ADMIN_MODE_STORAGE_KEY, mode);

const removeAdminModeKey = () => {
  localStorageUtils.removeItem(ADMIN_MODE_STORAGE_KEY);
};

// ACCOUNT ACCESS ROLE STORAGE

const getAccessRoleKey = () => {
  const accountAccessRole = localStorageUtils.getItem(ACCESS_ROLE_STORAGE_KEY);
  return accountAccessRole ? (accountAccessRole as AccountAccessRole) : null;
};

const setAccessRoleKey = (mode: AccountAccessRole) =>
  localStorageUtils.setItem(ACCESS_ROLE_STORAGE_KEY, mode);

const removeAccessRoleKey = () => {
  localStorageUtils.removeItem(ACCESS_ROLE_STORAGE_KEY);
};

const authLocalStorage = {
  getAdminModeKey,
  setAdminModeKey,
  removeAdminModeKey,

  getAccessRoleKey,
  setAccessRoleKey,
  removeAccessRoleKey,
};

export default authLocalStorage;
