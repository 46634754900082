import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import clsx from "clsx";
import pluralize from "pluralize";
import { Button } from "../Buttons/Button";

type Props = {
  pageIndex: number;
  pageCount: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  totalResultCount: number;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
};

export const TablePagination = ({
  totalResultCount,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
}: Props) => (
  <div
    className={clsx(
      "py-1 px-3 flex items-center rounded-b-lg border min-h-[40px] justify-between border-gray-200 bg-white"
    )}
  >
    <div className="flex-1 flex justify-between sm:hidden">
      <Button
        height="sm"
        theme="tertiary"
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Previous
      </Button>
      <Button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        Next
      </Button>
    </div>
    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Page <span className="font-medium">{pageIndex + 1}</span> of{" "}
          <span className="font-medium">{pageCount === 0 ? 1 : pageCount}</span>
          <span className="text-gray-500 ml-3">
            ({pluralize("result", totalResultCount, true)})
          </span>
        </p>
      </div>
      <div>
        <nav
          className="relative z-0 inline-flex rounded-md gap-x-3"
          aria-label="Pagination"
        >
          <Button
            height="sm"
            theme="tertiary"
            disabled={!canPreviousPage}
            onClick={() => gotoPage(0)}
          >
            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
          </Button>

          <Button
            height="sm"
            theme="tertiary"
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          >
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            Previous
          </Button>

          <Button
            height="sm"
            theme="tertiary"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
          <Button
            height="sm"
            theme="tertiary"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
        </nav>
      </div>
    </div>
  </div>
);
