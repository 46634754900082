// query for data for the user's courses

import { gql } from "@apollo/client";
import { PdCourseTable_CoursesFragment } from "@generated/graphql";
import { normalizeToSystemDate } from "@utils/dateTime";
import { Routes } from "@utils/routes";
import { Icon, Link, PDCourseStatusBadge, Table } from "components/shared";
import { CellProps, Column } from "react-table";

PDCourseTable.fragments = {
  courses: gql`
    fragment PDCourseTable_Courses on PDCourse {
      id
      enrolledOn
      dueOn
      courseName
      courseLink
      type
      details {
        cohorts {
          id
          name
        }
        engagements {
          id
          name
        }
      }
      status
    }
  `,
};

type Props = {
  courses: PdCourseTable_CoursesFragment[];
  isMyPD: boolean;
};

export function PDCourseTable({ courses, isMyPD }: Props) {
  const table = (
    <Table
      columns={getColumns(isMyPD)}
      data={courses ?? []}
      loading={false}
      emptyIcon={<Icon icon="teacher" size={8} />}
      dataName="Professional Development Courses"
      cellPadding="px-3 py-2"
      showPagination={false}
      className="min-h-0"
    />
  );

  return <div>{table}</div>;
}

const getColumns = (
  isMyPD: boolean
): Column<PdCourseTable_CoursesFragment>[] => [
  {
    Header: "Enrolled On",
    Cell: ({
      row: {
        original: { enrolledOn },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => (
      <div className="flex gap-2 items-center">
        {new Date(enrolledOn).toLocaleDateString()}
      </div>
    ),
  },
  {
    Header: "Due On",
    Cell: ({
      row: {
        original: { dueOn },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => (
      <div className="flex gap-2 items-center">
        {dueOn
          ? normalizeToSystemDate(new Date(dueOn)).toLocaleDateString()
          : null}
      </div>
    ),
  },
  {
    Header: "Course",
    Cell: ({
      row: {
        original: { courseName, courseLink },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => (
      <div className="flex gap-2 items-center">
        {courseLink ? (
          <Link href={courseLink} target="_blank">
            {courseName}
          </Link>
        ) : (
          courseName
        )}
      </div>
    ),
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: ({
      row: {
        original: { type },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => (
      <div className="flex gap-2 items-center">{type}</div>
    ),
  },
  {
    Header: "Details",
    Cell: ({
      row: {
        original: { details },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => {
      if (!details) {
        return null;
      }

      const cohorts = details.cohorts;
      const engagements = details.engagements;

      const cohortNames = cohorts.map((cohort) => cohort.name).join(", ");
      const engagementNames = engagements
        .map((engagement) => engagement.name)
        .join(", ");

      if (isMyPD) {
        return (
          <div className="flex gap-2 items-center">
            {cohortNames}
            {engagementNames}
          </div>
        );
      }

      const cohortLinks = cohorts.map((cohort, index) => (
        <span key={cohort.id}>
          <Link
            route={Routes.cohort.details}
            routeProps={[cohort.id]}
            key={cohort.id}
            target="_blank"
          >
            {cohort.name}
          </Link>
          {index !== cohorts.length - 1 && ", "}
        </span>
      ));

      const engagementLinks = engagements.map((engagement, index) => (
        <span key={engagement.id}>
          <Link
            route={Routes.engagement.details}
            routeProps={[engagement.id]}
            target="_blank"
          >
            {engagement.name}
          </Link>
          {index !== engagements.length - 1 && ", "}
        </span>
      ));

      return (
        <div className="flex gap-2 items-center">
          {cohortLinks}
          {engagementLinks}
        </div>
      );
    },
  },
  {
    Header: "Status",
    Cell: ({
      row: {
        original: { status },
      },
    }: CellProps<PdCourseTable_CoursesFragment>) => (
      <div className="flex gap-2 items-center">
        <PDCourseStatusBadge courseStatus={status} />
      </div>
    ),
  },
];
