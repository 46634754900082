import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import { Icon } from "../Icon";

type Props = {
  className?: string;
  color?: string;
  size?: number;
};

export const Spinner = ({
  color = "text-white",
  className,
  size = 4,
}: Props) => (
  <Icon
    icon="loading"
    size={size}
    color={color}
    className={clsx("animate-spin cursor-pointer", className)}
  />
);

/**
 * Avoid spinner flicker for fast transitions
 */

export function DelayedSpinner({
  loading,
  children,
  delayMs = 300,
}: {
  loading: boolean;
  children: ReactNode;
  delayMs?: number;
}) {
  const [shouldShowSpinner, setShouldShowSpinner] = useState(false);

  useEffect(() => {
    if (loading) {
      const timerId = setTimeout(() => setShouldShowSpinner(true), delayMs);
      return () => clearTimeout(timerId);
    }
  }, [loading, delayMs]);

  return shouldShowSpinner ? <Spinner /> : <>{children}</>;
}
