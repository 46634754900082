import { ApplicationStatus } from "@generated/graphql";
import { getApplicationStatusText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "components/shared";
import { AppStatusColorMap } from "sections/Jobs/constants";

type Props = {
  applicationStatus: ApplicationStatus | null | undefined;
  className?: string;
};

export const ApplicationStatusBadge = ({
  applicationStatus,
  className,
}: Props) => (
  <Badge
    className={clsx(
      AppStatusColorMap[applicationStatus ?? "NOT_APPLIED"],
      "flex text-center w-auto px-2 py-0.5 rounded-full",
      className
    )}
  >
    {getApplicationStatusText(applicationStatus ?? "NOT_APPLIED")}
  </Badge>
);
