import clsx from "clsx";
import { Badge } from "components/shared";

type Props = {
  autoPayEnabled: boolean;
  className?: string;
};

export const AutoPayBadge = ({ autoPayEnabled, className }: Props) => (
  <Badge className={clsx(autoPayColors(autoPayEnabled), "mr-5", className)}>
    {autoPayEnabled ? "Enabled" : "Disabled"}
  </Badge>
);

const autoPayColors = (autoPayEnabled: boolean) =>
  autoPayEnabled
    ? "text-emerald-900 bg-emerald-200"
    : "text-rose-900 bg-rose-200";
