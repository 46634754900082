import { CohortSessionStudentGrading } from "@generated/graphql";

export const getUpdateGradingFields = (
  updatedGrading: Partial<CohortSessionStudentGrading>
) => ({
  readingGrade: () => updatedGrading.readingGrade ?? null,
  classworkGrade: () => updatedGrading.classworkGrade ?? null,
  participationGrade: () => updatedGrading.participationGrade ?? null,
  languageArtsGrade: () => updatedGrading.languageArtsGrade ?? null,
  readingAbsentFromAssessment: () =>
    updatedGrading.readingAbsentFromAssessment ?? false,
  classworkAbsentFromAssessment: () =>
    updatedGrading.classworkAbsentFromAssessment ?? false,
  languageArtsAbsentFromAssessment: () =>
    updatedGrading.languageArtsAbsentFromAssessment ?? false,
});
