import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { PropsWithChildren } from "react";

type Props = { className?: string; noNav?: boolean; noScroll?: boolean };

export const Page = ({
  children,
  className,
  noNav,
  noScroll = false,
}: PropsWithChildren<Props>) => (
  <main
    className={clsx(
      "z-0",
      getScrollbarStyle("page"),
      noScroll ? "overflow-y-hidden" : "overflow-y-auto",
      noNav ? "h-screen" : "h-screen-nav"
    )}
  >
    <div className="mx-auto w-auto p-4">
      <div className={clsx("flex flex-col gap-y-4 justify-between", className)}>
        {children}
      </div>
    </div>
  </main>
);
