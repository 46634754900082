import { CohortAssignmentRole } from "@generated/graphql";
import {
  CohortAssignmentRoleTextMode,
  getCohortAssignmentRoleText,
} from "@utils/labels";

type Props = {
  cohortAssignmentRole: CohortAssignmentRole;
  className?: string;
  mode?: CohortAssignmentRoleTextMode;
};

export function CohortAssignmentRoleText({
  cohortAssignmentRole,
  className = "text-gray-700",
  mode = "long",
}: Props) {
  const assignmentRoleText = getCohortAssignmentRoleText(
    cohortAssignmentRole,
    mode
  );
  return <span className={className}>{assignmentRoleText}</span>;
}
