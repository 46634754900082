import { Popover, Transition } from "@headlessui/react";
import { resolveStringyFunction } from "@utils/strings";
import clsx from "clsx";
import { FC, Fragment, PropsWithChildren } from "react";
import { Chevron, ChevronDirection } from "../Chevron";

type Props = {
  label?: string;
  chevronDirection?: ChevronDirection;
  containerClassName?: string;
  buttonClassName?: string | ((open: boolean) => string);
  buttonTextClassName?: string | ((open: boolean) => string);
  chevronClassName?: string | ((open: boolean) => string);
  customPopoverButtonFunc?: FC<PopoverButtonProps>;
  disabled?: boolean;
};

/**
 * Useful subset of this component's props that should be made accessible to
 * any component that uses this component as a means to offer customization.
 */
export type CustomizationProps = Pick<
  Props,
  "label" | "buttonClassName" | "buttonTextClassName"
>;

/**
 * Props that are passed to children of Popover.Button component.
 */
export type PopoverButtonProps = {
  open?: boolean;
};

/**
 * A basic frame for a HeadlessUI Popover's Popover.Button. Simply provide a
 * <Popover.Panel> component as the child and let 'er rip!
 *
 * https://headlessui.com/react/popover
 *
 * classNames can be a function that is passed the Popover's `open` boolean
 * where you can then return a string to style the component (Button or Chevron)
 * depending on the open state.
 *
 * buttonTextClassName is a shortcut that lets you style the button text
 * alone. It will be ignored if you provide a buttonClassName prop.
 */
export const PopoverButton = ({
  label = "Expand",
  buttonClassName,
  containerClassName = "w-fit",
  buttonTextClassName = "text-base font-medium",
  chevronClassName,
  chevronDirection = "DOWN",
  customPopoverButtonFunc,
  disabled = false,
  children,
}: PropsWithChildren<Props>) => (
  <Popover className={`relative ${containerClassName}`}>
    {({ open }) => (
      <>
        <Popover.Button
          disabled={disabled}
          className={
            buttonClassName
              ? resolveStringyFunction(buttonClassName, open)
              : clsx(
                  open && "text-opacity-90",
                  "w-full group inline-flex items-center rounded-md px-3 py-2 border border-gray-300",
                  "hover:text-opacity-100 focus:outline-none focus-visible:ring-2",
                  "focus-visible:ring-black focus-visible:ring-opacity-75 whitespace-nowrap",
                  resolveStringyFunction(buttonTextClassName, open)
                )
          }
        >
          {customPopoverButtonFunc ? (
            <div className="w-full">
              {customPopoverButtonFunc({ open })}
              <span className="sr-only">{label}</span>
            </div>
          ) : (
            <>
              <span>{label}</span>
              <Chevron
                direction={chevronDirection}
                size={5}
                color="text-gray-700"
                className={
                  chevronClassName
                    ? resolveStringyFunction(chevronClassName, open)
                    : clsx(
                        open && "text-opacity-70",
                        "ml-2 transition duration-150",
                        "ease-in-out group-hover:text-opacity-80"
                      )
                }
              />
            </>
          )}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          {children}
        </Transition>
      </>
    )}
  </Popover>
);

export type OpenOrientation =
  | "TOP"
  | "TOP-START"
  | "TOP-END"
  | "BOTTOM"
  | "BOTTOM-START"
  | "BOTTOM-END"
  | "LEFT"
  | "LEFT-START"
  | "LEFT-END"
  | "RIGHT"
  | "RIGHT-START"
  | "RIGHT-END";
export type OpenOrientationRules = {
  orientationClassNames: string;
  chevronDirection: ChevronDirection;
};
/**
 * Use this function to get orientationClassNames for to use on your Popover.Panel.
 * Copied the names from MUI Tooltip:
 * https://mui.com/material-ui/react-tooltip/#positioned-tooltips
 * @param openOrientation
 * @returns
 */
export function getPopoverPanelOrientationRules(
  openOrientation: OpenOrientation
): OpenOrientationRules {
  switch (openOrientation) {
    case "TOP":
      return {
        orientationClassNames: "bottom-full mb-3 left-1/2 -translate-x-1/2",
        chevronDirection: "UP",
      };
    case "TOP-START":
      return {
        orientationClassNames: "bottom-full mb-3 ml-1 left-0",
        chevronDirection: "UP",
      };
    case "TOP-END":
      return {
        orientationClassNames: "bottom-full mb-3 mr-1 right-0",
        chevronDirection: "UP",
      };
    case "BOTTOM":
      return {
        orientationClassNames: "mt-3 left-1/2 -translate-x-1/2",
        chevronDirection: "DOWN",
      };
    case "BOTTOM-START":
      return {
        orientationClassNames: "mt-3 ml-1 left-0",
        chevronDirection: "DOWN",
      };
    case "BOTTOM-END":
      return {
        orientationClassNames: "mt-3 mr-1 right-0",
        chevronDirection: "DOWN",
      };
    case "LEFT":
      return {
        orientationClassNames: "top-1/2 right-full mr-3 -translate-y-1/2",
        chevronDirection: "LEFT",
      };
    case "LEFT-START":
      return {
        orientationClassNames: "top-0 right-full mr-3",
        chevronDirection: "LEFT",
      };
    case "LEFT-END":
      return {
        orientationClassNames: "bottom-0 right-full mr-3",
        chevronDirection: "LEFT",
      };
    case "RIGHT":
      return {
        orientationClassNames: "top-1/2 left-full ml-3 -translate-y-1/2",
        chevronDirection: "RIGHT",
      };
    case "RIGHT-START":
      return {
        orientationClassNames: "top-0 left-full ml-3",
        chevronDirection: "RIGHT",
      };
    case "RIGHT-END":
      return {
        orientationClassNames: "bottom-0 left-full ml-3",
        chevronDirection: "RIGHT",
      };
    default:
      return {
        orientationClassNames: "",
        chevronDirection: "DOWN",
      };
  }
}

export const getOpenOrientation = (
  chevronDirection: ChevronDirection,
  open: boolean
) => {
  if (!open) return chevronDirection;
  switch (chevronDirection) {
    case "UP":
      return "DOWN";
    case "DOWN":
      return "UP";
    case "LEFT":
      return "RIGHT";
    case "RIGHT":
      return "LEFT";
    default:
      return "DOWN";
  }
};
