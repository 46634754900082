import clsx from "clsx";

export type SimpleTabConfig<T extends string> = {
  name: string;
  tab: T;
  current: boolean;
};

type SimpleTabProps<T extends string> = {
  tabs: SimpleTabConfig<T>[];
  onTabClick?: (tab: SimpleTabConfig<T>, index: number) => void;
};

export function SimpleTabs<T extends string>({
  tabs,
  onTabClick,
}: SimpleTabProps<T>) {
  const handleTabClick = (tab: SimpleTabConfig<T>, index: number) => {
    if (onTabClick) {
      onTabClick(tab, index);
    }
  };

  return (
    <div>
      <nav
        className="navigation-tabs left flex flex-row gap-x-2 "
        aria-label="Tabs"
        data-test="tabs-container"
      >
        {tabs.map((tab, index) => (
          <button
            key={tab.name}
            onClick={() => handleTabClick(tab, index)}
            className={clsx(
              "tab",
              tab.current ? "selected" : "",
              "font-medium text-sm"
            )}
          >
            {tab.name}
          </button>
        ))}
      </nav>
    </div>
  );
}
