import Head from "next/head";
import router from "next/router";
import { config } from "../../config";

export default function TitleHead() {
  const getRouteName = (route: string) => {
    const match = route
      .replace(/[-_]/g, " ") // Replace - and _ with spaces.
      .replace(/\[|\]/g, "") // Remove square brackets.
      .replace(/\b\w/g, (l) => l.toUpperCase()) // Capitalize first letter of each word.
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Separate words that are in camelCase.
      .match(/\/([^/]+)/); // Get the first word after the first slash.

    return match != null ? match[1] : "Home";
  };

  const envName =
    config.env === "development"
      ? " • Dev"
      : config.env === "test"
      ? " • Staging"
      : "";

  return (
    <Head>
      <title>
        {getRouteName(router.route)} • TbT{envName}
      </title>
    </Head>
  );
}
