import { clsx } from "clsx";
import { SelectMenu, SelectMenuOption, Tooltip } from "components/shared";
import {
  RoboDisplayNameLabel,
  SavedDisplayNameLabel,
} from "components/shared/AttendanceGrades/SpecialLabels";
import { StudentLiveParticipantMatch } from "components/shared/AttendanceGrades/types";
import { useTutorDashboardData } from "contexts/TutorDashboardDataProvider";
import { useEffect, useMemo, useState } from "react";
import { NO_MATCH, NO_MATCH_OPTION } from "./constants";

type Props = {
  disabled: boolean;
  isAbsent: boolean;
  attendanceId: string;
  savedDisplayName?: string | null;
  matchData: StudentLiveParticipantMatch | undefined;
  saveName: (displayName: string | null) => void;
};

export const DisplayNameSaveOnChangeInput = ({
  isAbsent,
  disabled,
  matchData,
  attendanceId,
  savedDisplayName,
  saveName,
}: Props) => {
  const { backupStudentNames, setBackupStudentNames } = useTutorDashboardData();

  const displayName = useMemo(
    () =>
      savedDisplayName ||
      backupStudentNames[attendanceId] ||
      matchData?.match ||
      null,
    [savedDisplayName, backupStudentNames, attendanceId, matchData]
  );

  const studentMatchOptions = useMemo(() => {
    if (disabled) return [NO_MATCH_OPTION];
    const options: SelectMenuOption[] = (matchData?.distances ?? [])
      .filter((distance) => !!distance.participant)
      .map((distance, index) => {
        const isSelected = matchData?.match === distance.participant;
        const isSaved = savedDisplayName === distance.participant;

        return {
          id: `${distance.participant}_${index}`,
          value: distance.participant,
          ...((isSelected || isSaved) && {
            selectedLabel: isSaved ? (
              <SavedDisplayNameLabel displayName={distance.participant} />
            ) : (
              <RoboDisplayNameLabel displayName={distance.participant} />
            ),
            subValue: isSelected ? "(Robo suggestion)" : "(Saved display name)",
          }),
        };
      });

    const hasSavedDBOption = options.some(({ value }) => value === displayName);

    if (displayName && !hasSavedDBOption) {
      const forcedOption: SelectMenuOption = {
        id: "forcedOption",
        value: displayName,
        selectedLabel: <SavedDisplayNameLabel displayName={displayName} />,
        subValue: "(Saved display name)",
      };
      return [
        ...(backupStudentNames[attendanceId] !== NO_MATCH
          ? [NO_MATCH_OPTION]
          : []),
        forcedOption,
        ...options,
      ];
    }
    return [NO_MATCH_OPTION, ...options];
  }, [
    attendanceId,
    backupStudentNames,
    disabled,
    displayName,
    matchData?.distances,
    matchData?.match,
    savedDisplayName,
  ]);

  const initialSelectedMatchOption =
    studentMatchOptions.find((option) => option.value === displayName) ||
    NO_MATCH_OPTION;

  const [selectedMatchOption, setSelectedMatchOption] =
    useState<SelectMenuOption>(initialSelectedMatchOption);

  const updateMatchOption = (option: SelectMenuOption) => {
    setBackupStudentNames({
      ...backupStudentNames,
      [attendanceId]:
        option.value === matchData?.match ? undefined : String(option.value),
    });
    saveName(String(option.value));
    setSelectedMatchOption(option);
  };

  // Refills input with robo suggestion when transitioning away from disabled
  useEffect(() => {
    if (
      !disabled &&
      matchData?.match &&
      !savedDisplayName &&
      !backupStudentNames[attendanceId]
    ) {
      const matchedOption = studentMatchOptions.find(
        ({ value }) => value === matchData?.match
      );

      if (matchedOption) updateMatchOption(matchedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchData]);

  const initialIndex = studentMatchOptions.findIndex(
    ({ id }) => id === selectedMatchOption?.id
  );

  return (
    <div className="flex items-center">
      <SelectMenu
        disabled={disabled}
        listAlignment="right"
        className="min-w-[18ch]"
        options={studentMatchOptions}
        initialIndex={initialIndex < 0 ? undefined : initialIndex}
        onSelect={(option) => updateMatchOption(option)}
      />
      <Tooltip
        tooltipProps={{ place: "bottom" }}
        className={clsx(
          "w-5 h-5 text-blue-600 ml-2 cursor-pointer",
          displayName !== null || isAbsent ? "text-blue-600" : "text-rose-500"
        )}
        content={
          <div className="block w-36 text-sm text-center">
            Select student screen name during the video session
          </div>
        }
      />
    </div>
  );
};
