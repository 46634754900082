import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import { isSameYear } from "date-fns";

export const normalizedDateText = (timeMs?: number | null) => {
  if (!timeMs) return <span>&#8212;</span>;
  /**
   * Start and end dates are stored in the DB as dates with no time.
   * Javascript will interpret these dates as DateTimes with time 00:00:00 UTC
   *
   * When instantiated in the date constructor, Javascript will convert the given date to
   * your local timezone. And since the west is behind UTC by several hours, UTC midnight
   * is always still the previous day for us. Example: In New York (UTC-4),
   * 8/1/2022 00:00:00 UTC will be converted to 7/31/2022 19:00:00
   */
  const date = normalizeDateFromUTCDateTime(new Date(timeMs));
  return <span>{date.toLocaleDateString("en-US")}</span>;
};

export const getDateRangeText = (startDateMs?: number, endDateMs?: number) =>
  startDateMs &&
  normalizedDateText(startDateMs) === normalizedDateText(endDateMs) ? (
    <span className="flex flex-row gap-x-1">
      {normalizedDateText(startDateMs)}
    </span>
  ) : (
    <span className="flex flex-row gap-x-1">
      {startDateMs ? normalizedDateText(startDateMs) : "Start date unspecified"}
      <span>-</span>
      {endDateMs ? normalizedDateText(endDateMs) : "End date unspecified"}
    </span>
  );

// Feb 28, 2024
export const getShortReadableDate = (date: Date, hideYear = false): string =>
  date.toLocaleDateString("en-US", {
    year: hideYear ? undefined : "numeric",
    month: "short",
    day: "numeric",
  });

// Feb 28, 2024
export const getNormalizedShortReadableDate = (
  date: Date,
  hideYear = false
): string => {
  const normalizedDate = normalizeDateFromUTCDateTime(date);
  return normalizedDate.toLocaleDateString("en-US", {
    year: hideYear ? undefined : "numeric",
    month: "short",
    day: "numeric",
  });
};

// February 28, 2024
export const getNormalizedReadableDate = (date: Date): string => {
  const normalizedDate = normalizeDateFromUTCDateTime(date);
  return normalizedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// Feb 28 - Mar 1, 2024 || Dec 5, 2024 - Jan 23, 2025
export const getShortReadableDateRangeText = (start?: Date, end?: Date) => (
  <span className="flex flex-row gap-x-1">
    {start
      ? getShortReadableDate(start, !end || isSameYear(start, end))
      : "Start date unspecified"}
    <span>-</span>
    {end ? getShortReadableDate(end) : "End date unspecified"}
  </span>
);

// Feb 28 - Mar 1, 2024 || Dec 5, 2024 - Jan 23, 2025
export const getShortReadableDateRangeString = (start?: Date, end?: Date) =>
  `${
    start
      ? getShortReadableDate(start, !end || isSameYear(start, end))
      : "Start date unspecified"
  } - ${end ? getShortReadableDate(end) : "End date unspecified"}`;

// Feb 23
export const getMonthDayReadableDate = (date: Date): string =>
  date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

export const getNormalizedShortFullDateRangeText = (
  startDateMs?: number,
  endDateMs?: number,
  hideFirstYearIfSame = true,
  noEndDateText?: string
) => {
  const start = normalizeDateFromUTCDateTime(new Date(startDateMs ?? 0));
  const end = normalizeDateFromUTCDateTime(new Date(endDateMs ?? 0));
  const calcHideYear = hideFirstYearIfSame && start && end;
  const hideYear = calcHideYear && isSameYear(start, end) ? true : false;

  return (
    <div className="flex gap-x-1">
      {startDateMs
        ? getShortReadableDate(start, hideYear)
        : "Start date unspecified"}
      {" – "}
      {endDateMs
        ? getShortReadableDate(end)
        : noEndDateText ?? "End date unspecified"}
    </div>
  );
};

export const getNormalizedMonthDayDateRangeText = (
  startDateMs?: number,
  endDateMs?: number
) => (
  <div className="flex gap-x-1">
    {startDateMs
      ? getMonthDayReadableDate(
          normalizeDateFromUTCDateTime(new Date(startDateMs ?? 0))
        )
      : "Start date unspecified"}
    {" – "}
    {endDateMs
      ? getMonthDayReadableDate(
          normalizeDateFromUTCDateTime(new Date(endDateMs ?? 0))
        )
      : "End date unspecified"}
  </div>
);

export const getShrinkingDateRangeText = (startDate: Date, endDate: Date) => (
  <div>
    <span className="hidden lg:flex">
      {getNormalizedShortFullDateRangeText(
        startDate.getTime(),
        endDate.getTime()
      )}
    </span>
    <span className="flex lg:hidden">
      {getDateRangeText(startDate.getTime(), endDate.getTime())}
    </span>
  </div>
);

export const getLogDateFormat = (date: Date): string =>
  date.toLocaleString("en-US", {
    weekday: "long", // Monday
    year: "numeric", // 2024
    month: "long", // July
    day: "numeric", // 22
    hour: "numeric", // 8
    minute: "numeric", // 37
    second: "numeric", // 43
    hour12: true, // 12-hour format with AM/PM
    timeZoneName: "short", // "ET"
  });
