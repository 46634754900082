import { clsx } from "clsx";

type Props = {
  checked: boolean;
  onChange: (toggleState: boolean) => void;
  className?: string;
  disabled?: boolean;
};

export const RadioButton = ({
  checked,
  onChange,
  className,
  disabled = false,
}: Props) => (
  <div
    className={clsx(
      "flex justify-center items-center w-[18px] h-[18px] rounded-full border border-slate-400",
      disabled ? "cursor-default bg-gray-200" : "cursor-pointer bg-slate-50",
      !checked && !disabled && "hover:border-blue-600",
      className
    )}
    onClick={() => onChange(!checked)}
  >
    <div
      className={clsx(
        "w-[10px] h-[10px] rounded-full",
        checked && disabled && "bg-blue-400/20",
        checked && !disabled && "bg-blue-600",
        !checked && disabled && "bg-gray-200",
        !checked && !disabled && "bg-white"
      )}
    />
  </div>
);
