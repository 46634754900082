import { JobPostingStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "components/shared/Badges/Badge";

type Props = {
  status: JobPostingStatus;
  className?: string;
};

export const JobPostingStatusBadge = ({ status, className }: Props) => (
  <Badge className={clsx(jobPostingStatusMap[status], className)}>
    {getJobPostingStatusText(status)}
  </Badge>
);

export const getJobPostingStatusText = (status: JobPostingStatus): string => {
  switch (status) {
    case JobPostingStatus.Published:
      return "Published";
    case JobPostingStatus.Archived:
      return "Archived";
    case JobPostingStatus.Unpublished:
      return "Unpublished";
    default:
      return "Unknown Status";
  }
};

const jobPostingStatusMap: {
  [key in JobPostingStatus]: string;
} = {
  [JobPostingStatus.Published]: "text-emerald-900 bg-emerald-200",
  [JobPostingStatus.Archived]: "text-orange-900 bg-orange-200",
  [JobPostingStatus.Unpublished]: "text-red-900 bg-red-200",
};
