import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { redirectToLogin } from "@utils/browser";
import { removeCognitoCookies, removeSpoofCookie } from "@utils/cookies";
import { createClient } from "graphql-ws";
import type { GetServerSidePropsContext } from "next";
import { WebSocket } from "ws";
import { config, Env } from "../config";

export type Session = {
  client: ApolloClient<NormalizedCacheObject>;
  token: string;
  cookie?: string;
};

let __session: Session | undefined;

function cacheSession(session: Session) {
  __session = session;
}

function getSessionFromMemory() {
  return __session;
}

export function getSession(
  token: string,
  context: GetServerSidePropsContext | null
): Session {
  const session = getSessionFromMemory();
  const cookie = context?.req.headers.cookie;

  /**
   * If we don't have a client in memory, let's create one.
   */
  if (!session?.client) {
    // create new client
    const client = createApolloClient({ token, cookie });

    // cache the client in session object
    const session = { client, token, cookie };
    cacheSession(session);

    // return it
    return session;
  }

  /**
   * If we had a token before but a new one comes in, this is a
   * refresh operation.  let's spin up a new client with the new token.
   */

  if (session.token !== token) {
    // create new client
    const client = createApolloClient({ token, cookie });

    // cache the client in session object
    const newSession = { client, token, cookie };
    cacheSession(newSession);

    // return it
    return newSession;
  }

  return session;
}

/**
 * Unauthenticated Session
 */

export type UnauthenticatedSession = {
  client: ApolloClient<NormalizedCacheObject>;
};

let __unauthenticatedSession: UnauthenticatedSession | undefined;

function cacheUnauthenticatedSession(session: UnauthenticatedSession) {
  __unauthenticatedSession = session;
}

function getUnauthenticatedSessionFromMemory() {
  return __unauthenticatedSession;
}

function clearSessionFromMemory() {
  __session = undefined;
  __unauthenticatedSession = undefined;
}

export function getUnauthenticatedSession(): UnauthenticatedSession {
  const session = getUnauthenticatedSessionFromMemory();

  if (!session) {
    const client = createApolloClient();
    const session = { client };
    cacheUnauthenticatedSession(session);
    return session;
  }

  return session;
}

/**
 * Create client
 */

function createApolloClient(apolloConfig?: {
  token?: string | null;
  cookie?: string;
}) {
  const isClient = typeof window !== "undefined";
  const authHeader = apolloConfig?.token
    ? {
        headers: {
          authorization: `Bearer ${apolloConfig.token}`,
          ...(apolloConfig.cookie ? { cookie: apolloConfig.cookie } : {}),
        },
      }
    : {};

  const httpLink = new HttpLink({
    fetchOptions: {
      mode: "cors",
    },
    uri: process.env.NEXT_PUBLIC_API_URL,
    credentials: "include",
    ...authHeader,
  });

  const wsLink =
    typeof window !== "undefined"
      ? new GraphQLWsLink(
          createClient({
            webSocketImpl: WebSocket,
            url: `${process.env.NEXT_PUBLIC_WS_API_URL}`,
            connectionParams: {
              Authorization: authHeader.headers?.authorization,
            },
          })
        )
      : null;

  const splitLink =
    typeof window !== "undefined" && wsLink != null
      ? split(
          ({ query }) => {
            const def = getMainDefinition(query);
            return (
              def.kind === "OperationDefinition" &&
              def.operation === "subscription"
            );
          },
          wsLink,
          httpLink
        )
      : httpLink;

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (message.includes("User is not logged in.") && isClient) {
          removeCognitoCookies();
          removeSpoofCookie();
          clearSessionFromMemory();
          redirectToLogin();
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  return new ApolloClient({
    ssrMode: true,
    link: from([errorLink, splitLink]),
    credentials: "include",
    connectToDevTools: config.env === Env.DEV,
    cache: new InMemoryCache({
      typePolicies: {
        /**
         * A note on the cache policy for staffAssignments:
         *
         * Anytime an engagement or cohort's staffAssignments are mutated,
         * the incoming staffAssignments is the full list of available staff
         * for that engagement or cohort. It is not a partial list.
         * Therefore, there is no need to "merge" the incoming list with
         * the existing list in cache. By default, Apollo replaces the existing
         * data with the incoming data and throws a warning about potential
         * data-loss. In our case, there is no data-loss. Specifying this in
         * a merge function makes this behavior explicit and removes the warning.
         *
         * Docs: https://go.apollo.dev/c/merging-non-normalized-objects
         */
        Engagement: {
          fields: {
            staffAssignments: plainMerge,
          },
        },
        Cohort: {
          fields: {
            staffAssignments: plainMerge,
            eventInstances: plainMerge,
            cohortSessions: plainMerge,
            cohortStaffAssignments: plainMerge,
          },
        },
        CohortSessionTeacherAttendance: {
          fields: {
            cohortSession: plainMerge,
          },
        },
        EngagementInstructionalSupportAttendanceGroupSubstituteData: {
          keyFields: ["cacheKey"],
        },
        TutoringPreferencesAvailability: {
          keyFields: ["timeSlotId"],
        },
        TutorDashboardEvent: {
          keyFields: ["cacheKey"],
        },
      },
    }),
  });
}

const plainMerge = {
  merge: (_existing: unknown, incoming: unknown) => incoming,
};
