import { useGlobalFilterInputs } from "contexts/FilterInputsProvider";
import { Filters } from "../Filters";
import { FilterType } from "../types";

type Props = { filterType: FilterType };

export const TestTableFilters = ({ filterType }: Props) => {
  const {
    getFilterBoolean: getGlobalFilterBoolean,
    setFilterBoolean: setGlobalFilterBoolean,
  } = useGlobalFilterInputs();

  return (
    <Filters
      filters={getFilters(
        getGlobalFilterBoolean,
        setGlobalFilterBoolean,
        filterType
      )}
    />
  );
};

const getFilters = (
  getGlobalFilterBoolean: (key: string) => boolean | null,
  setGlobalFilterBoolean: (key: string, value: boolean) => void,
  filterType: FilterType
) => [
  {
    name: "All",
    selected: !getGlobalFilterBoolean("testEntitiesOnly"),
    tooltip: (
      <div className="flex flex-col items-center">
        <p>Show All {filterType}</p>
        <p>(except those in testing)</p>
      </div>
    ),
    onClick: () => {
      setGlobalFilterBoolean("testEntitiesOnly", false);
    },
  },
  {
    name: "Test",
    selected: getGlobalFilterBoolean("testEntitiesOnly") ?? false,
    tooltip: `Show only ${filterType} that are in testing.`,
    onClick: (newState: boolean) => {
      setGlobalFilterBoolean("testEntitiesOnly", newState);
    },
  },
];
