import { extractErrorMsg } from "@utils/errors";
import { Amplify, Auth } from "aws-amplify";
import { useCallback } from "react";
import { config } from "../config";

export function configureAmplify() {
  Amplify.configure({
    ssr: true,
    Auth: config.cognitoAuth,
    Storage: {
      AWSS3: {
        bucket: config.s3.BUCKET,
        region: config.cognito.REGION,
      },
    },
  });
}

/**
 * Amplify utilities
 */

export function useAmplifyAuth() {
  const onForgotPassword = useCallback(async (email: string) => {
    try {
      await Auth.forgotPassword(email);
      return { success: true, message: "Verication code sent." };
    } catch (error) {
      if (error instanceof Error) {
        switch (error.message) {
          case AmplifyAuthErrors.VericationEmailNotFound:
            return {
              success: false,
              message:
                "This email was not found. Double check your email and contact support if the issue persists.",
            };

          default:
            return {
              success: false,
              message: error.message,
            };
        }
      }

      return { success: false, message: extractErrorMsg(error) };
    }
  }, []);

  const onForgotPasswordSubmit = useCallback(
    async ({
      email,
      verificationCode,
      newPassword,
    }: {
      email: string;
      verificationCode: string;
      newPassword: string;
    }) => {
      try {
        await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
        return { success: true, message: "Password successfully changed." };
      } catch (error) {
        console.error(error);
        return { success: false, message: extractErrorMsg(error) };
      }
    },
    []
  );

  return {
    onForgotPassword,
    onForgotPasswordSubmit,
  };
}

/**
 * I was not able to find these error strings in amplify's module.
 */
export enum AmplifyAuthErrors {
  UserDoesNotExist = "User does not exist.",
  VericationEmailNotFound = "Username/client id combination not found.",
}
