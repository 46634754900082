import clsx from "clsx";
import React from "react";
import { config, Env } from "../../config";

export const Layout = ({ children }: { children: React.ReactNode }) => (
  <div
    className={clsx(
      "bg-gray-200 min-h-screen",
      config.env === Env.DEV && "debug-screens"
    )}
  >
    {children}
  </div>
);
