import clsx from "clsx";
import { Banner } from "../Banner";

type Props = {
  msg?: React.ReactNode | null;
  subMsg?: React.ReactNode;
  className?: string;
  boundary?: boolean;
  actions?: React.ReactNode;
};

export const ErrorBox = ({
  msg,
  subMsg,
  boundary = false,
  className,
  actions,
}: Props) =>
  msg || boundary ? (
    <Banner
      type="error"
      title={msg ?? "Looks like something went wrong."}
      subtitle={subMsg}
      actions={actions}
      className={clsx(boundary && "mt-4", className)}
    />
  ) : undefined;
