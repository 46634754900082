import { Editor } from "@tiptap/react";
import { SelectMenuOption } from "components/shared/Inputs/SelectMenu";

export const HEADING_EVENT_MAP: Record<string, (editor: Editor) => boolean> = {
  initial: () => false,
  h1: (editor: Editor) => editor.chain().focus().setHeading({ level: 1 }).run(),
  h2: (editor: Editor) => editor.chain().focus().setHeading({ level: 2 }).run(),
  h3: (editor: Editor) => editor.chain().focus().setHeading({ level: 3 }).run(),
  p: (editor: Editor) => editor.chain().focus().setParagraph().run(),
};

export const HEADING_OPTIONS: SelectMenuOption[] = [
  { id: "h1", value: "Heading 1" },
  { id: "h2", value: "Heading 2" },
  { id: "h3", value: "Heading 3" },
  { id: "p", value: "Paragraph" },
];
