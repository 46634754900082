/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountRole } from "@generated/graphql";
import { AdminMode } from "types/global";
import { Routes } from "./routes";
import {
  AccountAccessRole,
  NamedRoute,
  Route,
  RouteProps,
  UnauthenticatedUser,
} from "./types";

export const ROUTE_PATHS = getAllRoutes();

// ACCOUNT ROLE ACCESS FUNCTIONS

export const isAccountRoleRoute = (role: AccountAccessRole, route: Route) =>
  route.accountRoleAccess.includes(role);

export const isUnauthenticatedRoute = (route: Route) =>
  route.accountRoleAccess.includes(UnauthenticatedUser.NonAuthedUser);

export const isAuthenticatedRoute = (route: Route) =>
  !route.accountRoleAccess.includes(UnauthenticatedUser.NonAuthedUser);

export const isVisitorRoute = (route: Route) =>
  route.accountRoleAccess.includes(AccountRole.Visitor);

export const isTutorTeacherRoute = (route: Route) =>
  route.accountRoleAccess.includes(AccountRole.TutorTeacher);

export const isMentorTeacherRoute = (route: Route) =>
  route.accountRoleAccess.includes(AccountRole.MentorTeacher);

export const isAdminRoute = (route: Route) =>
  route.accountRoleAccess.includes(AccountRole.Admin);

// ADMIN MODE ACCESS FUNCTIONS

export const isAdminModeRoute = (mode: AdminMode, route: Route) =>
  route.adminModeAccess.includes(mode);

export const isStaffingRoute = (route: Route) =>
  route.adminModeAccess.includes(AdminMode.Staffing);

export const isManagingRoute = (route: Route) =>
  route.adminModeAccess.includes(AdminMode.Managing);

export const isControlPanelRoute = (route: Route) =>
  route.adminModeAccess.includes(AdminMode.ControlPanel);

export const getAdminModePriority = (route: Route) => {
  if (isManagingRoute(route)) return AdminMode.Managing;
  if (isStaffingRoute(route)) return AdminMode.Staffing;
  if (isControlPanelRoute(route)) return AdminMode.ControlPanel;
  return AdminMode.Dashboard;
};

// REDIRECT FUNCTIONS

export const getAdminModeFallbackRoute = (mode: AdminMode) => {
  switch (mode) {
    case AdminMode.Dashboard:
      return Routes.home;
    case AdminMode.Managing:
      return Routes.organizations;
    case AdminMode.Staffing:
      return Routes.job.board;
    case AdminMode.ControlPanel:
      return Routes.settings.usHolidays;
    default:
      return Routes.home;
  }
};

export const getAccountRoleFallbackRoute = (role: AccountAccessRole) => {
  switch (role) {
    case AccountRole.Visitor:
      return Routes.visitor;
    case UnauthenticatedUser.NonAuthedUser:
      return Routes.login;
    case AccountRole.Admin:
    case AccountRole.MentorTeacher:
    case AccountRole.TutorTeacher:
    default:
      return Routes.home;
  }
};

// PATH TO ROUTE FUNCTIONS

export function getAllRoutes(): { [key: string]: Route }[] {
  const parentPath: string[] = [];
  const routes: NamedRoute[] = [];
  const traverseTree = (node: any, parentKeys: string[] = []) => {
    for (const key in node) {
      const item = node[key];
      if (typeof item === "object") {
        if ("href" in item && "path" in item) {
          const fullPath = [...parentKeys, key].join(".");
          const routeWithKey: { [key: string]: Route } = {};
          routeWithKey[fullPath] = item;
          routes.push(routeWithKey);
        } else {
          traverseTree(item, [...parentKeys, key]);
        }
      }
    }
  };

  traverseTree(Routes, parentPath);
  return routes;
}

export const getRouteFromPath = (
  pathName: string,
  getParent?: boolean
): Route | undefined => {
  const routesArray: NamedRoute[] = ROUTE_PATHS;
  for (const routeItem of routesArray) {
    for (const routeKey in routeItem) {
      const route = routeItem[routeKey];
      const routePath = route.path();
      if (pathName === routePath) {
        const pathParts = routeKey.split(".");
        if (getParent && pathParts.length > 1) pathParts.pop();
        let currentObject: any = Routes;
        for (const part of pathParts) currentObject = currentObject[part];
        return currentObject;
      }
    }
  }
  return undefined;
};

export const arePathsEqual = (
  routePath: string, // Route path with /[variable]/ segments
  actualPath: string // Actual path with dynamic segment /values/
): boolean => {
  const cleanPath = (path: string) => path.split("?")[0].trim();
  const pathSegments = (path: string) => path.replace(/\/+$/, "").split("/");

  const cleanedRoutePath = cleanPath(routePath);
  const cleanedActualPath = cleanPath(actualPath);

  const routePathSegments = pathSegments(cleanedRoutePath);
  const actualPathSegments = pathSegments(cleanedActualPath);

  if (routePathSegments.length !== actualPathSegments.length) return false;

  for (let i = 0; i < routePathSegments.length; i++) {
    const routeSegment = routePathSegments[i];
    const actualSegment = actualPathSegments[i];
    if (routeSegment.startsWith("[") && routeSegment.endsWith("]")) continue;
    if (routeSegment !== actualSegment) return false;
  }

  return true;
};

export const getParamsFromRoute = (
  route: Route,
  actualURL: string // url path
): string[] | Record<string, string> => {
  const routePath = route?.href();
  const paramStrings: string[] = [];
  const paramObject: Record<string, string> = {};
  const [pathUndefined, queryUndefined] = routePath.split("?");
  const [pathActual, queryActual] = actualURL.split("?");
  const partsUndefined = pathUndefined.split("/");
  const partsActual = pathActual.split("/");
  let objectFound = false;

  for (let i = 0; i < partsUndefined.length; i++) {
    if (partsUndefined[i] === "undefined" && i < partsActual.length) {
      paramObject[partsActual[i]] = "undefined";
      paramStrings.push(partsActual[i]);
    }
  }

  if (queryActual) {
    const paramsUndefined = new URLSearchParams(queryUndefined);
    const paramsActual = new URLSearchParams(queryActual);
    const paramObjectPairs = queryActual.split("&");

    if (paramObjectPairs.length > 1) {
      objectFound = true;
      paramObjectPairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        paramObject[key] = value;
      });
    }

    if (!objectFound) {
      paramsActual.forEach((_value, key) => {
        if (paramsActual.has(key)) {
          paramStrings.push(paramsActual.get(key)!);
        }
      });

      paramsUndefined.forEach((value, key) => {
        if (paramsActual.has(key)) {
          paramObject[key] = paramsActual.get(key)!;
        }
      });
    }
  }

  return objectFound ? paramObject : paramStrings;
};

export const getPathRouteDetails = (
  routePath: string, // Route path with /[variable]/ segments
  actualPath: string // Actual path with dynamic segment /values/
): RouteProps => {
  const route = getRouteFromPath(routePath);
  return {
    route,
    params: route ? getParamsFromRoute(route, actualPath) : [],
  };
};

export const gracefulRedirect = (
  routePath: string, // Route path with /[variable]/ segments
  actualPath: string // Actual path with dynamic segment /values/
): RouteProps => {
  const routeParts = routePath.split("/");
  routeParts[routeParts.length - 1] = "details";
  const newRoute = routeParts.join("/");
  return getPathRouteDetails(newRoute, actualPath);
};
