import { sessionStorageUtils } from "@utils/browser";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDebounce } from "use-debounce";

const GROUP_FILTER_INPUTS_KEY = "groupedFilterInputs";
const GLOBAL_FILTER_INPUTS_KEY = "globalFilterInputs";

type FilterInputsContextProps = {
  initializeGroupValue: (
    group: string,
    key: string,
    initValue: string | boolean
  ) => void;
  getGroupFilterBoolean: (group: string, key: string) => boolean | null;
  setGroupFilterBoolean: (group: string, key: string, value: boolean) => void;
  initializeGlobalValue: (key: string, initValue: string | boolean) => void;
  getGlobalFilterBoolean: (key: string) => boolean | null;
  setGlobalFilterBoolean: (key: string, value: boolean) => void;
};

const FilterInputsContext = createContext<FilterInputsContextProps | undefined>(
  undefined
);

type Props = { children: React.ReactNode };

export function FilterInputsProvider({ children }: Props) {
  const [groupFilterInputs, setGroupFilterInputs] = useState<
    Record<string, Record<string, string | boolean>>
  >({});
  const [debouncedGroupFilterInputs] = useDebounce(groupFilterInputs, 300);

  const [filterInputs, setFilterInputs] = useState<
    Record<string, string | boolean>
  >({});
  const [debouncedFilterInputs] = useDebounce(filterInputs, 300);

  const [hasLoadedSavedInputs, setHasLoadedSavedInputs] =
    useState<boolean>(false);

  // Initialize states from sessionStorage on mount.
  useEffect(() => {
    const savedGroupFilterInputs = sessionStorageUtils.getItem(
      GROUP_FILTER_INPUTS_KEY
    );
    if (savedGroupFilterInputs)
      setGroupFilterInputs(JSON.parse(savedGroupFilterInputs));

    const savedFilterInputs = sessionStorageUtils.getItem(
      GLOBAL_FILTER_INPUTS_KEY
    );
    if (savedFilterInputs) setFilterInputs(JSON.parse(savedFilterInputs));

    // Set the flag to true after initial values are loaded.
    setHasLoadedSavedInputs(true);
  }, []);

  // Update sessionStorage whenever filterInputs state changes (debounced).
  useEffect(() => {
    if (!hasLoadedSavedInputs) return; // Skip if the initial values have not been loaded yet
    sessionStorageUtils.setItem(
      GROUP_FILTER_INPUTS_KEY,
      JSON.stringify(debouncedGroupFilterInputs)
    );
    sessionStorageUtils.setItem(
      GLOBAL_FILTER_INPUTS_KEY,
      JSON.stringify(debouncedFilterInputs)
    );
  }, [debouncedGroupFilterInputs, debouncedFilterInputs, hasLoadedSavedInputs]);

  // Group Functions
  const initializeGroupValue = useCallback(
    (group: string, key: string, initValue: string | boolean) => {
      setGroupFilterInputs((prev) => {
        const currentGroup = prev[group] || {};

        // Only initialize if the key doesn't already exist in the state.
        if (key in currentGroup) return prev;
        return {
          ...prev,
          [group]: { ...currentGroup, [key]: initValue },
        };
      });
    },
    []
  );

  const getGroupFilterBoolean = (group: string, key: string) => {
    const value = groupFilterInputs[group]?.[key];
    return typeof value === "boolean" ? value : null;
  };

  const setGroupFilterBoolean = (
    group: string,
    key: string,
    value: boolean
  ) => {
    setGroupFilterInputs((prevValues) => ({
      ...prevValues,
      [group]: { ...(prevValues[group] || {}), [key]: value },
    }));
  };

  // Global Functions
  const initializeGlobalValue = useCallback(
    (key: string, initValue: string | boolean) => {
      setFilterInputs((prev) => {
        // Only initialize if the key doesn't already exist in the state.
        if (key in prev) return prev;
        return {
          ...prev,
          [key]: initValue,
        };
      });
    },
    []
  );

  const getGlobalFilterBoolean = (key: string) => {
    const value = filterInputs[key];
    return typeof value === "boolean" ? value : null;
  };

  const setGlobalFilterBoolean = (key: string, value: boolean) => {
    setFilterInputs((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  return (
    <FilterInputsContext.Provider
      value={{
        initializeGroupValue,
        getGroupFilterBoolean,
        setGroupFilterBoolean,
        initializeGlobalValue,
        getGlobalFilterBoolean,
        setGlobalFilterBoolean,
      }}
    >
      {children}
    </FilterInputsContext.Provider>
  );
}

export function useGroupFilterInputs(group: string) {
  const context = useContext(FilterInputsContext);

  if (context === undefined) {
    throw new Error(
      "useGroupFilterInputs must be used within an FilterInputsProvider"
    );
  }

  // Get the context values from the original context hook.
  const { initializeGroupValue, getGroupFilterBoolean, setGroupFilterBoolean } =
    context;

  // Create new functions that are bound to the given group.
  const initializeGroupValueCB = useCallback(
    (key: string, initValue: string | boolean) =>
      initializeGroupValue(group, key, initValue),
    [initializeGroupValue, group]
  );

  const getGroupFilterBooleanCB = useCallback(
    (key: string) => getGroupFilterBoolean(group, key),
    [getGroupFilterBoolean, group]
  );

  const setGroupFilterBooleanCB = useCallback(
    (key: string, value: boolean) => setGroupFilterBoolean(group, key, value),
    [setGroupFilterBoolean, group]
  );

  // Return the new functions from the custom hook.
  return {
    initializeValue: initializeGroupValueCB,
    getFilterBoolean: getGroupFilterBooleanCB,
    setFilterBoolean: setGroupFilterBooleanCB,
  };
}

export function useGlobalFilterInputs() {
  const context = useContext(FilterInputsContext);

  if (context === undefined) {
    throw new Error(
      "useGlobalFilterInputs must be used within an FilterInputsProvider"
    );
  }

  // Get the context values from the original context hook.
  const {
    initializeGlobalValue,
    getGlobalFilterBoolean,
    setGlobalFilterBoolean,
  } = context;

  return {
    initializeValue: initializeGlobalValue,
    getFilterBoolean: getGlobalFilterBoolean,
    setFilterBoolean: setGlobalFilterBoolean,
  };
}
