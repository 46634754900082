import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";
import Select, {
  ActionMeta,
  components,
  GroupBase,
  InputActionMeta,
  MultiValue,
  OptionProps,
  SingleValue,
} from "react-select";
import { Icon } from "../Icon";

export type SearchSelectOption = {
  value: string;
  label: string;
  data?: { [key: string]: string };
};

interface Props<Option> {
  name: string;
  label?: ReactNode;
  options: Option[];
  heightPx?: number;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  inputValue?: string;
  placeholder?: string;
  selection?: Option | null;
  clearAfterSelect?: boolean;
  onClear?: () => void;
  onSelect: (option: Option | null) => void;
  onInputChange?: (input: string) => void;
}

export const SearchDropdown = <Option extends SearchSelectOption>({
  name,
  label,
  options,
  disabled,
  selection,
  className,
  placeholder,
  heightPx = 36,
  clearAfterSelect,
  isLoading = false,
  onClear,
  onSelect,
  onInputChange,
}: Props<Option>) => {
  const height = `h-[${heightPx}px]`;
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    selection ?? null
  );

  const selectOption = (option: SingleValue<Option>) => {
    if (clearAfterSelect) {
      clearInput();
      clearSelection();
    } else {
      setSelectedOption(option);
    }
    onSelect(option);
  };

  const clearSelection = () => setSelectedOption(null);
  const clearInput = () => onInputChange && onInputChange("");

  const actionBasedOnInputChange = (input: string, action: InputActionMeta) => {
    if (action.action === "input-change") onInputChange && onInputChange(input);
  };

  const actionBasedOnChange = (
    option: SingleValue<Option> | MultiValue<Option>,
    action: ActionMeta<Option>
  ) => {
    if (action.action === "select-option") {
      selectOption(option as SingleValue<Option>);
    } else if (action.action === "clear") {
      clearInput();
      clearSelection();
      onClear && onClear();
    }
  };

  const isOptionSelected = selectedOption
    ? (option: Option) => option.value === selectedOption.value
    : undefined;

  useEffect(() => {
    setSelectedOption(selection ?? null);
  }, [selection]);

  const CustomOption = (
    props?: OptionProps<Option, boolean, GroupBase<Option>>
  ) =>
    props ? (
      <components.Option {...props}>
        <div
          className={clsx(
            "flex items-center justify-between",
            "!odd:bg-gray-100/80 !even:bg-gray-50"
          )}
        >
          <div>{props?.label ?? ""}</div>
          {props.isSelected && (
            <Icon icon="check" size={5} color="text-blue-600" />
          )}
        </div>
      </components.Option>
    ) : null;

  return (
    <div className={clsx("flex flex-col", className)}>
      {label && (
        <label className="mb-1 block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <Select
        name={name}
        options={options}
        isClearable={true}
        isSearchable={true}
        isLoading={isLoading}
        isDisabled={disabled}
        value={selectedOption}
        classNamePrefix="select"
        placeholder={placeholder}
        className="w-[100%] focus:shadow-none shadow-none cursor-text !rounded-md text-gray-700 mb-[2px]"
        classNames={{
          container: () =>
            clsx("!border-none !rounded-md shadow-sm !text-sm", height),
          control: (state) =>
            clsx(
              "!rounded-md min-w-[200px] bg-white border !shadow-none",
              state.isFocused ? "border-blue-500" : "!border-gray-300",
              height
            ),
          input: () => "cursor-text",
          menu: () => "!border-none rounded-md overflow-hidden bg-gray-50",
          menuList: () =>
            clsx(
              getScrollbarStyle("gray"),
              "mt-0 !py-0 overflow-none !border-none bg-gray-50"
            ),
          option: (state) =>
            clsx(
              "!cursor-pointer !text-sm select-none !border-none",
              "hover:bg-blue-700 hover:text-white",
              state.isSelected
                ? "!bg-white !text-gray-900 font-semibold"
                : "odd:bg-gray-100/80 even:bg-gray-50"
            ),
          indicatorsContainer: () => "cursor-pointer",
          placeholder: () => "text-gray-400 text-sm",
        }}
        styles={{
          input: (base) => ({
            ...base,
            cursor: "text",
            "[type='text']:focus": { boxShadow: "none" },
          }),
        }}
        onChange={actionBasedOnChange}
        isOptionSelected={isOptionSelected}
        components={{ Option: CustomOption }}
        onInputChange={actionBasedOnInputChange}
      />
    </div>
  );
};
