import { VideoProvider } from "@generated/graphql";
import { Tooltip } from "components/shared";
import { useAttendanceData } from "contexts/AttendanceDataProvider";
import pluralize from "pluralize";
import { useEffect } from "react";

const SUPPORTED_VIDEO_PROVIDERS = [
  VideoProvider.Zoom,
  VideoProvider.GoogleMeets,
  VideoProvider.MicrosoftTeams,
];

type Props = {
  cohortId: string;
  showHostMissing?: boolean;
  videoProvider: VideoProvider;
};

export function RoomOccupancyIndicator({
  cohortId,
  showHostMissing = true,
  videoProvider,
}: Props) {
  const { attendanceData, includeCohortInDataFetch } = useAttendanceData();
  const data = attendanceData[cohortId];

  useEffect(() => {
    includeCohortInDataFetch(cohortId);
  }, [cohortId, includeCohortInDataFetch]);

  if (!SUPPORTED_VIDEO_PROVIDERS.includes(videoProvider)) return null;

  return (
    <Tooltip
      className="w-5 h-5 text-blue-600 cursor-help"
      content={
        <div className="flex flex-col items-start">
          <p>Participants: {data?.participants ?? "N/A"}</p>
          <p>Hosts: {data?.hosts ?? "N/A"}</p>
        </div>
      }
      tooltipProps={{
        place: "bottom",
      }}
    >
      {data?.participants ? (
        <p className="text-green-600 font-bold">
          {pluralize("participant", data.participants, true)}
        </p>
      ) : null}
      {showHostMissing && data?.hosts === 0 ? (
        <p className="text-red-600 font-bold">Host Absent</p>
      ) : null}
    </Tooltip>
  );
}
