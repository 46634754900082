import { configureAmplify } from "@lib/amplify";
import { startBugsnag } from "@lib/bugsnag";
import { Layout } from "components/shared";
import { AuthProvider } from "contexts/AuthProvider";
import { BreadcrumbsProvider } from "contexts/BreadcrumbsProvider";
import { FilterInputsProvider } from "contexts/FilterInputsProvider";
import { LayoutProvider } from "contexts/LayoutProvider";
import { ToastProvider } from "contexts/ToastProvider/ToastProvider";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { Suspense } from "react";

import { TutorDashboardDataProvider } from "contexts/TutorDashboardDataProvider";
import "styles/antDStyleOverrides.css";
import "styles/commalist.css";
import "styles/emojiJumpingAnimation.css";
import "styles/global.css";
import "styles/groupEventPopup.css";
import "styles/loadingBarsAnimation.css";
import "styles/loadingText.css";
import "styles/richText.css";
import "styles/stickynote.css";
import "styles/tabNavigation.css";
import "tailwindcss/tailwind.css";

startBugsnag();
configureAmplify();

const DynamicTitleHead = dynamic(
  () => import("../components/shared/TitleHead"),
  { ssr: false }
);

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <Suspense fallback={<p>TbT</p>}>
      <meta name="viewport" content="width=device-width, minimum-scale=1.0" />
      <DynamicTitleHead />
    </Suspense>
    <AuthProvider>
      <LayoutProvider>
        <BreadcrumbsProvider>
          <FilterInputsProvider>
            <TutorDashboardDataProvider>
              <Layout>
                <ToastProvider />
                <Component {...pageProps} />
              </Layout>
            </TutorDashboardDataProvider>
          </FilterInputsProvider>
        </BreadcrumbsProvider>
      </LayoutProvider>
    </AuthProvider>
  </>
);

export default MyApp;
