import TextStyle from "@tiptap/extension-text-style";
import { StarterKit } from "@tiptap/starter-kit";

export const EXTENSIONS = [
  TextStyle.configure(),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];
