import { CognitoUser } from "@aws-amplify/auth";
import { CurrentUserQuery } from "@generated/graphql";
import { AccountAccessRole } from "@utils/routes";
import { ThemeMode } from "components/shared/types";

export enum LoginStatus {
  Success = "SUCCESS",
  ChangePassword = "CHANGE_PASSWORD",
  Failure = "FAILURE",
}

export type AuthState = {
  user: CurrentUserQuery["currentUser"] | null;
  token: string | null;
  isAuthenticating: boolean;
};

export interface LoginResult {
  status: LoginStatus;
  message: string;
  accountRole?: AccountAccessRole;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cognitoUser: CognitoUser | any; // TODO: type cognitoUser type.
}

export type NavigationToastProps = {
  message: string;
  sub: string;
  theme: ThemeMode;
};
