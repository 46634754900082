import { useAuth } from "contexts/AuthProvider";
import { useBreadcrumbs } from "contexts/BreadcrumbsProvider";
import { isEqual } from "lodash";
import { ReactNode, useEffect } from "react";
import { Breadcrumb } from "types/global";
import { Header } from "./components/Header";

type Props = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  badges?: ReactNode;
  breadcrumbs?: Breadcrumb[];
  actionsSection?: ReactNode;
  banner?: ReactNode;
};

export const PageHeader = ({
  title,
  subtitle,
  breadcrumbs,
  banner,
  badges,
  actionsSection,
}: Props) => {
  const { isAuthenticated } = useAuth();
  const { breadcrumbs: oldCrumbs, setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (isAuthenticated && !isEqual(breadcrumbs, oldCrumbs))
      setBreadcrumbs(breadcrumbs ?? []);
  }, [breadcrumbs, isAuthenticated, oldCrumbs, setBreadcrumbs]);

  return title || actionsSection || banner ? (
    <>
      <div className="flex z-[50] flex-wrap gap-3 justify-between items-center pl-1">
        {title && <Header badges={badges} title={title} subtitle={subtitle} />}
        {actionsSection && (
          <div className="flex gap-2 justify-end">{actionsSection}</div>
        )}
      </div>
      {banner}
    </>
  ) : null;
};
