import clsx from "clsx";
import { ReactNode } from "react";
import { MdCheck } from "react-icons/md";

type NotifyResultProps = {
  title: ReactNode | string;
  items: ReactNode[] | string[];
  emptyText: ReactNode | string;
  layout?: "row" | "column";
};

export function NotifyResult({
  title,
  items,
  emptyText,
  layout = "row",
}: NotifyResultProps) {
  return (
    <div>
      {items.length > 0 ? (
        <h3 className="font-bold mb-2">
          <MdCheck className="inline text-green-600" /> {title}
        </h3>
      ) : (
        <h3 className="font-bold mb-2 text-gray-400">
          <MdCheck className="inline" /> {emptyText}
        </h3>
      )}

      {items.length > 0 && (
        <div
          className={clsx(
            layout === "row"
              ? "flex flex-row gap-2 ml-6 mb-2 basis-auto flex-wrap"
              : "flex flex-col gap-2 ml-6 mb-2 flex-wrap"
          )}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={clsx(
                layout === "row"
                  ? "basis-40 p-1 border border-gray-300 text-sm rounded bg-gray-50"
                  : " p-1 border border-gray-300 text-sm rounded bg-gray-50"
              )}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
