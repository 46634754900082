import { StudentStatus } from "@generated/graphql";
import { Routes } from "@utils/routes";
import { Icon, Link, Tooltip } from "components/shared";
import { getStudentStatusTooltip } from "components/shared/AttendanceGrades/helpers";
import { STUDENT_WIDTH } from "../../../constants";

type Props = {
  studentStatus: StudentStatus;
  studentName: string;
  startDate: Date;
};

export const StudentNameSection = ({
  studentStatus,
  studentName,
  startDate,
}: Props) => (
  <div className="flex pt-[2px] h-fit" style={{ width: `${STUDENT_WIDTH}px` }}>
    <Tooltip
      tooltipProps={{ place: "right" }}
      disabled={studentStatus === StudentStatus.Fixture}
      className="flex items-center gap-1 cursor-pointer p-2"
      content={getStudentStatusTooltip(
        studentStatus ?? StudentStatus.New,
        startDate
      )}
    >
      <Link
        route={Routes.engagementsDashboard}
        routeProps={[`search=${studentName}`]}
        className="flex flex-wrap items-center leading-5 pl-1"
      >
        {studentName}
      </Link>
      {studentStatus !== StudentStatus.Fixture && (
        <Icon
          icon="new"
          size={4}
          color={
            studentStatus === StudentStatus.New
              ? "text-yellow-500"
              : "text-indigo-500"
          }
        />
      )}
    </Tooltip>
  </div>
);
