import clsx from "clsx";
import Image from "next/legacy/image";

export enum TbTCheckVariant {
  BLACK_CIRCLE_FILL = "/logos/tbt-check-black-circle-fill.svg",
  BLACK_CIRCLE_OUTLINE = "/logos/tbt-check-black-circle-outline.svg",
  BLACK_SQUARE_FILL = "/logos/tbt-check-black-square-fill.svg",
  BLACK_SQUARE_OUTLINE = "/logos/tbt-check-black-square-outline.svg",
  BLUE_CIRCLE_FILL = "/logos/tbt-check-blue-circle-fill.svg",
  BLUE_CIRCLE_OUTLINE = "/logos/tbt-check-blue-circle-outline.svg",
  BLUE_SQUARE_FILL = "/logos/tbt-check-blue-square-fill.svg",
  BLUE_SQUARE_OUTLINE = "/logos/tbt-check-blue-square-outline.svg",
}

type Props = {
  size: number;
  className?: string;
  variant?: TbTCheckVariant;
  background?: string;
};

export const TbTCheck = ({
  size,
  className,
  background,
  variant = TbTCheckVariant.BLUE_SQUARE_FILL,
}: Props) => (
  <div
    className={clsx("relative p-0 w-fit m-0", className)}
    style={{ height: `${size}px` }}
  >
    <div
      className={clsx(
        "absolute rounded-sm h-[82%] w-[80%] top-[10%] right-[10%]",
        background
      )}
    />
    <Image
      width={size}
      height={size}
      src={variant}
      alt="Tutored by Teachers Logo"
      layout="fixed"
    />
  </div>
);
