import { ConfigProvider, DatePicker, theme } from "antd";
import { CalendarEventInfo } from "components/cohorts/CohortsScheduleCalendar";
import { addMonths, isSameMonth, subMonths } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useId, useMemo, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { Icon } from "../Icon";
import { defaultCellRenderer } from "./cellRenderers";
import { CalendarNavButton } from "./components/CalendarNavButton";

const CALENDAR_WIDTH = 246;
const NAV_BUTTONS_WIDTH = 120;

type Props = {
  currentWeekShownDate: Date;
  setTargetDateTime: (date: Date) => void;
  events?: CalendarEventInfo[];
  cohortIds?: string[];
  startBound?: Date;
  endBound?: Date;
  refetch?: () => void;
  calendarNum?: number;
  setCalendarNum?: (count: number) => void;
  cellRender?: (current: dayjs.Dayjs, month: dayjs.Dayjs) => React.ReactNode;
  currMonth?: Date;
  initCenterDate?: boolean;
  setCurrMonth?: (date: Date) => void;
};

export const NavigationCalendars = ({
  endBound,
  startBound,
  currMonth,
  calendarNum,
  currentWeekShownDate,
  initCenterDate = false,
  refetch,
  cellRender,
  setCurrMonth,
  setCalendarNum,
  setTargetDateTime,
}: Props) => {
  const navigationCalendarId = useId();
  const [navWidth, setNavWidth] = useState<number>(0);
  const [showCalendars, setShowCalendars] = useState(false);
  const calendarNavRef = useRef<HTMLDivElement | null>(null);
  const [hasCalendarValueProp, setHasCalendarValueProp] = useState(true);
  const [targetMonth, setTargetMonth] = useState<Date>(currMonth ?? new Date());

  const navigateToMonth = (month: Date) => {
    setHasCalendarValueProp(true);
    setCurrMonth && setCurrMonth(month);
    setTargetMonth(month);
  };

  useEffect(() => {
    setHasCalendarValueProp(true);
  }, [currentWeekShownDate]);

  useEffect(() => {
    const handleResize = () =>
      setNavWidth(calendarNavRef.current?.offsetWidth || 0);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calendarCount = useMemo(() => {
    if (navWidth === 0) return calendarNum ?? 1;
    const calCount = Math.floor(
      (navWidth - NAV_BUTTONS_WIDTH) / CALENDAR_WIDTH
    );
    setCalendarNum?.(calCount);

    return calCount;
  }, [calendarNum, navWidth, setCalendarNum]);

  const [debouncedCalendarCount] = useDebounce(calendarCount, 100);

  const showNav = navWidth > CALENDAR_WIDTH;
  const showNavButton = navWidth > CALENDAR_WIDTH + NAV_BUTTONS_WIDTH;

  const getCurrentCalendarValueProp = (value: dayjs.Dayjs) =>
    hasCalendarValueProp ? { value } : {};

  useEffect(() => {
    if (debouncedCalendarCount > 0 && !showCalendars) setShowCalendars(true);
    setHasCalendarValueProp(false);
  }, [debouncedCalendarCount, showCalendars]);

  useEffect(() => {
    if (initCenterDate) {
      const initMonth = currMonth ?? new Date();
      const calendarOffset = Math.floor(debouncedCalendarCount / 2);
      const monthOffset = subMonths(initMonth, calendarOffset);
      setTargetMonth(monthOffset);
    }
  }, [currMonth, debouncedCalendarCount, initCenterDate]);

  const handleDayChange = (
    newDate: dayjs.Dayjs | null,
    curCalMonth: dayjs.Dayjs
  ) => {
    if (newDate) {
      setTargetDateTime(newDate?.toDate());
      if (!isSameMonth(newDate.toDate(), curCalMonth.toDate())) {
        const monthOffset = newDate.toDate() < curCalMonth.toDate() ? -1 : 1;
        navigateToMonth(addMonths(targetMonth, monthOffset));
      }
      refetch && refetch();
    }
  };

  const renderDatePickerContainer = (targetMonth: Date, offset: number) => {
    const defaultDay = dayjs(addMonths(targetMonth, offset));
    const id = `date-picker-container-${navigationCalendarId}-${defaultDay.toISOString()}`;

    const cellRenderer = (current: number | dayjs.Dayjs | string) =>
      cellRender
        ? cellRender(dayjs(current), defaultDay)
        : defaultCellRenderer(
            dayjs(current),
            defaultDay,
            currentWeekShownDate,
            startBound,
            endBound
          );

    return (
      <div className="relative w-[243px]" key={id} id={id}>
        <DatePicker
          autoFocus
          showNow={false}
          defaultValue={defaultDay}
          popupStyle={{ zIndex: 0, position: "absolute" }}
          open={showCalendars}
          cellRender={(current) => cellRenderer(current)}
          onChange={(value) => handleDayChange(value, defaultDay)}
          getPopupContainer={() => document.getElementById(id) as HTMLElement}
          style={{ visibility: "hidden", top: "-22px", height: 0 }}
          superNextIcon={null}
          superPrevIcon={null}
          nextIcon={
            !showNavButton ? <Icon icon="chevronRight" size={5} /> : null
          }
          prevIcon={
            !showNavButton ? <Icon icon="chevronLeft" size={5} /> : null
          }
          {...getCurrentCalendarValueProp(defaultDay)}
        />
      </div>
    );
  };

  return (
    <ConfigProvider theme={{ algorithm: theme.compactAlgorithm }}>
      <div
        className="isolate flex flex-row gap-x-1 justify-center grow h-[247px]"
        ref={calendarNavRef}
      >
        {showNavButton && (
          <CalendarNavButton
            position="left"
            targetMonth={targetMonth}
            navigateToMonth={navigateToMonth}
          />
        )}
        <div className="flex flex-row gap-x-1">
          {showNav &&
            [...Array(Math.max(calendarCount, 1))].map((__, i) =>
              renderDatePickerContainer(targetMonth, i)
            )}
        </div>
        {showNavButton && (
          <CalendarNavButton
            position="right"
            targetMonth={targetMonth}
            navigateToMonth={navigateToMonth}
          />
        )}
      </div>
    </ConfigProvider>
  );
};
