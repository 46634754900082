import { BsDatabase } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";

type SharedProps = { displayName: string };

export function SavedDisplayNameLabel({ displayName }: SharedProps) {
  return (
    <div className="flex flex-row gap-x-2">
      <BsDatabase className="w-4 h-4 text-emerald-700" aria-hidden="true" />
      <span className="text-emerald-700 font-semibold">{displayName}</span>
    </div>
  );
}

export function RoboDisplayNameLabel({ displayName }: SharedProps) {
  return (
    <div className="flex flex-row gap-x-2">
      <FaRobot className="h-4 w-4 text-blue-700" aria-hidden="true" />
      <span className="text-blue-700 font-semibold">{displayName}</span>
    </div>
  );
}
