import { getInputStyle } from "@utils/styleStrings";
import { DatePicker } from "antd";
import clsx from "clsx";
import { disabledDay } from "components/shared/Calendars/utils";
import dayjs from "dayjs";

interface Props {
  title?: string;
  endBound: Date;
  startBound: Date;
  value: Date;
  compareValue?: Date;
  isStart?: boolean;
  className?: string;
  onChange: (date: Date) => void;
}

export const DateTimeInput = ({
  endBound,
  startBound,
  value,
  title,
  compareValue,
  onChange,
  className = "col-span-3",
  isStart = undefined,
}: Props) => (
  <div className={clsx(className)}>
    {title && (
      <label className="block text-sm font-medium text-gray-700 mb-[4px]">
        {title}
      </label>
    )}
    <DatePicker
      onChange={(date) => date && onChange(date.toDate())}
      disabledDate={(date) =>
        disabledDay(
          date,
          startBound,
          endBound,
          isStart ? value : compareValue,
          isStart ? compareValue : value,
          isStart
        )
      }
      defaultValue={dayjs(value)}
      style={{ ...getInputStyle }}
      showNow={false}
    />
  </div>
);
