import clsx from "clsx";
import { ReactNode, useState } from "react";
import { FieldError } from "../Error";
import { SelectMenu, SelectMenuOption } from "./SelectMenu";

type Props<O> = {
  error?: string;
  border?: string;
  label: ReactNode;
  heightPx?: number;
  disabled?: boolean;
  className?: string;
  listWidth?: string;
  initialIndex?: number;
  labelClassName?: string;
  selectClassName?: string;
  options: SelectMenuOption<O>[];
  listAlignment?: "left" | "right";
  onSelect: (option: SelectMenuOption<O>) => void;
};

export function LabeledSelectMenu<O>({
  label,
  error,
  options,
  className,
  heightPx = 38,
  labelClassName,
  selectClassName,
  initialIndex = 0,
  disabled = false,
  border = "border-0",
  listWidth = "w-auto",
  listAlignment = "left",
  onSelect,
}: Props<O>) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="flex flex-col w-auto cursor-pointer shadow-button-outer rounded-md"
      style={{ height: `${heightPx}px` }}
    >
      <div
        className={clsx(
          "flex items-center bg-slate-600 rounded-md h-full pr-[3px] w-fit",
          "hover:bg-slate-800 group shadow-button-inner",
          className
        )}
      >
        <div
          onClick={() => setOpen(!open)}
          className={clsx(
            "flex justify-center items-center w-auto px-6 h-full text-center",
            "text-white -mr-[5px] leading-tight text-sm font-medium",
            labelClassName
          )}
        >
          {label}
        </div>
        <div className="flex relative h-full items-center w-fit">
          <SelectMenu
            heightPx={heightPx - 6}
            darkMode
            border={border}
            hasAttachedLabel
            options={options}
            disabled={disabled}
            externalOpen={open}
            listWidth={listWidth}
            initialIndex={initialIndex}
            listAlignment={listAlignment}
            className={clsx(selectClassName, "z-3 overflow-none")}
            onSelect={onSelect}
          />
        </div>
      </div>
      <FieldError msg={error} />
    </div>
  );
}
