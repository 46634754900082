import clsx from "clsx";
import {
  FaBullseye,
  FaCheck,
  FaExclamation,
  FaMinus,
  FaPlus,
  FaQuestion,
  FaTrash,
} from "react-icons/fa";
import { GiSubmarine } from "react-icons/gi";
import {
  IoMdAddCircle,
  IoMdCloseCircle,
  IoMdHelpCircle,
  IoMdRemoveCircle,
  IoMdSchool,
} from "react-icons/io";
import { IoAlertCircle, IoCheckmarkCircle } from "react-icons/io5";
import {
  TbAlarmFilled,
  TbThumbUpFilled,
  TbUser,
  TbUserOff,
  TbUsers,
  TbZzz,
} from "react-icons/tb";

export type AttendanceIconSubject = "student" | "teacher" | "indicator";
export type AttendanceIconType =
  | "normal"
  | "cross"
  | "plus"
  | "minus"
  | "check"
  | "exclamation"
  | "question"
  | "snooze"
  | "multi"
  | "missing"
  | "thumbsup"
  | "thumbsdown"
  | "trash"
  | "alarm"
  | "sub";

type Props = {
  className: string;
  subject: AttendanceIconSubject;
  type: AttendanceIconType;
};
export function AttendanceIcon({ className, subject, type }: Props) {
  let icon: React.ReactNode = null;

  if (subject === "indicator") {
    switch (type) {
      case "cross":
        icon = <IoMdCloseCircle className="w-full h-full" />;
        break;
      case "plus":
        icon = <IoMdAddCircle className="w-full h-full" />;
        break;
      case "minus":
        icon = <IoMdRemoveCircle className="w-full h-full" />;
        break;
      case "check":
        icon = <IoCheckmarkCircle className="w-full h-full" />;
        break;
      case "exclamation":
        icon = <IoAlertCircle className="w-full h-full" />;
        break;
      case "question":
        icon = <IoMdHelpCircle className="w-full h-full" />;
        break;
      case "snooze":
        icon = <TbZzz className="w-full h-full" />;
        break;
      case "multi":
        icon = <TbUsers className="w-full h-full" />;
        break;
      case "missing":
        icon = <TbUserOff className="w-full h-full" />;
        break;
      case "thumbsup":
        icon = <TbThumbUpFilled className="w-full h-full" />;
        break;
      case "thumbsdown":
        icon = (
          <TbThumbUpFilled className="w-full h-full rotate-180 -scale-x-100" />
        );
        break;
      case "trash":
        icon = <FaTrash className="w-full h-full" />;
        break;
      case "alarm":
        icon = <TbAlarmFilled className="w-full h-full" />;
        break;
      case "sub":
        icon = <GiSubmarine className="w-full h-full" />;
        break;
      case "normal":
      default:
        icon = <FaBullseye className="w-full h-full" />;
    }
  } else if (subject === "teacher" || subject === "student") {
    switch (type) {
      case "multi":
        icon = <TbUsers className="w-full h-full" />;
        break;
      case "missing":
        icon = <TbUserOff className="w-full h-full" />;
        break;
      case "normal":
      case "cross":
      case "plus":
      case "minus":
      case "check":
      case "exclamation":
      case "question":
      case "snooze":
      case "multi":
      case "missing":
      case "thumbsup":
      case "thumbsdown":
      case "trash":
      case "alarm":
      case "sub":
      default:
        icon = <TbUser className="w-full h-full" />;
    }
  }

  return (
    <div className={className}>
      {subject === "indicator" ? (
        icon
      ) : (
        <SubIconWrapper type={type}>
          <SubjectWrapper subject={subject} type={type}>
            {icon}
          </SubjectWrapper>
        </SubIconWrapper>
      )}
    </div>
  );
}

type SubjectWrapperProps = {
  subject: AttendanceIconSubject;
  type: AttendanceIconType;
  children: React.ReactNode;
};
function SubjectWrapper({ subject, type, children }: SubjectWrapperProps) {
  if (subject === "student") {
    return (
      <div className="relative scale-y-[0.93] scale-x-[0.9] origin-bottom">
        {children}
        <IoMdSchool
          className={clsx(
            "absolute -top-[6%] w-[50%] h-[50%] origin-center",
            type === "multi" ? "left-[12%]" : "left-[25%]",
            type === "missing" ? "scale-x-[1.4]" : "-scale-x-[1.4]"
          )}
        />
      </div>
    );
  }

  return <>{children}</>;
}

type TypeWrapperProps = {
  type: AttendanceIconType;
  children: React.ReactNode;
};
function SubIconWrapper({ type, children }: TypeWrapperProps) {
  if (type === "normal" || type === "multi" || type === "missing") {
    return <>{children}</>;
  }

  let subIcon: React.ReactNode = null;

  switch (type) {
    case "cross":
      subIcon = <FaPlus className="w-full h-full rotate-45" />;
      break;
    case "plus":
      subIcon = <FaPlus className="w-full h-full" />;
      break;
    case "minus":
      subIcon = <FaMinus className="w-full h-full" />;
      break;
    case "check":
      subIcon = <FaCheck className="w-full h-full" />;
      break;
    case "exclamation":
      subIcon = <FaExclamation className="w-full h-full" />;
      break;
    case "question":
      subIcon = <FaQuestion className="w-full h-full" />;
      break;
    case "snooze":
      subIcon = <TbZzz className="w-[120%] h-[120%]" />;
      break;
    case "thumbsup":
      subIcon = <TbThumbUpFilled className="w-full h-full" />;
      break;
    case "thumbsdown":
      subIcon = (
        <TbThumbUpFilled className="w-full h-full rotate-180 -scale-x-100" />
      );
      break;
    case "trash":
      subIcon = <FaTrash className="w-[90%] h-[90%]" />;
      break;
    case "alarm":
      subIcon = <TbAlarmFilled className="w-[120%] h-[120%]" />;
      break;
    case "sub":
      subIcon = (
        <GiSubmarine className="absolute w-[160%] h-[160%] -left-[25%] -top-[40%]" />
      );
      break;
  }

  return (
    <div className="relative -left-[15%] w-full h-full">
      {children}
      <div className="absolute w-[35%] h-[35%] -right-[9%] top-[34%]">
        {subIcon}
      </div>
    </div>
  );
}
