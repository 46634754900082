import {
  CohortSessionStudentAttendanceStatus,
  StudentStatus,
} from "@generated/graphql";
import clsx from "clsx";
import { AttendanceIcon } from "components/shared/AttendanceGrades/AttendanceIcon";
import { getNormalizedShortReadableDate } from "helpers/dateText";
import { getAttendanceStatusStyles } from "./utils";

export const getStudentAttendanceIcon = (
  status: CohortSessionStudentAttendanceStatus,
  color?: string | boolean,
  className?: string,
  indicator?: boolean
) => (
  <AttendanceIcon
    type={getAttendanceStatusStyles(status).iconType}
    subject={indicator ? "indicator" : "student"}
    className={clsx(
      "w-7 h-7",
      color || getAttendanceStatusStyles(status).peepColor,
      className
    )}
  />
);

export const getStudentStatusTooltip = (
  studentStatus: StudentStatus,
  studentStart: Date
) => (
  <div className="w-[170px] flex flex-col items-center justify-center">
    <div>{studentStatus === StudentStatus.New ? "NEW" : "TRANSFERRED"}</div>
    <div>
      {`Start Date: ${getNormalizedShortReadableDate(new Date(studentStart))}`}
    </div>
  </div>
);
