import clsx from "clsx";
import { Icon } from "../Icon";
import { Badge } from "./Badge";

type Props = { className?: string };

export const ArchivedBadge = ({ className }: Props) => (
  <Badge
    className={clsx(
      "text-orange-900 select-none whitespace-nowrap gap-1 bg-orange-200",
      className
    )}
  >
    <Icon icon="archive" size={3} color="text-orange-900" />
    Archived
  </Badge>
);
