import clsx from "clsx";
import { useState } from "react";
import { Chevron } from "./Chevron";
import { Icon } from "./Icon";

export type ChecklistItemProps = {
  title: string;
  trackedForCompletion?: boolean;
  complete?: boolean;
  loading?: boolean;
  action?: React.ReactNode;
  collapsibleContent?: React.ReactNode;
};

export function ChecklistItem({
  title,
  trackedForCompletion,
  complete,
  loading,
  action,
  collapsibleContent,
}: ChecklistItemProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const getIcon = loading ? (
    <Icon
      icon="loadingIcon"
      size={5}
      color="text-blue-700"
      className="animate-spin"
    />
  ) : !trackedForCompletion ? (
    <Icon icon="infoCircle" size={5} color="text-gray-500" />
  ) : complete ? (
    <Icon icon="checkCircle" size={5} />
  ) : (
    <Icon icon="triangleExclamation" size={5} color="text-gray-600" />
  );

  const toggleCollapsible = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="flex flex-col rounded-md bg-white border border-gray-200 shadow-sm py-2 px-3 gap-y-2">
      <div
        className={clsx(
          "flex items-center w-full text-lg group",
          collapsibleContent ? "cursor-pointer" : "cursor-default"
        )}
        onClick={toggleCollapsible}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3 text-base text-gray-800">
            {getIcon}
            {title}
            {action}
          </div>

          {collapsibleContent && (
            <Chevron
              direction={!isCollapsed ? "UP" : "DOWN"}
              color="text-gray-700 group-hover:text-blue-500"
            />
          )}
        </div>
      </div>
      {collapsibleContent && !isCollapsed && (
        <div
          className={clsx(
            "flex flex-wrap ml-7 mb-1 px-3 py-2 text-base leading-snug text-gray-700",
            "border border-gray-200 rounded-md bg-gray-100"
          )}
        >
          {collapsibleContent}
        </div>
      )}
    </div>
  );
}
