import { Editor } from "@tiptap/react";
import { HeadingSelect } from "./HeadingSelect";
import { MenuBarItem } from "./MenuBarItem";
import clsx from "clsx";

type Props = {
  editor: Editor | null;
  className?: string;
};

export const MenuBar = ({ editor, className }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={clsx("control-group grid grid-cols-12 gap-2", className)}>
      <HeadingSelect editor={editor} className="col-span-3" />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        isSelected={editor.isActive("bold")}
        className="col-span-1 w-full"
        icon={"bold"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        isSelected={editor.isActive("italic")}
        className="col-span-1 w-full"
        icon={"italic"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        isSelected={editor.isActive("strike")}
        className="col-span-1 w-full"
        icon={"strikethrough"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        isSelected={editor.isActive("bulletList")}
        className="col-span-1 w-full"
        icon={"unorderedList"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        isSelected={editor.isActive("orderedList")}
        className="col-span-1 w-full"
        icon={"orderedList"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        disabled={!editor.can().chain().focus().toggleBlockquote().run()}
        isSelected={editor.isActive("blockquote")}
        className="col-span-1 w-full"
        icon={"blockquote"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        isSelected={true}
        className="col-span-1 w-full"
        icon={"undoEdit"}
      />
      <MenuBarItem
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        isSelected={true}
        className="col-span-1 w-full"
        icon={"redoEdit"}
      />
    </div>
  );
};
