import clsx from "clsx";
import { Icon } from "components/shared";
import toast from "react-hot-toast";

export const clearAllToastsButton = (hasToasts: boolean) =>
  hasToasts ? (
    <div className="absolute z-[2000] top-[15px] right-[578px]">
      <div
        className={clsx(
          "flex items-center justify-end hover:bg-gray-500/80 rounded-full",
          "text-sm text-white cursor-pointer group overflow-hidden",
          "transition-width duration-200 ease-in-out",
          "hover:w-[95px] w-[28px] h-[28px]"
        )}
        onClick={() => toast.remove()}
      >
        <span
          className={clsx(
            "flex items-center justify-end relative !w-[100px] truncate h-full",
            "flex-nowrap text-xs font-semibold mr-1"
          )}
        >
          Clear All
        </span>
        <Icon
          icon="remove"
          color="text-white/40"
          size={8}
          className="group-hover:text-white mr-[-3px]"
        />
      </div>
    </div>
  ) : null;
