import { FaRobot } from "react-icons/fa";
import { Tooltip } from "./Tooltip";

type Props = { isAutomaticallyUpdated: boolean };

export const AttendanceRobot = ({ isAutomaticallyUpdated }: Props) =>
  isAutomaticallyUpdated ? (
    <div className="flex items-center cursor-pointer">
      <Tooltip content="This attendance was automatically recorded by the system">
        <FaRobot className="h-6 w-6 text-indigo-600" aria-hidden="true" />
      </Tooltip>
    </div>
  ) : null;
