import clsx from "clsx";
import { Icon } from "./Icon";
import { Spinner } from "./Loading/Spinner";

type Props = {
  icon?: React.ReactNode;
  dataName?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  dataDescription?: React.ReactNode;
  loading?: boolean;
  showIcon?: boolean;
  className?: string;
  actions?: React.ReactNode;
};

export const EmptyStateContainer = ({
  icon,
  actions,
  loading,
  dataName,
  showIcon = true,
  dataDescription,
  title,
  subtitle,
  className,
}: Props) => (
  <div
    className={clsx(
      "flex flex-col justify-center items-center p-5 gap-2 text-md w-full",
      "rounded-lg bg-gray-50/70 shadow-sm min-width-[330px] border border-gray-200",
      className
    )}
  >
    {showIcon ? icon ? icon : <Icon icon="database" size={10} /> : null}

    <h2 className="text-lg font-medium text-gray-900 leading-5">
      {title ?? `${loading ? "Loading" : "No"} ${dataName ?? "Data"}`}
    </h2>

    {loading ? (
      <Spinner size={5} className="mt-1" color="text-blue-500" />
    ) : (
      <>
        <div className="text-sm text-gray-500 max-w-[650px] text-center leading-tight">
          {subtitle ?? `You haven't added any ${dataName ?? "Data"} yet.`}
        </div>
        {dataDescription && (
          <div className="text-xs text-gray-500">{dataDescription}</div>
        )}
      </>
    )}
    {actions && actions}
  </div>
);
