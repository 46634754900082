import { Details, Tooltip } from "components/shared";
import { ExcludedDaysScrollContainer } from "components/shared/ExcludedDays/ExcludedDaysScrollContainer";

type Props = {
  childExcludedDays: Date[];
  parentExcludedDays: Date[];
  showExcludedDaysModal: () => void;
  excludedDayType: string;
  childEntityType: string;
  parentEntityType: string;
  tooltip: string;
};

export const ParentChildExcludedDaysPanel = ({
  childExcludedDays,
  parentExcludedDays,
  showExcludedDaysModal,
  excludedDayType,
  childEntityType,
  parentEntityType,
  tooltip,
}: Props) => (
  <Details.Line>
    <Details.Term>
      <div className="flex items-center text-sm font-medium text-gray-500 gap-2">
        {excludedDayType} Days
        <Tooltip
          className="w-5 h-5 text-blue-600 mr-2 cursor-help"
          content={
            <div className="block w-48 text-sm text-center">{tooltip}</div>
          }
        />
      </div>
    </Details.Term>
    <div className="flex w-full flex-col sm:col-span-2">
      <div className="grid gap-x-4 sm:grid-cols-2 gap-y-1">
        <div className="flex w-full flex-col gap-2 sm:col-span-1">
          <Details.Detail>
            {parentEntityType} {excludedDayType} Days
          </Details.Detail>
        </div>
        <div className="flex flex-col w-full gap-2 sm:col-span-1">
          <Details.Detail>
            {childEntityType} {excludedDayType} Days
          </Details.Detail>
        </div>
        <div className="flex w-full flex-col gap-2 sm:col-span-1">
          <ExcludedDaysScrollContainer
            excludedDays={parentExcludedDays}
            noDaysMessage={`No ${parentEntityType} ${excludedDayType} Days`}
          />
        </div>
        <div className="flex flex-col w-full gap-2 sm:col-span-1">
          <ExcludedDaysScrollContainer
            onClick={showExcludedDaysModal}
            excludedDays={childExcludedDays}
            noDaysMessage={`No ${childEntityType} ${excludedDayType} Days`}
          />
        </div>
      </div>
    </div>
  </Details.Line>
);
