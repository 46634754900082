import clsx from "clsx";
import { useState } from "react";

type ColumnElement<I> = {
  id: I;
  title: string;
  content: React.ReactNode;
};

/**
 * The Breakpoint is the minimum width at which the columns will appear besides
 * each other before they collapse to save space and switch to tabs.
 *
 * Basically: "Larger columns need larger breakpoints."
 *
 * * `zero` is a special breakpoint that will always show the columns and never the
 * tabs no matter how narrow the screen is.
 * * `infinite` will always show the tabs no matter how wide the screen is.
 * * `infinite-unmount` like `infinite`, but also unmounts the inactive columns.
 * * `disabled` is a special breakpoint that will never show the tabs. If there are
 * more than one column, only the first column will appear.
 */
type Breakpoint =
  | "zero"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "infinite"
  | "infinite-unmount"
  | "disabled";

type Props<I> = {
  columns: ColumnElement<I>[];
  onColumnChange?: (id: I) => void;
  breakpoint?: Breakpoint;
  initialColId?: I;
  className?: string;
};

/**
 */
export function TabbedColumn<I>({
  columns,
  onColumnChange,
  breakpoint = "xl",
  initialColId,
  className,
}: Props<I>) {
  const [activeColId, setActiveColId] = useState<I | null>(
    initialColId ?? columns[0].id ?? null
  );

  if (columns.length === 0) return null;
  if (columns.length === 1) breakpoint = "disabled";

  return (
    <div className="flex flex-col">
      {/* Tabs Bar */}
      <div className={clsx("block", BREAKPOINTS[breakpoint].tabsDisplay)}>
        <nav
          className="isolate flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {columns.map(({ id, title }) => {
            const isActiveTab = id === activeColId;
            return (
              <div
                key={String(id)}
                className={clsx(
                  isActiveTab ? "bg-white" : "bg-gray-50 hover:bg-blue-100",
                  "text-gray-900 first:rounded-tl-lg last:rounded-tr-lg",
                  "group font-md shadow relative flex items-center justify-center flex-1",
                  "min-w-0 overflow-hidden cursor-pointer",
                  "py-4 px-4 text-sm font-medium text-center focus:z-10"
                )}
                aria-current={isActiveTab ? "page" : undefined}
                onClick={() => {
                  setActiveColId(id);
                  onColumnChange?.(id);
                }}
              >
                <span>{title}</span>
                <div
                  aria-hidden="true"
                  className={clsx(
                    isActiveTab ? "bg-blue-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-1"
                  )}
                />
              </div>
            );
          })}
        </nav>
      </div>

      {/* Columns */}
      <div className={clsx("w-full grow", BREAKPOINTS[breakpoint].colsClass)}>
        {columns.map(({ id, content }) => {
          const isActiveColumn = id === activeColId;
          if (breakpoint === "infinite-unmount" && !isActiveColumn) return null;
          return (
            <div
              key={String(id)}
              className={clsx(
                "min-w-0 flex-1",
                BREAKPOINTS[breakpoint].colFlex,
                isActiveColumn ? BREAKPOINTS[breakpoint].colBlock : "hidden"
              )}
            >
              <div
                className={clsx(
                  BREAKPOINTS[breakpoint].colMin,
                  BREAKPOINTS[breakpoint].colFlexOne
                )}
              >
                <div className={clsx("relative h-full", className)}>
                  {content}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const BREAKPOINTS = {
  zero: {
    tabsDisplay: "zero:hidden",
    colsClass: "zero:flex zero:gap-x-2",
    colBlock: "zero:block",
    colMin: "zero:min-w-0",
    colFlex: "zero:flex",
    colFlexOne: "zero:flex-1",
  },
  sm: {
    tabsDisplay: "sm:hidden",
    colsClass: "sm:flex sm:gap-x-2",
    colBlock: "sm:block",
    colMin: "sm:min-w-0",
    colFlex: "sm:flex",
    colFlexOne: "sm:flex-1",
  },
  md: {
    tabsDisplay: "md:hidden",
    colsClass: "md:flex md:gap-x-2",
    colBlock: "md:block",
    colMin: "md:min-w-0",
    colFlex: "md:flex",
    colFlexOne: "md:flex-1",
  },
  lg: {
    tabsDisplay: "lg:hidden",
    colsClass: "lg:flex lg:gap-x-2",
    colBlock: "lg:block",
    colMin: "lg:min-w-0",
    colFlex: "lg:flex",
    colFlexOne: "lg:flex-1",
  },
  xl: {
    tabsDisplay: "xl:hidden",
    colsClass: "xl:flex xl:gap-x-2",
    colBlock: "xl:block",
    colMin: "xl:min-w-0",
    colFlex: "xl:flex",
    colFlexOne: "xl:flex-1",
  },
  "2xl": {
    tabsDisplay: "2xl:hidden",
    colsClass: "2xl:flex 2xl:gap-x-2",
    colBlock: "2xl:block",
    colMin: "2xl:min-w-0",
    colFlex: "2xl:flex",
    colFlexOne: "2xl:flex-1",
  },
  infinite: {
    tabsDisplay: "",
    colsClass: "",
    colBlock: "",
    colMin: "",
    colFlex: "",
    colFlexOne: "",
  },
  "infinite-unmount": {
    tabsDisplay: "",
    colsClass: "",
    colBlock: "",
    colMin: "",
    colFlex: "",
    colFlexOne: "",
  },
  disabled: {
    tabsDisplay: "hidden",
    colsClass: "flex",
    colBlock: "block",
    colMin: "min-w-0",
    colFlex: "flex",
    colFlexOne: "flex-1",
  },
};
