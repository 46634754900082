import { gql, useQuery } from "@apollo/client";
import { GetAttendanceScorecardQuery } from "@generated/graphql";
import { subMonths } from "date-fns";
import { useState } from "react";
import { OptionsToggleButton } from "../../Buttons";
import { EmptyStateContainer } from "../../EmptyStateContainer";
import { Icon } from "../../Icon";
import { LoadingTableSkeleton } from "../../Loading";
import { Modal } from "../../Modal";
import { triggerErrorToast } from "../../Toast";
import { AttendanceScorecardTable } from "./components/AttendanceScorecardTable";

export enum TeacherScorecardPeriod {
  lastSixMonths = "Last 6 Months",
  allTime = "All Time",
}

const ATTENDANCE_SCORECARD_QUERY = gql`
  query GetAttendanceScorecard($userId: ID!) {
    getAttendanceScorecard(userId: $userId) {
      id
      name
      idap
      email
      phoneNumber
      attendanceScorecardRecords {
        ...AttendanceScorecardTable_Teachers
      }
    }
  }
  ${AttendanceScorecardTable.fragments.attendance}
`;

type Props = {
  show: boolean;
  closeModal: () => void;
  userId: string;
};

export const AttendanceScorecardModal = ({
  show,
  closeModal,
  userId,
}: Props) => {
  const [periodView, setPeriodView] = useState<number>(0);
  const sixMonthsAgo = subMonths(new Date(), 6);
  const allTime = periodView === 1;

  const { loading, data } = useQuery<GetAttendanceScorecardQuery>(
    ATTENDANCE_SCORECARD_QUERY,
    {
      variables: { userId },
      fetchPolicy: "network-only",
      onError: (error) =>
        triggerErrorToast({
          message: "Something went wrong when querying for the attendance data",
          sub: error.message,
        }),
      skip: !userId,
    }
  );

  const { attendanceScorecardRecords } = data?.getAttendanceScorecard || {};
  const { name, email, phoneNumber, idap } = data?.getAttendanceScorecard || {};

  const pastSixMonthsScorecards = attendanceScorecardRecords?.filter(
    (scorecard) => +new Date(scorecard.sessionDate) > +sixMonthsAgo
  );

  const displayedScorecards = allTime
    ? attendanceScorecardRecords
    : pastSixMonthsScorecards;

  const hasScorecards = displayedScorecards && displayedScorecards.length > 0;

  return (
    <Modal
      show={show}
      onClose={closeModal}
      icon={<Modal.Icon icon="source" />}
      title={name ? `${name} Teacher Scorecard` : "Teacher Scorecard"}
      subtitle={
        <div className="flex flex-col">
          {idap && <span>IDAP: {idap}</span>}
          {email && <span>{email}</span>}
          {phoneNumber && <span>{phoneNumber}</span>}
        </div>
      }
      width="xxxlarge"
    >
      <div className="flex flex-col gap-4 my-4">
        <OptionsToggleButton
          options={Object.values(TeacherScorecardPeriod)}
          setActiveTab={setPeriodView}
          activeTab={periodView}
          height="h-[42px]"
          radius={8}
        />
        {loading && <LoadingTableSkeleton rows={4} />}
        {hasScorecards ? (
          <AttendanceScorecardTable attendanceRecords={displayedScorecards} />
        ) : (
          <EmptyStateContainer
            dataName="Attendance Data"
            icon={<Icon icon="attendance" size={10} />}
            subtitle="There is no noteworthy attendance data for the selected date range (i.e.
        no absences and no notes)."
          />
        )}
      </div>
      <Modal.Buttons>
        <Modal.Button type="cancel" onClick={closeModal}>
          Close
        </Modal.Button>
      </Modal.Buttons>
    </Modal>
  );
};
