import clsx from "clsx";
import { useId } from "react";
import { Icon } from "../Icon";
import { NoteStatusIndicator } from "../NoteStatusIndicator";
import { Tooltip } from "../Tooltip";
import { TextArea } from "./TextArea";

type Props = {
  value?: string | null;
  onChange: (note: string | null) => void;
  debouncePending: boolean;
  mutationLoading: boolean;
  className?: string;
  placeholder?: string;
  rows?: number;
  textAreaClassName?: string;
  disabled?: boolean;
};

export const DynamicTextArea = ({
  value,
  onChange,
  debouncePending,
  mutationLoading,
  placeholder,
  className,
  textAreaClassName,
  rows = 1,
  disabled,
}: Props) => {
  const id = useId();

  return (
    <div className={clsx("relative", className)}>
      <label htmlFor={id} className="sr-only">
        Add a note
      </label>
      <TextArea
        id={id}
        name="note"
        rows={rows}
        value={value ?? ""}
        disabled={disabled}
        placeholder={placeholder}
        textAreaClassName={textAreaClassName}
        onChange={(event) => onChange(event.target.value)}
        className={clsx("sm:text-sm z-0")}
      />
      <div className="absolute bottom-[5px] right-[7px] z-[200]">
        {disabled ? (
          <Tooltip content="This note is read-only.">
            <Icon icon="lock" size={4} color="text-slate-700" />
          </Tooltip>
        ) : (
          <NoteStatusIndicator
            debouncePending={debouncePending}
            mutationLoading={mutationLoading}
          />
        )}
      </div>
    </div>
  );
};
