import clsx from "clsx";
import { ReactNode } from "react";

type Props = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  badges?: ReactNode;
  className?: string;
  titleClassName?: string;
};

export const Header = ({
  title,
  subtitle,
  badges,
  className,
  titleClassName,
}: Props) => (
  <div className={clsx("flex flex-col gap-y-[1px]", className)}>
    {title && (
      <div className="flex items-center justify-start gap-x-3 h-fit">
        <h1
          className={clsx(
            "text-2xl sm:text-3xl font-bold leading-tight text-gray-900",
            titleClassName
          )}
        >
          {title}
        </h1>
        <div className="flex items-center gap-x-3">{badges}</div>
      </div>
    )}
    {subtitle && (
      <div
        className={clsx(
          "flex text-xs sm:text-sm font-medium text-gray-500 leading-tight"
        )}
      >
        {subtitle}
      </div>
    )}
  </div>
);
