import { ReactNode } from "react";

export type Step = {
  name: string;
  body: ReactNode;
  status: StepStatus;
};

export enum StepStatus {
  Complete = "complete",
  Current = "current",
  Upcoming = "upcoming",
}
