import { EngagementAssignmentRole } from "@generated/graphql";
import {
  EngagementAssignmentRoleTextMode,
  getEngagementAssignmentRoleText,
} from "@utils/labels";

type Props = {
  engagementAssignmentRole: EngagementAssignmentRole;
  className?: string;
  mode?: EngagementAssignmentRoleTextMode;
};

export const EngagementAssignmentRoleText = ({
  engagementAssignmentRole,
  className = "text-gray-700",
  mode = "long",
}: Props) => (
  <span className={className}>
    {getEngagementAssignmentRoleText(engagementAssignmentRole, mode)}
  </span>
);
