import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { value: boolean };

export const BooleanBadge = ({ value }: Props) => (
  <Badge
    className={clsx(
      "inline-flex rounded-full px-3 py-0.5 text-center text-xs font-medium",
      value ? "text-emerald-900 bg-emerald-200" : "text-rose-900 bg-rose-200"
    )}
  >
    {value ? "True" : "False"}
  </Badge>
);
