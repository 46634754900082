import { getTimeZoneString } from "@utils/geo";
import { CountryCode, getTimezonesForCountry } from "countries-and-timezones";
import { useMemo } from "react";
import { SelectMenu, SelectMenuOption, SelectMenuProps } from "./SelectMenu";

type TimeZoneSelectMenuOption = SelectMenuOption<{
  timeZone: string | null;
}>;

type Props = {
  countryCode: CountryCode | string | null | undefined;
  initialValue: string | null | undefined;
  onSelect: (timeZone: string | null) => void;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
};

export function TimeZoneSelectMenu({
  countryCode,
  initialValue,
  onSelect,
  selectMenuProps,
}: Props) {
  const options = useMemo(() => {
    const options: TimeZoneSelectMenuOption[] = [
      {
        id: "none",
        value: "--",
        selectedLabel: "None Selected",
        timeZone: null,
      },
    ];

    const timeZones = getTimezonesForCountry(countryCode ?? "");

    if (timeZones) {
      options.push(
        ...timeZones.map(({ name }) => ({
          id: name,
          value: name,
          selectedLabel: getTimeZoneString(name),
          timeZone: name,
        }))
      );
    }

    return options;
  }, [countryCode]);

  const initialTimeZoneIndex = useMemo(
    () => options.findIndex(({ timeZone }) => timeZone === initialValue),
    [initialValue, options]
  );

  const handleSelect = ({ timeZone }: TimeZoneSelectMenuOption) => {
    onSelect(timeZone);
  };
  return (
    <SelectMenu
      {...selectMenuProps}
      options={options}
      onSelect={handleSelect}
      initialIndex={initialTimeZoneIndex}
    />
  );
}
