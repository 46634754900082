import { NoteStatusIndicator } from "components/shared/NoteStatusIndicator";
import { useTutorDashboardData } from "contexts/TutorDashboardDataProvider";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";

const CHAR_PX_WIDTH = 7;
const DEFAULT_NOTE_PX_WIDTH = 668;

type Props = {
  loading: boolean;
  showNote: boolean;
  note: string | null;
  attendanceId: string;
  onUpdateNote: (note: string) => void;
};

export const AttendanceNote = ({
  note,
  loading,
  showNote,
  attendanceId,
  onUpdateNote,
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { activeNote, setActiveNote } = useTutorDashboardData();
  const [draftNote, setDraftNote] = useState<string | null>(note);
  const [debouncedDraftNote, { isPending }] = useDebounce(draftNote, 400);

  // Sync internal note updates
  useEffect(() => {
    if (activeNote === attendanceId && debouncedDraftNote !== note)
      onUpdateNote(debouncedDraftNote || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedDraftNote]);

  // Sync external note updates
  useEffect(() => setDraftNote(note), [note]);

  // Auto focus on the text area when the note is active
  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea && activeNote === attendanceId) {
      textArea.focus();
      textArea.selectionStart = textArea.selectionEnd = textArea.value.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return showNote ? (
    <div className="relative w-full mt-[6px]">
      <label htmlFor="note" className="sr-only">
        Add a note
      </label>
      <textarea
        name="note"
        rows={
          draftNote && textAreaRef.current?.offsetWidth
            ? Math.ceil(
                (draftNote.length * CHAR_PX_WIDTH) /
                  (textAreaRef.current?.offsetWidth || DEFAULT_NOTE_PX_WIDTH)
              )
            : 1
        }
        ref={textAreaRef}
        key={`textArea-${attendanceId}`}
        className="flex w-full justify-start border-gray-300 border focus:border-2 items-start focus:border-blue-500 focus:ring-0 sm:text-sm rounded-lg pr-7 min-h-10 h-auto"
        placeholder="Add your note..."
        value={draftNote || ""}
        onChange={(event) => {
          setDraftNote(event.target.value);
          setActiveNote(attendanceId);
        }}
      />
      <div className="absolute right-1 bottom-[7px]">
        <NoteStatusIndicator
          debouncePending={isPending()}
          mutationLoading={loading}
        />
      </div>
    </div>
  ) : null;
};
