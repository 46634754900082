import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { resolveStringyFunction } from "@utils/strings";
import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";

export type DrawerProps = {
  label: ReactNode;
  buttonClassName?: string | ((open: boolean) => string);
  drawerClassName?: string | ((open: boolean) => string);
  defaultOpen?: boolean;
};
export function Drawer({
  label,
  buttonClassName,
  drawerClassName,
  defaultOpen,
  children,
}: PropsWithChildren<DrawerProps>) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={
              buttonClassName
                ? resolveStringyFunction(buttonClassName, open)
                : clsx(
                    "flex w-full justify-between rounded-lg bg-blue-100",
                    "px-4 py-2 text-left text-sm font-medium text-blue-900",
                    "hover:bg-blue-200 focus:outline-none focus-visible:ring",
                    "focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
                  )
            }
          >
            <span>{label}</span>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-blue-500`}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-150 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel
              className={
                drawerClassName
                  ? resolveStringyFunction(drawerClassName, open)
                  : "px-4 pb-4 text-sm text-gray-500 text-left"
              }
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
