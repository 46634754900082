/* eslint-disable react/jsx-key */
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Spinner, TablePagination, Tooltip } from "components/shared";
import { CONTEXT_MENU_ID } from "components/shared/Table/constants";
import { useEffect } from "react";
import {
  Column,
  Row,
  SortingRule,
  TableRowProps,
  UseTableOptions,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useDebounce } from "use-debounce";
import { EmptyTableState } from "./EmptyTableState";

const DEBOUNCE_DELAY = 300;

type OnTableChangeArgs<D> = {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingRule<D>[];
};

export type OnTableChange<D> = (props: OnTableChangeArgs<D>) => void;

export type TableControl<D> = {
  onTableChange: OnTableChange<D>;
  loading: boolean;
  pageCount: number;
};

/**
 * The AdvancedTable requires an id property for whatever data is being passed
 * into the table. Extend this type to ensure that the id property is present.
 */
export type AdvancedTableMinimumFragment = { id: string };

type Props<Fragment extends AdvancedTableMinimumFragment> = {
  hiddenColumns?: string[];
  columns: Column<Fragment>[];
  getRowProps?: (row: Row<Fragment>) => TableRowProps;
  data: Fragment[];
  emptyIcon?: React.ReactNode;
  dataName?: string;
  onRowClick?: (row: Row<Fragment>) => void;
  selectedId?: string | null;
  className?: string;
  initialState?: UseTableOptions<Fragment>["initialState"];
  totalResultCount: number;
  tableControl: TableControl<Fragment>;
};

export function AdvancedTable<Fragment extends AdvancedTableMinimumFragment>({
  columns,
  data,
  getRowProps: getRowProps,
  onRowClick,
  selectedId,
  className,
  initialState,
  totalResultCount,
  hiddenColumns,
  emptyIcon,
  dataName,
  tableControl: { onTableChange, loading, pageCount: controlledPageCount },
}: Props<Fragment>) {
  const {
    state: { pageIndex, pageSize, sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //Pagination
    page,
    pageCount,
    setHiddenColumns,
    canNextPage,
    canPreviousPage,
    gotoPage,
    previousPage,
    nextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        ...initialState,
      },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );
  const hasData = page.length > 0;
  const needsPagination = page.length > 5 || pageCount > 1;

  const [onTableChangeDebounced] = useDebounce(onTableChange, DEBOUNCE_DELAY);

  useEffect(() => {
    setHiddenColumns(hiddenColumns ?? []);
    onTableChangeDebounced({ pageIndex, pageSize, sortBy });
  }, [
    onTableChangeDebounced,
    pageIndex,
    pageSize,
    sortBy,
    hiddenColumns,
    setHiddenColumns,
  ]);

  // Automatically navigate to the last page if a new search causes the results
  // to be less than the current page.
  useEffect(() => {
    if (!loading && totalResultCount < pageIndex * pageSize) {
      gotoPage(Math.floor(totalResultCount / pageSize));
    }
  }, [loading, totalResultCount, pageIndex, pageSize, gotoPage]);

  return (
    <div className={clsx("w-full rounded-lg shadow-sm")}>
      <div
        className={clsx(
          "bg-white border-gray-200 overflow-x-auto border rounded-lg",
          hasData && needsPagination && "border-b-0 rounded-b-none",
          getScrollbarStyle("gray"),
          className
        )}
      >
        <table
          className="min-w-full divide-gray-200 divide-y"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    key={i}
                    scope="col"
                    className={clsx(
                      column.id === CONTEXT_MENU_ID ? "w-16" : "p-3",
                      "text-left text-gray-500 text-xs font-medium tracking-wider uppercase bg-gray-50",
                      i === 0 && "rounded-tl-md",
                      i === headerGroup.headers.length - 1 && "rounded-tr-md",
                      column.width
                    )}
                  >
                    <div
                      className="flex"
                      {...(column.id === CONTEXT_MENU_ID
                        ? {}
                        : column.getSortByToggleProps())}
                    >
                      {column.render("Header")}

                      {column.canSort &&
                        column.isSorted &&
                        (column.isSortedDesc ? (
                          <ArrowDownIcon className="ml-2 h-4 w-4" />
                        ) : (
                          <ArrowUpIcon className="ml-2 h-4 w-4" />
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {hasData && (
            <tbody
              className="bg-white divide-gray-200 divide-y"
              {...getTableBodyProps()}
              data-test="table"
            >
              {loading ? (
                <tr key={0}>
                  <td
                    key={0}
                    className="px-6 py-4 text-gray-500 text-sm"
                    colSpan={columns.length}
                  >
                    <Spinner size={10} color="text-blue-600" />
                  </td>
                </tr>
              ) : (
                page.map((row, i) => {
                  prepareRow(row);
                  const isRowSelected = row.original.id === selectedId;
                  return (
                    <tr
                      key={i}
                      className={clsx([
                        isRowSelected ? "bg-blue-100" : "hover:bg-gray-50",
                        onRowClick ? "cursor-pointer" : "cursor-default",
                      ])}
                    >
                      {row.cells.map((cell, i) => {
                        const onClick =
                          onRowClick && cell.column.id !== CONTEXT_MENU_ID
                            ? { onClick: () => onRowClick(row) }
                            : {};
                        const cellToRender =
                          cell.column.id === CONTEXT_MENU_ID ? (
                            <div className="flex justify-center">
                              {cell.render("Cell")}
                            </div>
                          ) : (
                            cell.render("Cell")
                          );

                        return (
                          <td
                            key={i}
                            {...onClick}
                            className={clsx(
                              cell.column.id === CONTEXT_MENU_ID
                                ? "px-1 py-0"
                                : "p-3",
                              "text-gray-500 text-sm",
                              cell.column.width
                            )}
                          >
                            {cellToRender}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </table>

        {!hasData && (
          <EmptyTableState
            dataName={dataName}
            emptyIcon={emptyIcon}
            loading={loading}
          />
        )}
      </div>

      {hasData && needsPagination && (
        <TablePagination
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          totalResultCount={totalResultCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      )}
    </div>
  );
}

type AdvancedSearchTooltipProps = { children: React.ReactNode };

export const AdvancedSearchTooltip = ({
  children,
}: AdvancedSearchTooltipProps) => (
  <Tooltip
    className="cursor-pointer ml-1"
    content={<div className="block w-48 text-sm text-center">{children}</div>}
    tooltipProps={{ place: "bottom" }}
  >
    <Icon icon="info" size={4} color="text-blue-600" />
  </Tooltip>
);
