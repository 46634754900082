import { SlotApplicationStatus } from "@generated/graphql";
import { HOUR_MS, MINUTE_MS, Millisecond, SECOND_MS } from "@utils/dateTime";
import { getSlotApplicationStatusText } from "@utils/labels";
import clsx from "clsx";
import { Badge, Tooltip } from "components/shared";
import { useEffect, useMemo, useState } from "react";
import { SlotAppStatusColorMap } from "sections/Jobs/constants";

const UNDER_HOUR_OFFER_TICK_RATE: Millisecond = SECOND_MS;
const OFFER_TICK_RATE: Millisecond = MINUTE_MS;

type Props = {
  slotStatus: SlotApplicationStatus | null | undefined;
  offerExpiry?: Date;
  className?: string;
};

export const SlotApplicationStatusBadge = ({
  slotStatus,
  offerExpiry,
  className,
}: Props) => {
  const [updateTime, setUpdateTime] = useState<boolean>(true);
  const isOfferStatus = slotStatus === SlotApplicationStatus.Offered;

  const { remainingTime, isUnderHour, isExpired } = useMemo(() => {
    if (!offerExpiry) return defaultTimeUntilOfferExpiry;

    const offerExpiryTime = new Date(offerExpiry).getTime();
    const timeDifference = offerExpiryTime - Date.now();
    const hoursDifference = Math.floor(timeDifference / HOUR_MS);
    const minutesDifference = Math.floor(timeDifference / MINUTE_MS) % 60;
    const secondsDifference = Math.floor(timeDifference / SECOND_MS) % 60;
    const isExpired = Boolean(timeDifference < 0);

    setUpdateTime(false);

    return {
      remainingTime: isExpired
        ? "Expired"
        : hoursDifference >= 1
        ? `${hoursDifference}hrs ${minutesDifference}min`
        : minutesDifference >= 1
        ? `${minutesDifference}min ${secondsDifference}sec`
        : `${secondsDifference} seconds`,
      isUnderHour: hoursDifference < 1,
      isExpired,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerExpiry, updateTime]);

  const showOfferExpiry = isOfferStatus && offerExpiry;

  const renderOfferExpiry = showOfferExpiry ? (
    <span
      className={clsx(
        "flex justify-center items-center leading-none whitespace-nowrap",
        isExpired ? "w-fit" : "w-[68px]"
      )}
    >
      {remainingTime}
    </span>
  ) : null;

  useEffect(() => {
    const showSeconds = showOfferExpiry && isUnderHour;
    const interval = setInterval(
      () => showOfferExpiry && setUpdateTime(true),
      showSeconds ? UNDER_HOUR_OFFER_TICK_RATE : OFFER_TICK_RATE
    );
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return slotStatus ? (
    <Tooltip
      disabled={!showOfferExpiry}
      className={clsx(showOfferExpiry && "cursor-pointer")}
      content={
        <div className="flex justify-center items-center max-w-[200px] text-center leading-tight">
          {isExpired
            ? 'This offer has expired and should be automatically set to "Expired" within the next 5 minutes.'
            : `This offer automatically expires in ${remainingTime}`}
        </div>
      }
    >
      <Badge
        className={clsx(
          SlotAppStatusColorMap[slotStatus ?? "NOT_APPLIED"],
          "flex justify-center items-center text-center",
          "w-auto gap-x-[6px] px-2 py-0.5 rounded-full",
          className
        )}
      >
        {getSlotApplicationStatusText(
          slotStatus,
          isExpired && showOfferExpiry ? "noun" : "verb-past"
        )}
        {renderOfferExpiry}
      </Badge>
    </Tooltip>
  ) : null;
};

const defaultTimeUntilOfferExpiry = {
  remainingTime: "",
  isExpired: false,
  isUnderHour: false,
};
