import clsx from "clsx";
import { useEffect, useState } from "react";

type MultiSelectOption = { id: string; value: string };

const stringToMultiSelectOptions = (
  options: MultiSelectOption[],
  value: string
) => {
  const values = value.split(",").map((val) => val.trim());
  return options.filter((option) => values.some((val) => val === option.value));
};

const multiSelectOptionsToString = (options: MultiSelectOption[]) =>
  options
    .reverse()
    .map((opt) => opt.value)
    .join(", ");

interface Props {
  onChange: (selected: string) => void;
  title: string | React.ReactNode;
  options: MultiSelectOption[];
  disabled?: boolean;
  value: string;
}

export const MultiSelectOptions = ({
  disabled = false,
  onChange,
  options,
  value,
  title,
}: Props) => {
  const [selected, setSelected] = useState<MultiSelectOption[]>(
    stringToMultiSelectOptions(options, value)
  );
  const isSelected = (optionId: string) =>
    selected.find((option) => option.id === optionId);

  useEffect(() => {
    onChange(multiSelectOptionsToString(selected));
  }, [onChange, selected]);

  const onButtonToggle = (option: MultiSelectOption) => {
    setSelected(
      selected.some((opt) => opt.id === option.id)
        ? selected.filter((opt) => opt.id !== option.id)
        : [...selected, option]
    );
  };

  return (
    <span className="flex items-center w-full gap-1">
      <label className="block text-sm font-medium text-gray-700 pr-2">
        {title}
      </label>
      <div className="flex gap-1">
        {options.map((option) => (
          <button
            disabled={disabled}
            key={option.id}
            className={clsx(
              isSelected(option.id) &&
                "hover:bg-blue-600 bg-blue-500 text-white",
              "group border active:bg-blue-700 active:text-white relative px-2 py-1 mt-1 rounded-md shadow-sm items-center text-sm"
            )}
            onClick={() => onButtonToggle(option)}
          >
            {option.value}
          </button>
        ))}
      </div>
    </span>
  );
};
