import { useAuth } from "contexts/AuthProvider";
import {
  useGlobalFilterInputs,
  useGroupFilterInputs,
} from "contexts/FilterInputsProvider";
import { Filters } from "../Filters";
import { FilterType } from "../types";

type Props = { tableName: string; filterType: FilterType };

export const ActiveTestTableFilters = ({ tableName, filterType }: Props) => {
  const { isManagingMode } = useAuth();
  const { getFilterBoolean, setFilterBoolean } =
    useGroupFilterInputs(tableName);
  const {
    getFilterBoolean: getGlobalFilterBoolean,
    setFilterBoolean: setGlobalFilterBoolean,
  } = useGlobalFilterInputs();

  return (
    <Filters
      filters={getFilters(
        getGlobalFilterBoolean,
        getFilterBoolean,
        setGlobalFilterBoolean,
        setFilterBoolean,
        isManagingMode,
        filterType
      )}
    />
  );
};

const getFilters = (
  getGlobalFilterBoolean: (key: string) => boolean | null,
  getFilterBoolean: (key: string) => boolean | null,
  setGlobalFilterBoolean: (key: string, value: boolean) => void,
  setFilterBoolean: (key: string, value: boolean) => void,
  isManagingMode: boolean,
  filterType: FilterType
) => [
  {
    name: "All",
    selected:
      !getGlobalFilterBoolean("testEntitiesOnly") &&
      !getFilterBoolean("activeCohortsOnly"),
    tooltip: (
      <div className="flex flex-col items-center">
        <p>{`Show All ${filterType}`}</p>
        {`(except those in testing)`}
      </div>
    ),
    onClick: () => {
      setFilterBoolean("activeCohortsOnly", false);
      setGlobalFilterBoolean("testEntitiesOnly", false);
    },
  },
  {
    name: "Active",
    selected: getFilterBoolean("activeCohortsOnly") ?? false,
    show: isManagingMode,
    tooltip: getActiveCohortTooltip(filterType),
    onClick: (newState: boolean) => {
      setGlobalFilterBoolean("testEntitiesOnly", false);
      setFilterBoolean("activeCohortsOnly", newState);
    },
  },
  {
    name: "Test",
    selected: getGlobalFilterBoolean("testEntitiesOnly") ?? false,
    tooltip: `Show only ${filterType} that are in testing.`,
    onClick: (newState: boolean) => {
      setFilterBoolean("activeCohortsOnly", false);
      setGlobalFilterBoolean("testEntitiesOnly", newState);
    },
  },
];

const getActiveCohortTooltip = (filterType: FilterType) => {
  switch (filterType) {
    case FilterType.Organizations:
      return "Show only Organizations with cohorts that have recently been active.";
    case FilterType.Engagements:
      return "Show only the Engagements with Cohorts that have recently been active.";
    case FilterType.Cohorts:
      return "Show only the Cohorts that have recently been active.";
    default:
      return "Show only the Cohorts that have recently been active.";
  }
};
