import { normalizeToUtcISODate } from "@utils/dateTime";
import { BuildHolidayMapHoliday } from "./types";

export const buildDayHolidaysMap = <H extends BuildHolidayMapHoliday>(
  holidays: H[]
): Map<string, H[]> => {
  const dayHolidaysMap: Map<string, H[]> = new Map();
  for (const holiday of holidays) {
    const startISODate = normalizeToUtcISODate(new Date(holiday.startDate));
    const endISODate = normalizeToUtcISODate(new Date(holiday.endDate));

    for (
      let curDate = new Date(startISODate);
      curDate <= new Date(endISODate);
      curDate.setDate(curDate.getDate() + 1)
    ) {
      const curISODate = normalizeToUtcISODate(curDate);
      dayHolidaysMap.has(curISODate)
        ? dayHolidaysMap.set(
            curISODate,
            dayHolidaysMap.get(curISODate)!.concat(holiday)
          )
        : dayHolidaysMap.set(curISODate, [holiday]);
    }
  }

  return dayHolidaysMap;
};

export const buildDayEventsCountMap = (
  eventsInstancesStartTimes: Date[]
): Map<string, number> => {
  const dayEventsCountMap: Map<string, number> = new Map();

  for (const eventInstanceStartTime of eventsInstancesStartTimes) {
    const isoDate = normalizeToUtcISODate(new Date(eventInstanceStartTime));

    dayEventsCountMap.has(isoDate)
      ? dayEventsCountMap.set(
          isoDate,
          (dayEventsCountMap.get(isoDate) ?? 1) + 1
        )
      : dayEventsCountMap.set(isoDate, 1);
  }

  return dayEventsCountMap;
};
