import {
  StudentDisplayNames,
  StudentLiveParticipantMatches,
} from "components/shared/AttendanceGrades/types";
import noop from "lodash/noop";
import { createContext, useContext, useMemo, useState } from "react";

type TutorDashboardDateContextType = {
  backupStudentNames: StudentDisplayNames;
  setBackupStudentNames: (noMatches: StudentDisplayNames) => void;

  studentMatchData: StudentLiveParticipantMatches;
  setStudentMatchData: (
    studentMatchData: StudentLiveParticipantMatches
  ) => void;

  activeNote: string;
  setActiveNote: (attendanceId: string) => void;
};

const initialState: TutorDashboardDateContextType = {
  backupStudentNames: {},
  setBackupStudentNames: noop,

  studentMatchData: {},
  setStudentMatchData: noop,

  activeNote: "",
  setActiveNote: noop,
};

export const TutorDashboardDataContext =
  createContext<TutorDashboardDateContextType>(initialState);

type Props = { children: React.ReactNode };

export function TutorDashboardDataProvider({ children }: Props) {
  const [activeNote, setActiveNote] = useState(initialState.activeNote);

  const [backupStudentNames, setBackupStudentNames] = useState(
    initialState.backupStudentNames
  );

  const [studentMatchData, setStudentMatchData] = useState(
    initialState.studentMatchData
  );

  const context = useMemo(
    () => ({
      activeNote,
      setActiveNote,
      studentMatchData,
      setStudentMatchData,
      backupStudentNames,
      setBackupStudentNames,
    }),
    [activeNote, backupStudentNames, studentMatchData]
  );

  return (
    <TutorDashboardDataContext.Provider value={context}>
      {children}
    </TutorDashboardDataContext.Provider>
  );
}

export const useTutorDashboardData = () =>
  useContext(TutorDashboardDataContext);
