import { gql } from "@apollo/client";
import { Holiday, HolidayType } from "@generated/graphql";
import { normalizeToUtcISODate } from "@utils/dateTime";
import clsx from "clsx";
import { Tooltip, disabledDay } from "components/shared";
import { isSameDay, isSameMonth, isSameWeek } from "date-fns";
import dayjs from "dayjs";
import pluralize from "pluralize";

eventsAndHolidaysCellRenderer.fragments = {
  holiday: gql`
    fragment EventsAndHolidaysCellRenderer_Holiday on Holiday {
      id
      name
    }
  `,
  limitedAccessHoliday: gql`
    fragment EventsAndHolidaysCellRenderer_LimitedAccessHoliday on LimitedAccessHoliday {
      id
      name
    }
  `,
};

export type CellRendererHoliday = Pick<Holiday, "id" | "name" | "type">;

export function eventsAndHolidaysCellRenderer(
  current: dayjs.Dayjs,
  month: dayjs.Dayjs,
  currentWeekShownDate: Date,
  dayEventsCountMap: Map<string, number> | undefined,
  dayHolidaysMap: Map<string, CellRendererHoliday[]> | undefined,
  showEvents: boolean,
  startBound?: Date,
  endBound?: Date
) {
  const isToday = isSameDay(new Date(), current.toDate());
  const isCurrentWeek = isSameWeek(current.toDate(), currentWeekShownDate);
  const isOnSameMonth = isSameMonth(current.toDate(), month.toDate());
  const dayEventsCount =
    dayEventsCountMap?.get(normalizeToUtcISODate(current.toDate())) ?? 0;
  const dayHolidays =
    dayHolidaysMap?.get(normalizeToUtcISODate(current.toDate())) ?? [];
  const dayHolidaysCount = dayHolidays.length;
  const isOutOfBounds =
    startBound && endBound && disabledDay(current, startBound, endBound);

  const bgColor =
    isToday && isOnSameMonth
      ? "bg-red-500 text-white"
      : isCurrentWeek && isOnSameMonth
      ? "bg-blue-500 text-white"
      : isOutOfBounds
      ? "bg-gray-100"
      : dayEventsCount > 0 && isOnSameMonth
      ? "bg-indigo-100"
      : dayEventsCount > 0
      ? "bg-indigo-50"
      : "";

  const dayContent = (
    <div
      className={clsx("flex relative justify-center h-full w-full", bgColor)}
    >
      {dayHolidaysCount > 0 && (
        <div className="absolute top-0.5 right-0.5 w-1 h-1 rounded-full bg-green-600 outline outline-1 outline-white" />
      )}
      <p className="z-10 hover:text-inherit">{current.date()}</p>
    </div>
  );

  return showEvents || dayHolidaysCount > 0 ? (
    <Tooltip
      content={
        <div className="flex flex-col gap-y-1.5 text-xs font-medium text-white text-center max-w-[20ch] p-1">
          {showEvents && (
            <p>
              {dayEventsCount === 0
                ? "No events"
                : pluralize("Event", dayEventsCount, true)}
            </p>
          )}
          {dayHolidaysCount > 0 && (
            <div className="flex flex-col">
              <p>
                {pluralize(
                  dayHolidays.every(({ type }) => type === HolidayType.Usa)
                    ? "Holiday"
                    : "No Tutor Day",
                  dayHolidaysCount,
                  true
                )}
                :
              </p>
              <div className="flex flex-col items-center">
                {dayHolidays.map(({ id, name }) => (
                  <p key={id}>{name}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      }
    >
      {dayContent}
    </Tooltip>
  ) : (
    dayContent
  );
}

export const defaultCellRenderer = (
  current: dayjs.Dayjs,
  month: dayjs.Dayjs,
  currentWeekShownDate: Date,
  startBound?: Date,
  endBound?: Date
) => {
  const isToday = isSameDay(new Date(), current.toDate());
  const isCurrentWeek = isSameWeek(current.toDate(), currentWeekShownDate);
  const isOnSameMonth = isSameMonth(current.toDate(), month.toDate());
  const isOutOfBounds =
    startBound && endBound && disabledDay(current, startBound, endBound);

  const getBGColor =
    isToday && isOnSameMonth
      ? "bg-red-500 text-white"
      : isCurrentWeek && isOnSameMonth
      ? "bg-blue-500 text-white"
      : isOutOfBounds
      ? "bg-gray-100"
      : "";

  return (
    <div className={`flex relative justify-center h-full w-full ${getBGColor}`}>
      <p className="z-10 hover:text-inherit">{current.date()}</p>
    </div>
  );
};
