import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  EngagementStudentGradesPage_Base_LimitedAccessEngagementFragment,
  EngagementStudentGradesPage_SubjectSelected_LimitedAccessEngagementFragment,
  LimitedAccessCohort,
  LimitedAccessCohortStudent,
  LimitedAccessStudent,
  Student,
  StudentAttendanceGradingItem_CohortSessionStudentGradingFragment,
  StudentAttendanceGradingItem_StudentGradingRecordFragment,
  WeekGridData_LimitedAccessCohortSessionFragment,
  WeekGridData_LimitedAccessCohortSessionStudentAttendanceFragment,
  WeekGridData_LimitedAccessCohortSessionStudentGradingFragment,
} from "@generated/graphql";
import { LocalizedWeekday } from "@utils/dateTime";
import { AttendanceIconType } from "components/shared/AttendanceGrades/AttendanceIcon";
import { SelectMenuOption } from "../Inputs/SelectMenu";

export type StudentGrades =
  | StudentAttendanceGradingItem_StudentGradingRecordFragment
  | StudentAttendanceGradingItem_CohortSessionStudentGradingFragment;

export type DraftNotes = { id: string; notes: string };

export type StudentLiveParticipantMatchDistance = {
  participant: string;
  distance: number;
};

export type StudentLiveParticipantMatch = {
  studentId: Student["id"];
  studentFullName: Student["fullName"];
  match: string;
  distances: StudentLiveParticipantMatchDistance[];
};

export type StudentLiveParticipantMatches = {
  [attendanceKey: string]: StudentLiveParticipantMatch;
};

export type StudentDisplayNames = {
  [cohortSessionStudentId: string]: string | undefined;
};

export type StudentAttendanceStyleData = {
  peepColor: string;
  iconType: AttendanceIconType;
  pillColor: string;
};

export type SubjectOption = SelectMenuOption<{
  subject: CohortAssignmentSubject;
}>;

export type SubSubjectOption = SelectMenuOption<{
  subSubject: CohortAssignmentSubSubject;
}>;

export type SubSubjectAllOption = SelectMenuOption<{
  subSubject: SubSubjectOrAll;
}>;

export type WeekGridData = {
  localizedWeekday: LocalizedWeekday;
  notInEngagement: boolean;
  notInGradingPeriod: boolean;
  isEngagementNoShowDay: boolean;
  isCohortNoShowDay: boolean;
  isEngagementAssessmentDay: boolean;
  isCohortAssessmentDay: boolean;
  isUpcomingDate: boolean;
  isUsHoliday: boolean;
  isNoTutoringDay: boolean;
  studentFullName?: string;
  inStudentRange: boolean;
  entries: {
    cohortSession: WeekGridData_LimitedAccessCohortSessionFragment;
    grading: WeekGridData_LimitedAccessCohortSessionStudentGradingFragment;
    attendance: WeekGridData_LimitedAccessCohortSessionStudentAttendanceFragment;
  }[];
};

export const ANY_SUB_SUBJECT = "All";

export type SubSubjectOrAll =
  | CohortAssignmentSubSubject
  | typeof ANY_SUB_SUBJECT;

export type SubjectComboKey = `${CohortAssignmentSubject}-${SubSubjectOrAll}`;

export type SubjectComboWithAll = {
  subject: CohortAssignmentSubject;
  subSubject: SubSubjectOrAll;
};

export enum StudentGradesColumnKey {
  Student = "Student",
  Grade = "Grade",
  Cohort = "Cohort",
  SubjectAttendanceAverage = "Attend. Avg.",
  ClassworkGradeAverage = "Classwork Avg.",
  ParticipationGradeAverage = "Partici. Avg.",
  ReadingGradeAverage = "Reading Avg.",
  LanguageArtsGradeAverage = "Lang. Arts. Avg.",
}

export type StudentStatColumn =
  | StudentGradesColumnKey.SubjectAttendanceAverage
  | StudentGradesColumnKey.ClassworkGradeAverage
  | StudentGradesColumnKey.ParticipationGradeAverage
  | StudentGradesColumnKey.ReadingGradeAverage
  | StudentGradesColumnKey.LanguageArtsGradeAverage;

export type StudentGradesSort = {
  columnKey: StudentGradesColumnKey;
  isSortedDesc: boolean;
};

export type StudentGridData = {
  [StudentGradesColumnKey.Student]: Pick<
    LimitedAccessStudent,
    "id" | "fullName" | "externalId"
  > &
    Pick<LimitedAccessCohortStudent, "createdAt">;
  [StudentGradesColumnKey.Grade]: LimitedAccessCohort["grade"];
  [StudentGradesColumnKey.Cohort]: Pick<LimitedAccessCohort, "id" | "name">;
  averages: {
    [key in SubjectComboKey]?: {
      [StudentGradesColumnKey.SubjectAttendanceAverage]: number | null;
      [StudentGradesColumnKey.ClassworkGradeAverage]: number | null;
      [StudentGradesColumnKey.ParticipationGradeAverage]: number | null;
      [StudentGradesColumnKey.ReadingGradeAverage]: number | null;
      [StudentGradesColumnKey.LanguageArtsGradeAverage]: number | null;
    };
  };
};

export enum EngagementStudentGradesPageMode {
  Base = "base",
  SubjectSelected = "subjectSelected",
}

export type GradebookPageData =
  | {
      mode: EngagementStudentGradesPageMode.Base;
      limitedAccessEngagement: EngagementStudentGradesPage_Base_LimitedAccessEngagementFragment;
    }
  | {
      mode: EngagementStudentGradesPageMode.SubjectSelected;
      cohortSubject: CohortAssignmentSubject;
      cohortSubSubject: SubSubjectOrAll;
      limitedAccessEngagement: EngagementStudentGradesPage_SubjectSelected_LimitedAccessEngagementFragment;
    };
