import { RosterRecordStatus } from "@generated/graphql";
import { assertUnreachable } from "@utils/types";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = { rosterRecordStatus: RosterRecordStatus; className?: string };

export function RosterRecordStatusBadge({
  rosterRecordStatus,
  className,
}: Props) {
  switch (rosterRecordStatus) {
    case RosterRecordStatus.Confirmed: {
      return (
        <Badge className={clsx("text-emerald-900 bg-emerald-200", className)}>
          Confirmed
        </Badge>
      );
    }
    case RosterRecordStatus.Pending: {
      return (
        <Badge className={clsx("text-yellow-900 bg-yellow-200", className)}>
          Pending
        </Badge>
      );
    }
    case RosterRecordStatus.Destaffed: {
      return (
        <Badge className={clsx("text-rose-900 bg-rose-200", className)}>
          Destaffed
        </Badge>
      );
    }
    default:
      return assertUnreachable(rosterRecordStatus, "RosterRecordStatusBadge");
  }
}
