import { normalizeDateFromUTCDateTime } from "@utils/dateTime";
import { endOfDay, startOfDay } from "date-fns";
import dayjs from "dayjs";
import { EventFilterOptions, UserEventFilter } from "./types";

export const getOrCreateDate = (date: Date | null | undefined) =>
  date ? normalizeDateFromUTCDateTime(new Date(date)) : new Date();

/**
 * Disabled days outside of the bounded start and end dates as well as
 * the start date being after the end date and vice-versa.
 * @param day The current date to check, provided by <DatePicker />.
 * @param boundedStart The overall start date for the date range.
 * @param boundedEnd The overall end date for the date range.
 * @param pairedStart Optional, the start date if used in a start/end pair.
 * @param pairedEnd Optional, the end date if used in a start/end pair.
 * @param isStartPicker Optional, but required for start/end pairs to work correctly.
 * Set true if this is the start picker. False if end picker (don't leave undefined!)
 * @param needsToEndAfterToday
 * @returns
 */
export const disabledDay = (
  day: dayjs.Dayjs,
  boundedStart: Date,
  boundedEnd: Date,
  pairedStart?: Date,
  pairedEnd?: Date,
  isStartPicker?: boolean,
  needsToEndAfterToday?: boolean
) => {
  // Make sure the incoming parameters are Date objects (TypeScript sleeps on this).
  const boundedStartDate = new Date(boundedStart);
  const boundedEndDate = new Date(boundedEnd);
  const pairedStartDate = pairedStart && new Date(pairedStart);
  const pairedEndDate = pairedEnd && new Date(pairedEnd);

  let isDisabled = false;
  if (isStartPicker !== undefined && pairedStartDate && pairedEndDate) {
    isDisabled =
      (isStartPicker &&
        startOfDay(day.toDate()).getTime() >
          endOfDay(pairedEndDate).getTime()) ||
      (!isStartPicker &&
        endOfDay(day.toDate()).getTime() <
          startOfDay(pairedStartDate).getTime());
    if (needsToEndAfterToday && !isStartPicker) {
      isDisabled =
        isDisabled ||
        endOfDay(day.toDate()).getTime() < endOfDay(new Date()).getTime();
    }
  }

  return (
    isDisabled ||
    startOfDay(day.toDate()).getTime() <
      normalizeDateFromUTCDateTime(boundedStartDate).getTime() ||
    startOfDay(day.toDate()).getTime() >
      normalizeDateFromUTCDateTime(boundedEndDate).getTime()
  );
};

// Only disables day if the startDate is after the endDate and vice-versa
export const disabledUnboundedDay = (
  day: dayjs.Dayjs,
  start: Date,
  end: Date,
  isStartDate: boolean
) =>
  (isStartDate &&
    startOfDay(day.toDate()).getTime() > new Date(end).getTime()) ||
  (!isStartDate &&
    startOfDay(day.toDate()).getTime() < new Date(start).getTime());

export const getUserEventFilters = (isMentor: boolean): EventFilterOptions => {
  return {
    [UserEventFilter.Teaching]: true,
    [UserEventFilter.InstructionalSupport]: false,
    [UserEventFilter.Mentoring]: isMentor ? false : undefined,
  };
};
