import { ApolloQueryResult, gql } from "@apollo/client";
import { AccountStatus, CurrentUserQuery } from "@generated/graphql";
import { getSession } from "@lib/apollo-client";

import type { GetServerSidePropsContext } from "next";

export const GET_CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      email
      accountStatus
      accountRole
      fullName
      createdAt
      hasPendingAgreements
    }
  }
`;

export async function fetchUser(
  accessToken: string,
  context: GetServerSidePropsContext | null
): Promise<CurrentUserQuery["currentUser"]> {
  const { client } = getSession(accessToken, context);

  const result: ApolloQueryResult<CurrentUserQuery> = await client.query({
    query: GET_CURRENT_USER,
  });

  return result.data.currentUser ?? null;
}

export async function getRefreshTokenCallback(
  onSuccess: (user: CurrentUserQuery["currentUser"], token: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error?: any) => void
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (err: unknown, session: any) => {
    if (err) {
      console.error("[🔑 AuthProvider] - Error Refreshing session.", err);
      if (onError) onError(err);
    } else {
      const refreshedAccessToken = session.getAccessToken().getJwtToken();
      const user = await fetchUser(refreshedAccessToken, null);
      const isUserActive = user?.accountStatus === AccountStatus.Active;

      if (!user || !isUserActive)
        throw new Error("User not found in refresh operation.");

      console.log("[🔑 AuthProvider] - Token refresh success:", {
        email: user.email,
        role: user.accountRole,
        hasToken: Boolean(refreshedAccessToken),
      });

      onSuccess(user, refreshedAccessToken);
    }
  };
}
