import clsx from "clsx";
import { EventScheduleStatus, Tense } from "types/events";

interface Props {
  tense: Tense;
  status: EventScheduleStatus;
  startDateTime: Date;
  endDateTime: Date;
  currDateTime?: Date;
}
export const EventProgressBar = ({
  tense,
  status,
  currDateTime,
  startDateTime,
  endDateTime,
}: Props) => {
  const tenseBarColor =
    tense === "present"
      ? "bg-blue-300"
      : tense === "future"
      ? "bg-gray-300"
      : "bg-purple-600";

  const calculateWidthPercentage = (
    currentDateTime: Date,
    startDateTime: Date,
    endDateTime: Date
  ): number => {
    const duration = +endDateTime - +startDateTime;
    const timeSinceStart = +currentDateTime - +startDateTime;
    return (timeSinceStart / duration) * 100;
  };

  return (
    <div className="bg-gray-200 rounded-full overflow-hidden mb-1">
      <div
        className={clsx(
          "h-1.5 rounded-full",
          status === EventScheduleStatus.ONGOING
            ? "bg-blue-600 animate-pulse"
            : tenseBarColor
        )}
        style={{
          width: `${
            status === EventScheduleStatus.ONGOING
              ? calculateWidthPercentage(
                  currDateTime ?? new Date(Date.now()),
                  startDateTime,
                  endDateTime
                )
              : status === EventScheduleStatus.UPCOMING
              ? 0
              : 100
          }%`,
        }}
      />
    </div>
  );
};
