import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";
import clsx from "clsx";
import {
  EvaluationTableDetails,
  StudentEvaluationRow,
  StudentGradingSection,
} from "../..";
import { StudentAttendanceSection } from "./components/StudentAttendanceSection/StudentAttendanceSection";
import { StudentNameSection } from "./components/StudentNameSection";
import {
  ReadOnlyAttendanceStatus,
  ReadOnlyDisplayName,
  ReadOnlyEvaluationNotes,
  ReadOnlyGradingPanel,
} from "./helpers";

type Props = {
  zIndex: number;
  readOnly: boolean;
  className?: string;
  highlightIncomplete?: boolean;
  evaluationDetails: EvaluationTableDetails;
  studentEvaluationRow: StudentEvaluationRow;
};

export const SessionStudentEvaluationTableRow = ({
  zIndex,
  readOnly,
  className,
  highlightIncomplete,
  studentEvaluationRow: {
    grading,
    fullName,
    studentId,
    startDate,
    attendance,
    studentStatus,
    isEvaluationComplete,
  },
  evaluationDetails: {
    id,
    subject,
    startDateTime,
    isGradingEnabled,
    isCohortNoShowDay,
    isEngagementNoShowDay,
  },
}: Props) => {
  const { Absent, Unknown } = CohortSessionStudentAttendanceStatus;
  const attendanceStatus = attendance?.status ?? Unknown;

  return (
    <div
      style={{ zIndex }}
      className={clsx(
        "flex items-center w-full",
        highlightIncomplete && !isEvaluationComplete && "bg-rose-50",
        className
      )}
    >
      <StudentNameSection
        startDate={startDate}
        studentName={fullName || ""}
        studentStatus={studentStatus}
      />

      {readOnly ? (
        <>
          <ReadOnlyDisplayName displayName={attendance?.displayName} />
          <ReadOnlyAttendanceStatus status={attendanceStatus} />
          <ReadOnlyGradingPanel
            grading={grading}
            attendanceStatus={attendanceStatus}
            subject={subject}
          />
          <ReadOnlyEvaluationNotes notes={attendance?.notes} />
        </>
      ) : (
        <>
          <StudentAttendanceSection
            cohortSessionId={id}
            studentId={studentId}
            startDateTime={startDateTime}
            studentAttendanceEntry={attendance ?? null}
            isCohortNoShowDay={isCohortNoShowDay ?? false}
            isEngagementNoShowDay={isEngagementNoShowDay ?? false}
          />
          <StudentGradingSection
            subject={subject}
            cohortSessionId={id}
            studentId={studentId}
            startDateTime={startDateTime}
            studentGradingEntry={grading ?? null}
            hasStudentAttendanceEntry={!!attendance}
            studentGradingIsEnabled={isGradingEnabled}
            isCohortNoShowDay={isCohortNoShowDay ?? false}
            studentDidAttend={attendance?.status !== Absent}
            isEngagementNoShowDay={isEngagementNoShowDay ?? false}
          />
        </>
      )}
    </div>
  );
};
