import { ParsedContactInfoData } from "@utils/csv-students-data/parseCsvStudentData";
import { Tooltip } from "components/shared";
import { MdNotificationsActive } from "react-icons/md";

type Props = {
  contactInfo: ParsedContactInfoData;
};

export function ParsedContactInfoTooltip({ contactInfo }: Props) {
  return (
    <Tooltip
      className="cursor-help"
      content={
        <ul className="flex flex-col items-start text-sm text-white">
          <li>Full Name: {contactInfo.fullName || "--"}</li>
          <li className="flex flex-row flex-nowrap gap-x-2">
            <span>Email: {contactInfo?.email || "--"}</span>
            {contactInfo?.notifEmail && (
              <MdNotificationsActive className="w-4 h-4" />
            )}
          </li>
          <li className="flex flex-row flex-nowrap gap-x-2">
            <span>Phone: {contactInfo?.phone || "--"}</span>
            {contactInfo?.notifSms && (
              <MdNotificationsActive className="w-4 h-4" />
            )}
          </li>
          <li>Street Address 1: {contactInfo?.streetAddress1 || "--"}</li>
          <li>Street Address 2: {contactInfo?.streetAddress2 || "--"}</li>
          <li>City: {contactInfo?.city || "--"}</li>
          <li>State: {contactInfo?.state || "--"}</li>
        </ul>
      }
    />
  );
}
