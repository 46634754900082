import Image from "next/legacy/image";

export enum TbTLogoVariant { // 730x243
  BLACK_BLACK = "/logos/tbt-logo-blackText-blackCheck.svg",
  BLACK_BLUE = "/logos/tbt-logo-blackText-blueCheck.svg",
  BLACK_NONE = "/logos/tbt-logo-blackText-noCheck.svg",
  WHITE_WHITE = "/logos/tbt-logo-whiteText-whiteCheck.svg",
  WHITE_BLACK = "/logos/tbt-logo-whiteText-blackCheck.svg",
  WHITE_BLUE = "/logos/tbt-logo-whiteText-blueCheck.svg",
  WHITE_NONE = "/logos/tbt-logo-whiteText-noCheck.svg",
  BLUE_BLUE = "/logos/tbt-logo-blueText-blueCheck.svg",
}

type Props = {
  width?: number;
  className?: string;
  variant?: TbTLogoVariant;
};

export const TbTLogo = ({
  width = 245,
  className,
  variant = TbTLogoVariant.BLACK_BLUE,
}: Props) => (
  <div className={className}>
    <Image
      width={width}
      height={width / 3}
      src={variant}
      alt="Tutored by Teachers Logo"
      layout="fixed"
    />
  </div>
);
