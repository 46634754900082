import { CohortAssignmentRole } from "@generated/graphql";
import clsx from "clsx";
import {
  MdPeopleAlt,
  MdPerson,
  MdPersonOff,
  MdPersonOutline,
} from "react-icons/md";
import { Tooltip } from "./Tooltip";

/**
 * You can find an explanation of this Component and what it can be used for in
 * this task: https://app.asana.com/0/1202198978668199/1202606787732449/f
 * You can customize the color of the icon by changing the indicatorColors
 * prop or you can disable it with `text-black` (it appears other colors won't
 * work).
 */
type Props = {
  tooltipId?: string; // To use a Tooltip you must provide a UNIQUE id.
  cohortAssignmentRoles: CohortAssignmentRole[];
  className?: string;
  indicatorColors?: {
    both: string;
    primaryOnly: string;
    substituteOnly: string;
    neither: string;
  };
};
export function AssignmentIndicator({
  tooltipId,
  cohortAssignmentRoles,
  className,
  indicatorColors = {
    both: "text-blue-600",
    primaryOnly: "text-emerald-600",
    substituteOnly: "text-orange-600",
    neither: "text-rose-600",
  },
}: Props) {
  const primary = cohortAssignmentRoles.includes(
    CohortAssignmentRole.PrimaryTeacher
  );
  const substitute = cohortAssignmentRoles.includes(
    CohortAssignmentRole.SubstituteTeacher
  );

  const indicator = {
    icon: <MdPersonOff className={clsx(indicatorColors.neither, className)} />,
    tooltipContent: "No Teacher Assigned",
  };

  if (primary && substitute) {
    indicator.icon = (
      <MdPeopleAlt className={clsx(indicatorColors.both, className)} />
    );
    indicator.tooltipContent = "Primary and Substitute Teachers Assigned";
  } else if (primary && !substitute) {
    indicator.icon = (
      <MdPerson className={clsx(indicatorColors.primaryOnly, className)} />
    );
    indicator.tooltipContent = "Only Primary Teacher Assigned";
  } else if (!primary && substitute) {
    indicator.icon = (
      <MdPersonOutline
        className={clsx(indicatorColors.substituteOnly, className)}
      />
    );
    indicator.tooltipContent = "Only Substitute Teacher Assigned";
  }

  return tooltipId ? (
    <Tooltip className="cursor-help" content={indicator.tooltipContent}>
      {indicator.icon}
    </Tooltip>
  ) : (
    indicator.icon
  );
}
