import { gql } from "@apollo/client";
import {
  AttendanceScorecardStatus,
  AttendanceScorecardTable_TeachersFragment,
} from "@generated/graphql";
import clsx from "clsx";
import { AccountRoleBadge, Badge, Tooltip } from "components/shared";
import { Table } from "components/shared/Table";
import { format } from "date-fns";
import { CellProps, Column } from "react-table";

AttendanceScorecardTable.fragments = {
  attendance: gql`
    fragment AttendanceScorecardTable_Teachers on AttendanceScorecardRecord {
      id
      sessionDate
      attendanceStatus
      role
      cohortName
      engagementName
      notes
    }
  `,
};

type Props = {
  attendanceRecords: AttendanceScorecardTable_TeachersFragment[];
};

export function AttendanceScorecardTable({ attendanceRecords }: Props) {
  return (
    <Table
      data={attendanceRecords}
      columns={getColumns}
      cellPadding="py-3 px-2"
      showPagination={false}
      className="min-h-0"
    />
  );
}

const getColumns: Column<AttendanceScorecardTable_TeachersFragment>[] = [
  {
    Header: "Date",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <div className="whitespace-nowrap">
        {format(new Date(original.sessionDate), "MMMM do, h:mm a")}
      </div>
    ),
  },
  {
    Header: "Status",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <Badge
        className={clsx(
          "capitalize items-center ml-[-4px]",
          original.attendanceStatus === AttendanceScorecardStatus.Present
            ? "bg-emerald-200 text-emerald-900"
            : "bg-rose-200 text-rose-900"
        )}
      >
        {original.attendanceStatus}
      </Badge>
    ),
  },
  {
    Header: "Role",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <AccountRoleBadge accountRole={original.role} mode="tiny" />
    ),
  },
  {
    Header: "Notes",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <Tooltip content={original.notes}>
        <span className="block truncate !max-w-[5vw]">{original.notes}</span>
      </Tooltip>
    ),
  },
  {
    Header: "Cohort",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <span>{original.cohortName}</span>
    ),
  },
  {
    Header: "Engagement",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <span>{original.engagementName}</span>
    ),
  },
];
