import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { Icon, IconType } from "../Icon";
import { CardDetails } from "./components/CardDetails";
import { CardDetailsRow, CardSize } from "./types";

type Props = {
  size?: CardSize;
  icon?: IconType;
  className?: string;
  isCollapsed?: boolean;
  bodyClassName?: string;
  header: React.ReactNode;
  rows?: CardDetailsRow[];
  height?: "full" | "fit";
  headerClassName?: string;
  children?: React.ReactNode;
  headerOverlayColor?: string;
};

export const Card = ({
  rows,
  header,
  icon,
  children,
  className,
  size = "sm",
  bodyClassName,
  height = "fit",
  headerClassName,
  isCollapsed = false,
  headerOverlayColor = "",
}: Props) => {
  const style = getStyle(size);

  return (
    <div
      className={clsx(
        "flex flex-col w-full shadow",
        height === "full" ? "h-full" : "h-fit",
        style.parent,
        className
      )}
    >
      <div
        className={clsx(
          "relative flex items-center justify-start gap-x-[8px]",
          "border border-gray-300 w-full overflow-hidden",
          "bg-gray-300 text-slate-800 leading-4 font-semibold",
          !isCollapsed && "rounded-b-none border-b-0",
          style.header,
          headerClassName
        )}
      >
        {headerOverlayColor && (
          <div
            className={clsx(
              `absolute top-0 left-0 w-full h-full opacity-[0.08] pointer-events-none`,
              headerOverlayColor
            )}
          />
        )}
        {icon && (
          <Icon icon={icon} size={style.iconSize} color="text-slate-700" />
        )}
        {header}
      </div>
      {!isCollapsed && (
        <div
          className={clsx(
            "flex w-full flex-col overflow-x-auto overflow-y-hidden",
            "border border-gray-300 bg-slate-100",
            getScrollbarStyle("gray"),
            height === "full" ? "h-full" : "h-fit",
            style.body,
            bodyClassName
          )}
        >
          {rows && rows.length > 0 && (
            <CardDetails height={height} rows={rows} size={size} />
          )}
          {children}
        </div>
      )}
    </div>
  );
};

const getStyle = (size: CardSize) => {
  switch (size) {
    case "sm":
      return {
        parent: "rounded-lg",
        header: "rounded-lg text-md min-h-[40px] px-3",
        iconSize: 5,
        body: "rounded-b-lg",
      };
    default:
    case "md":
      return {
        parent: "rounded-xl",
        header: "rounded-y-xl text-lg min-h-[42px] px-3",
        iconSize: 5,
        body: "rounded-b-xl",
      };
    case "lg":
      return {
        parent: "rounded-2xl",
        header: "rounded-2xl text-xl min-h-[55px] px-4",
        iconSize: 5,
        body: "rounded-b-2xl",
      };
  }
};
