import { getCountry } from "countries-and-timezones";

/**
 * Note: States are ordered alphabetically by their full name, not their abbreviation.
 */
export const NA_STATES_AND_TERRITORIES: Record<
  "CA" | "US",
  Record<string, string>
> = {
  CA: {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NT: "Northwest Territories",
    NS: "Nova Scotia",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  },
  US: {
    // 50 States + Washington DC
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",

    // US Territories
    AS: "American Samoa",
    FM: "Federated States of Micronesia",
    GU: "Guam",
    MH: "Marshall Islands",
    MP: "Northern Mariana Islands",
    PW: "Palau",
    PR: "Puerto Rico",
    VI: "Virgin Islands",
  },
} as const;

/**
 * Takes a North American state's two-character code and returns the full name of the state.
 * @param stateCode
 * @returns the full name of the state, "Unknown State" if not found
 */
function getNAState(stateCode: string): string {
  const caState = NA_STATES_AND_TERRITORIES.CA[stateCode];
  const usState = NA_STATES_AND_TERRITORIES.US[stateCode];

  if (caState) {
    return caState;
  } else if (usState) {
    return usState;
  } else {
    return "Unknown State";
  }
}

/**
 * Takes a country's two-character code and returns the full name of the country.
 * @param countryId
 * @param fallback If the countryId is null or undefined, this will be returned.
 * @returns the full name of the country and its code
 */
export function getCountryString(
  countryId: string | null | undefined,
  withCode = true,
  fallback = "--"
) {
  return countryId
    ? `${getCountry(countryId)?.name ?? "Unknown Country"}${
        withCode ? ` — ${countryId}` : ""
      }`
    : fallback;
}

/**
 * Takes a North American state's two-character code and returns the full name of the state.
 * @param stateId
 * @param fallback If the stateId is null or undefined, this will be returned.
 * @returns the full name of the state and its code
 */
export function getNAStateString(
  stateId: string | null | undefined,
  withCode = true,
  fallback = "--"
) {
  return stateId
    ? `${getNAState(stateId)}${withCode ? ` — ${stateId}` : ""}`
    : fallback;
}

/**
 * Takes a time zone string performs some basic formatting to make it look less
 * like a machine-readable string.
 * @param timeZone
 * @param fallback
 * @returns the formatted time zone string
 */
export function getTimeZoneString(
  timeZone: string | null | undefined,
  fallback = "--"
) {
  return timeZone
    ? timeZone.replace(/_/g, " ").replace(/\//g, " / ")
    : fallback;
}
