import clsx from "clsx";
import { PillButton } from "../Buttons";

const selectedStyle = "border-2 text-white";
const defaultStyle = "border border-gray-300 bg-white text-gray-700";

export type YesNoNull = boolean | null;

type Props = {
  required?: boolean;
  className?: string;
  readOnly?: boolean;
  isYes: boolean | null;
  onChange?: (isYes: boolean | null) => void;
};

export const YesOrNoInput = ({
  isYes,
  className,
  readOnly = false,
  required = false,
  onChange,
}: Props) => (
  <div className={clsx("flex items-center gap-x-2", className)}>
    {/* [YES Button] | Show unless state is read-Only and value is NOT TRUE */}
    {(!readOnly || isYes) && (
      <PillButton
        onChange={() => !readOnly && onChange?.(isYes ? null : true)}
        className={clsx(
          readOnly && "!cursor-default",
          isYes ? [selectedStyle, getOptionColor(isYes)] : defaultStyle
        )}
        name={<div className={clsx(!isYes && "border border-white")}>Yes</div>}
      />
    )}

    {/* [NO Button] | Show unless state is read-Only and value is NOT FALSE */}
    {(!readOnly || isYes === false) && (
      <PillButton
        onChange={() => !readOnly && onChange?.(isYes === false ? null : false)}
        className={clsx(
          readOnly && "!cursor-default",
          isYes === false
            ? [selectedStyle, getOptionColor(isYes)]
            : defaultStyle
        )}
        name={
          <div className={clsx(isYes !== false && "border border-white")}>
            No
          </div>
        }
      />
    )}

    {/* Required Indicator */}
    {required && !readOnly && (
      <span className="text-gray-800 text-sm -ml-[3px] mb-[4px] font-semibold">
        *
      </span>
    )}
  </div>
);

const getOptionColor = (isYes: boolean) =>
  isYes
    ? "border-emerald-600 bg-emerald-500 bg-opacity-90 text-white"
    : "border-rose-600 bg-rose-500 bg-opacity-80 text-white";
