import { ApplicationStatus, SlotApplicationStatus } from "@generated/graphql";

export const SlotAppStatusColorMap: {
  [key in SlotApplicationStatus]: string;
} = {
  [SlotApplicationStatus.Applied]: "text-blue-900 bg-blue-200",
  [SlotApplicationStatus.Withdrawn]: "text-pink-900 bg-pink-200",
  [SlotApplicationStatus.Accepted]: "text-emerald-900 bg-emerald-200",
  [SlotApplicationStatus.Rejected]: "text-red-900 bg-red-200",
  [SlotApplicationStatus.Offered]: "text-yellow-900 bg-yellow-200",
  [SlotApplicationStatus.Expired]: "text-stone-900 bg-stone-200",
  [SlotApplicationStatus.Rescinded]: "text-rose-900 bg-rose-200",
  [SlotApplicationStatus.Removed]: "text-gray-900 bg-gray-200",
};

export const AppStatusColorMap: {
  [key in ApplicationStatus | "NOT_APPLIED"]: string;
} = {
  [ApplicationStatus.Applied]: "text-blue-900 bg-blue-200",
  [ApplicationStatus.Withdrawn]: "text-pink-900 bg-pink-200",
  NOT_APPLIED: "text-purple-900 bg-purple-200",
};

export const ATTENDANCE_BADGE_COLORS = [
  {
    min: 70,
    max: 100,
    colorClass: "text-emerald-900 bg-emerald-200",
  },
  {
    min: 40,
    max: 69,
    colorClass: "text-yellow-900 bg-yellow-200",
  },
  {
    min: 0,
    max: 39,
    colorClass: "text-rose-900 bg-rose-200",
  },
];

export const QA_BADGE_COLORS = [
  {
    min: 80,
    max: 100,
    colorClass: "text-emerald-900 bg-emerald-200",
  },
  {
    min: 50,
    max: 79,
    colorClass: "text-yellow-900 bg-yellow-200",
  },
  {
    min: 0,
    max: 49,
    colorClass: "text-rose-900 bg-rose-200",
  },
];

export const CAREER_ENGAGEMENT_DAYS_BADGE_COLORS = [
  {
    min: 199,
    max: Number.POSITIVE_INFINITY,
    colorClass: "text-emerald-900 bg-emerald-200",
  },
  {
    min: 0.1,
    max: 198.9,
    colorClass: "text-yellow-900 bg-yellow-200",
  },
  {
    min: 0,
    max: 0,
    colorClass: "text-rose-900 bg-rose-200",
  },
];

export const PLACEMENT_COUNT_BADGE_COLORS = [
  {
    min: 0,
    max: Number.POSITIVE_INFINITY,
    colorClass: "text-gray-900 bg-gray-200",
  },
];
