import { gql } from "@apollo/client";
import { getScrollbarStyle } from "@utils/styleStrings";
import { clsx } from "clsx";
import { SessionStudentEvaluationTableHeader } from "./components/SessionStudentEvaluationTableHeader";
import { SessionStudentEvaluationTableRow } from "./components/SessionStudentEvaluationTableRow/SessionStudentEvaluationTableRow";
import {
  ATTENDANCE_FRAGMENT,
  GRADING_FRAGMENT,
  STUDENT_FRAGMENT,
} from "./fragments";
import { EvaluationTableDetails, StudentEvaluationRow } from "./types";

SessionStudentEvaluationTable.fragments = {
  tutorDashboardEvent: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardEvent on TutorDashboardEvent {
      subject
      cacheKey
      cohortId
      subSubject
      engagementId
      startDateTime
      cohortSessionId
      isCohortNoShowDay
      isCohortAssessmentDay
      isEngagementNoShowDay
      isEngagementAssessmentDay
      cohortSession {
        id
        studentAttendanceEntries {
          ...SessionStudentEvaluationTable_StudentAttendanceRecord
        }
        studentGradingEntries {
          ...SessionStudentEvaluationTable_StudentGradingRecord
        }
      }
    }
    ${GRADING_FRAGMENT}
    ${ATTENDANCE_FRAGMENT}
  `,
  tutorDashboardCohort: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardCohort on TutorDashboardCohort {
      id
      activeStudents {
        id
        studentId
        ...SessionStudentEvaluationTable_TutorDashboardStudent
      }
    }
    ${STUDENT_FRAGMENT}
  `,
  tutorDashboardEngagement: gql`
    fragment SessionStudentEvaluationTable_TutorDashboardEngagement on TutorDashboardEngagement {
      id
      productType
      enableStudentGrading
    }
  `,
};

type Props = {
  readOnly?: boolean;
  highlightIncomplete?: boolean;
  evaluationDetails: EvaluationTableDetails;
  studentEvaluationRows: StudentEvaluationRow[];
};

export function SessionStudentEvaluationTable({
  readOnly = false,
  evaluationDetails,
  highlightIncomplete,
  studentEvaluationRows,
}: Props) {
  return (
    <div
      className={clsx(
        "block w-full h-full overflow-x-auto",
        getScrollbarStyle("barOnly")
      )}
    >
      <div
        className={clsx(
          "flex flex-col min-w-full w-fit border rounded-lg",
          "border-gray-300 divide-y divide-gray-300"
        )}
      >
        <SessionStudentEvaluationTableHeader
          readOnly={readOnly}
          isGradingEnabled={evaluationDetails.isGradingEnabled}
        />

        {studentEvaluationRows.map((row, i) => (
          <SessionStudentEvaluationTableRow
            key={row.studentId}
            readOnly={readOnly}
            studentEvaluationRow={row}
            evaluationDetails={evaluationDetails}
            zIndex={studentEvaluationRows.length - i}
            highlightIncomplete={highlightIncomplete}
            className={clsx(
              i == studentEvaluationRows.length - 1 && "rounded-b-lg"
            )}
          />
        ))}
      </div>
    </div>
  );
}
