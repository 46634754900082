import { Toaster, useToaster } from "react-hot-toast";
import { clearAllToastsButton } from "./helpers";

export const ToastProvider = () => {
  const { toasts } = useToaster();

  return (
    <>
      <Toaster />
      {clearAllToastsButton(toasts.length > 1)}
    </>
  );
};
