import { addMonths } from "date-fns";
import { Icon } from "../../Icon";

type Props = {
  position: "left" | "right";
  targetMonth: Date;
  navigateToMonth: (month: Date) => void;
};

export const CalendarNavButton = ({
  position,
  targetMonth,
  navigateToMonth,
}: Props) => {
  const isLeft = position === "left";
  const nextMonth = isLeft ? -1 : 1;

  return (
    <div
      className="flex items-center justify-center cursor-pointer"
      onClick={() => navigateToMonth(addMonths(targetMonth, nextMonth))}
    >
      <Icon
        icon={isLeft ? "chevronLeft" : "chevronRight"}
        color="text-slate-600"
        size={12}
      />
    </div>
  );
};
