import clsx from "clsx";
import { Icon } from "components/shared";
import { Button } from "components/shared/Buttons";
import { IconType } from "components/shared/Icon";

type Props = {
  onClick: () => boolean;
  disabled: boolean;
  isSelected: boolean;
  className: string;
  icon: IconType;
};

export const MenuBarItem = ({
  onClick,
  disabled,
  isSelected,
  className,
  icon,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={clsx(isSelected ? "is-active" : "", className)}
      theme="tertiary"
    >
      <Icon icon={icon} />
    </Button>
  );
};
