import clsx from "clsx";
import { addMonths, isSameDay, isSameMonth } from "date-fns";
import dayjs from "dayjs";

export const excludedDaysCellRenderer = (
  current: dayjs.Dayjs,
  monthOffset: number,
  targetMonth: Date,
  updatedPrimaryExcludedDays: Date[],
  secondaryExcludedDays: Date[]
) => {
  const isToday = isSameDay(new Date(), current.toDate());
  const isPrimaryExcludedDay = updatedPrimaryExcludedDays.some((excludedDate) =>
    isSameDay(new Date(excludedDate), current.toDate())
  );

  const isSecondaryExcludedDay = secondaryExcludedDays.some((excludedDate) =>
    isSameDay(new Date(excludedDate), current.toDate())
  );

  const isSecondaryExcludedFromOtherMonth =
    isSecondaryExcludedDay &&
    !isSameMonth(current.toDate(), addMonths(targetMonth, monthOffset));

  const isPrimaryExcludedFromOtherMonth =
    isPrimaryExcludedDay &&
    !isSameMonth(current.toDate(), addMonths(targetMonth, monthOffset));

  const isPrimaryAndSecondary = isSecondaryExcludedDay && isPrimaryExcludedDay;

  const isPrimaryAndSecondaryOtherMonth =
    isSecondaryExcludedFromOtherMonth && isPrimaryExcludedFromOtherMonth;

  return (
    <div
      className={clsx(
        isSecondaryExcludedDay &&
          "rounded-xl mx-[6px] text-white bg-purple-500",
        isPrimaryExcludedDay && "rounded-xl mx-[6px] text-white bg-blue-500",
        isSecondaryExcludedFromOtherMonth && "bg-purple-200",
        isPrimaryExcludedFromOtherMonth && "bg-blue-200",
        isToday && "rounded-xl mx-[6px] text-white bg-red-500",
        isToday &&
          isSecondaryExcludedDay &&
          "bg-gradient-to-b from-red-500 to-purple-500",
        isToday &&
          isPrimaryExcludedDay &&
          "bg-gradient-to-b from-red-500 to-blue-500",
        isPrimaryAndSecondary && "bg-gradient-to-b from-blue-500 to-purple-500",
        isPrimaryAndSecondaryOtherMonth &&
          "bg-gradient-to-t from-blue-300 to-purple-200",
        isToday &&
          isPrimaryAndSecondary &&
          "bg-gradient-to-b from-blue-500 via-red-500 to-purple-500"
      )}
    >
      {current.date()}
    </div>
  );
};
