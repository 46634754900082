import { AccountRole, AttendanceScorecardRole } from "@generated/graphql";
import { AccountRoleTextMode, getAccountRoleText } from "@utils/labels";
import { AccountAccessRole, UnauthenticatedUser } from "@utils/routes/types";
import clsx from "clsx";
import { Badge } from "components/shared";
import { useAuth } from "contexts/AuthProvider";

export type BadgeAccountRole = AccountAccessRole | AttendanceScorecardRole;

type Props = {
  accountRole?: BadgeAccountRole;
  className?: string;
  mode?: AccountRoleTextMode;
};

export const AccountRoleBadge = ({
  accountRole,
  className,
  mode = "long",
}: Props) => {
  const { accountRole: currRole } = useAuth();
  const role = accountRole || currRole;
  return (
    <Badge
      className={clsx(accountRoleColors[role], "whitespace-nowrap ", className)}
    >
      {getAccountRoleText(role, mode)}
    </Badge>
  );
};

const accountRoleColors: { [key in BadgeAccountRole]: string } = {
  [AccountRole.Admin]: "text-cyan-900 bg-cyan-200",
  [AccountRole.MentorTeacher]: "text-fuchsia-900 bg-fuchsia-200",
  [AccountRole.TutorTeacher]: "text-indigo-900 bg-indigo-200",
  [AccountRole.Visitor]: "text-teal-900 bg-teal-200",
  [AttendanceScorecardRole.InstructionalSupport]:
    "text-emerald-900 bg-emerald-200",
  [UnauthenticatedUser.NonAuthedUser]: "text-slate-900 bg-slate-200",
};
