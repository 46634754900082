import clsx from "clsx";
import { PropsWithChildren, ReactNode, useId } from "react";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Icon } from "./Icon";

export type ReactTooltipProps = Exclude<
  ITooltip,
  "children" | "content" | "html" | "anchorId"
>;

type Props = {
  className?: string;
  tooltipProps?: ReactTooltipProps;
  content: ReactNode;
  contentClassName?: string;
  disabled?: boolean;
};

export const Tooltip = ({
  tooltipProps,
  className,
  content,
  contentClassName,
  children,
  disabled,
}: PropsWithChildren<Props>) => {
  const id = useId();

  return (
    <>
      <span
        id={id}
        className={clsx("h-fit w-fit", Boolean(children) && className)}
      >
        {children || (
          <Icon
            icon="info"
            color="text-blue-600"
            size={5}
            className={className}
          />
        )}
      </span>
      {!disabled && (
        <ReactTooltip {...tooltipProps} anchorId={id} className="z-[4000]">
          <div>
            {typeof content === "string" ? (
              <div className={contentClassName ?? "text-sm text-center"}>
                {content}
              </div>
            ) : (
              <>{content}</>
            )}
          </div>
        </ReactTooltip>
      )}
    </>
  );
};
