import { NA_STATES_AND_TERRITORIES } from "@utils/geo";
import { assertUnreachable } from "@utils/types";
import { useMemo } from "react";
import { SelectMenu, SelectMenuOption, SelectMenuProps } from "./SelectMenu";

type NAStateSelectMenuOption = SelectMenuOption<{
  stateCode: string | null;
}>;

type Props = {
  countryCode: "CA" | "US" | "OTHER";
  initialValue: string | null | undefined;
  onSelect: (stateCode: string | null) => void;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
};

export function NAStateSelectMenu({
  countryCode,
  initialValue,
  onSelect,
  selectMenuProps,
}: Props) {
  const options = useMemo(() => {
    const options: NAStateSelectMenuOption[] = [
      {
        id: "none",
        value: "--",
        selectedLabel: "None Selected",
        stateCode: null,
      },
    ];

    switch (countryCode) {
      case "US":
        options.push(
          ...Object.keys(NA_STATES_AND_TERRITORIES.US).map((stateCode) => ({
            id: stateCode,
            value: `${NA_STATES_AND_TERRITORIES.US[stateCode]} — ${stateCode}`,
            stateCode,
          }))
        );
        break;
      case "CA":
        options.push(
          ...Object.keys(NA_STATES_AND_TERRITORIES.CA).map((stateCode) => ({
            id: stateCode,
            value: `${NA_STATES_AND_TERRITORIES.CA[stateCode]} — ${stateCode}`,
            stateCode,
          }))
        );
        break;
      case "OTHER":
        break;
      default:
        assertUnreachable(countryCode, "countryCode");
    }
    return options;
  }, [countryCode]);

  const initialStateIndex = useMemo(
    () => options.findIndex(({ stateCode }) => stateCode === initialValue),
    [initialValue, options]
  );

  const handleSelect = ({ stateCode }: NAStateSelectMenuOption) => {
    onSelect(stateCode);
  };

  return (
    <SelectMenu
      {...selectMenuProps}
      options={options}
      onSelect={handleSelect}
      initialIndex={initialStateIndex}
    />
  );
}
