import {
  arePathsEqual,
  getRouteFromPath,
  isUnauthenticatedRoute,
} from "@utils/routes";
import { AccountAccessRole, Route } from "@utils/routes/types";
import { isAuthenticatedRoute } from "@utils/routes/utils";

export const isNotAuthenticatedRoute = (
  activeRoute: Route,
  activeURL: string
) => {
  let route = activeRoute;
  if (!arePathsEqual(activeRoute.path(), activeURL))
    route = getRouteFromPath(activeURL) ?? activeRoute;
  return isUnauthenticatedRoute(route);
};

export const isAuthedRoute = (activeRoute: Route, activeURL: string) => {
  let route = activeRoute;
  if (!arePathsEqual(activeRoute.path(), activeURL))
    route = getRouteFromPath(activeURL) ?? activeRoute;
  return route ? isAuthenticatedRoute(route) : true;
};

import { getAccountRoleText, getAdminModeText } from "@utils/labels";
import { ThemeMode } from "components/shared/types";
import { AdminMode } from "types/global";

export const hasHadRecentChange = (lastChangeTime: Date | undefined): boolean =>
  Boolean(lastChangeTime && +new Date() - +new Date(lastChangeTime) < 500);

export const getStaffModeWarningToastProps = {
  message: "You have been redirected.",
  sub: "That page was not available in Staffing mode.",
  theme: "info" as ThemeMode,
};

export const getAccountWarningToastProps = (role: AccountAccessRole) => ({
  message: "You have been redirected.",
  sub: `That page is not available for ${getAccountRoleText(role)}s`,
  theme: "info" as ThemeMode,
});

export const getAdminModeChangeToastProps = (mode: AdminMode) => ({
  message: `You have been switched to ${getAdminModeText(mode)} Mode.`,
  sub: `The Engagement is in the ${getAdminModeText(mode)} status.`,
  theme: "info" as ThemeMode,
});

export const getUnknownRouteToastProps = (route: string) => ({
  message: `You have been safely redirected.`,
  sub: `The ${route} route is unknown.`,
  theme: "warning" as ThemeMode,
});
