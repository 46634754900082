import { Pixel } from "@utils/numbers";
import { getQrCodeImageUrl } from "@utils/qr";
import Image from "next/image";
import { MinimalCopyButton } from "./Buttons/MinimalCopyButton";

type Props = {
  text: string;
  size: Pixel;
  alt?: string;
};

export default function QrCodeImage({ text, size, alt = "QR Code" }: Props) {
  const imageUrl = getQrCodeImageUrl(text, size);
  return (
    <>
      <Image
        className="my-2"
        src={imageUrl}
        height={size}
        width={size}
        alt={alt}
      />
      <MinimalCopyButton copyValue={imageUrl} label="Copy link" />
    </>
  );
}
