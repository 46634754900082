import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { PageButton } from "../Buttons/PageButton";

type PaginationControlsProps = {
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  pageSize: number;
  total: number;
  alwaysShowExtremes?: number;
  alwaysShowNeighbors?: number;
};

export function PaginationControls({
  pageIndex,
  setPageIndex,
  pageSize,
  total,
  alwaysShowExtremes = 1,
  alwaysShowNeighbors = 1,
}: PaginationControlsProps) {
  // Bugs happen when these are set to 0 and fixing them is too painful.
  alwaysShowExtremes = Math.max(alwaysShowExtremes, 1);
  alwaysShowNeighbors = Math.max(alwaysShowNeighbors, 1);

  const totalPages = Math.ceil(total / pageSize);

  const handlePageClick = (newIndex: number) => {
    setPageIndex(newIndex);
  };

  const renderPageNumbers = (
    alwaysShowExtremes: number,
    alwaysShowNeighbors: number
  ) => {
    const pageNumbers: React.ReactNode[] = [];

    // Quickly detect that there's no need to hide any pages: thus, show all pages.
    // (Extremes twice, neighbors twice, current page, and two ellipsis.)
    const showAll =
      alwaysShowExtremes * 2 + alwaysShowNeighbors * 2 + 1 + 2 >= totalPages;

    for (let i = 0; i < totalPages; ++i) {
      const showNeighbors =
        (i >= pageIndex - alwaysShowNeighbors && i < pageIndex) ||
        (i <= pageIndex + alwaysShowNeighbors && i > pageIndex);
      const showExtreme =
        i < alwaysShowExtremes || i >= totalPages - alwaysShowExtremes;

      const beforeGapSize =
        pageIndex - alwaysShowExtremes - alwaysShowNeighbors;
      const afterGapSize =
        totalPages - alwaysShowExtremes - pageIndex - alwaysShowNeighbors - 1;
      const hideEllipsisBefore =
        i === pageIndex - alwaysShowNeighbors - 1 && beforeGapSize === 1;
      const hideEllipsisAfter =
        i === pageIndex + alwaysShowNeighbors + 1 && afterGapSize === 1;

      const showEllipsis =
        (i === alwaysShowExtremes && pageIndex > alwaysShowExtremes + 1) ||
        (i === totalPages - alwaysShowExtremes - alwaysShowNeighbors &&
          pageIndex <
            totalPages - alwaysShowExtremes - alwaysShowNeighbors - 1);

      if (
        i === pageIndex ||
        showAll ||
        showNeighbors ||
        showExtreme ||
        hideEllipsisBefore ||
        hideEllipsisAfter
      ) {
        pageNumbers.push(
          <PageButton
            key={i}
            onClick={() => handlePageClick(i)}
            currentPage={pageIndex === i}
          >
            {i + 1}
          </PageButton>
        );
      } else if (showEllipsis) {
        pageNumbers.push(
          <PageButton key={i} disabled>
            ...
          </PageButton>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="flex flex-1 flex-wrap items-center justify-between gap-2">
      <div>
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium">{pageIndex * pageSize + 1}</span> to{" "}
          <span className="font-medium">
            {Math.min((pageIndex + 1) * pageSize, total)}
          </span>{" "}
          of <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md"
          aria-label="Pagination"
        >
          <PageButton
            disabled={pageIndex === 0}
            onClick={() => handlePageClick(pageIndex - 1)}
            rounded="left"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </PageButton>
          <div className="hidden lg:block">
            {renderPageNumbers(alwaysShowExtremes, alwaysShowNeighbors)}
          </div>
          <div className="lg:hidden">
            <PageButton disabled>
              Page {pageIndex + 1} of {totalPages}
            </PageButton>
          </div>
          <PageButton
            disabled={pageIndex * pageSize + pageSize >= total}
            onClick={() => handlePageClick(pageIndex + 1)}
            rounded="right"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </PageButton>
        </nav>
      </div>
    </div>
  );
}
