import { ExclamationCircleIcon } from "@heroicons/react/solid";

export const FieldWarning = ({ msg }: { msg?: string | null }) =>
  msg ? (
    <span className="mt-1 flex items-center text-sm text-gray-600">
      <ExclamationCircleIcon
        className="w-4 h-4 text-orange-400 mr-2 flex-shrink-0"
        aria-hidden="true"
      />
      {msg}
    </span>
  ) : null;
