export const STUDENT_WIDTH = 210;

export const SCREEN_WIDTH = 180;

export const ATTENDANCE_MIN_WIDTH = 540;
export const ATTENDANCE_READONLY_WIDTH = 240;

export const GRADING_MIN_WIDTH = 460;
export const GRADING_READONLY_WIDTH = 180;

export const NOTES_MIN_WIDTH = 250;
