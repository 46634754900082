import {
  AccountRole,
  AccountStatus,
  ApplicationStatus,
  AttendanceScorecardRole,
  CohortAssignmentRole,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  DayOfWeek,
  EngagementAssignmentRole,
  EngagementProductType,
  EngagementProgramType,
  EngagementStatus,
  ExternalUserAccountType,
  HolidayType,
  Language,
  RosterRecordStatus,
  RosterRole,
  SessionConcernType,
  SessionReportInstructionType,
  SlotApplicationStatus,
  TutoringPreferencesPosition,
  YesNoSomewhat,
} from "@generated/graphql";
import { BadgeAccountRole } from "components/shared/Badges/AccountRoleBadge";
import sortBy from "lodash/sortBy";
import { AdminMode, AttendanceStatus } from "types/global";
import { IANAtzName, STANDARD_TIME_ZONES, dayOfWeekMap } from "./dateTime";
import { AccountAccessRole, UnauthenticatedUser } from "./routes/types";
import { assertUnreachable } from "./types";

export type AccountRoleTextMode = "tiny" | "short" | "long";
export type CohortAssignmentSubjectTextMode = "short" | "long";
export type CohortAssignmentSubSubjectTextMode = "short" | "medium" | "long";
export type CohortAssignmentRoleTextMode = "tiny" | "short" | "long";
export type EngagementAssignmentRoleTextMode = "tiny" | "short" | "long";
export type RosterRoleTextMode = "short" | "long";
export type TimeZoneTextMode = "iana" | "short" | "location";
export type ExternalUserAccountTypeTextMode = "short" | "long";
export type SlotApplicationStatusTextMode = "verb-past" | "noun";
export type TutoringPreferencesPositionTextMode = "short" | "long";
export type DayOfWeekTextMode = "narrow" | "compact" | "short" | "long";
export type EngagementProductTypeTextMode = "short" | "long";

/**
 * This is a  collection of functions for getting text labels for various enums.
 */

export function getCohortSubSubjectText(
  cohortSubSubject: CohortAssignmentSubSubject,
  mode: CohortAssignmentSubSubjectTextMode = "long"
): string {
  const cohortSubSubjectTextMap: Record<
    CohortAssignmentSubSubject,
    { short: string; medium: string; long: string }
  > = {
    [CohortAssignmentSubSubject.General]: {
      short: "GEN",
      medium: "General",
      long: "General",
    },
    [CohortAssignmentSubSubject.Algebra_1]: {
      short: "ALG1",
      medium: "Alg. 1",
      long: "Algebra 1",
    },
    [CohortAssignmentSubSubject.Algebra_2]: {
      short: "ALG2",
      medium: "Alg. 2",
      long: "Algebra 2",
    },
    [CohortAssignmentSubSubject.Geometry]: {
      short: "GEOM",
      medium: "Geometry",
      long: "Geometry",
    },
    [CohortAssignmentSubSubject.IntegratedMath_1]: {
      short: "IM1",
      medium: "Integ. Math 1",
      long: "Integrated Math 1",
    },
    [CohortAssignmentSubSubject.IntegratedMath_2]: {
      short: "IM2",
      medium: "Integ. Math 2",
      long: "Integrated Math 2",
    },
    [CohortAssignmentSubSubject.IntegratedMath_3]: {
      short: "IM3",
      medium: "Integ. Math 3",
      long: "Integrated Math 3",
    },
    [CohortAssignmentSubSubject.Biology]: {
      short: "BIO",
      medium: "Biology",
      long: "Biology",
    },
    [CohortAssignmentSubSubject.EarthSpace]: {
      short: "E&S",
      medium: "Earth+Space",
      long: "Earth & Space",
    },
    [CohortAssignmentSubSubject.Chemistry]: {
      short: "CHEM",
      medium: "Chemistry",
      long: "Chemistry",
    },
    [CohortAssignmentSubSubject.Physics]: {
      short: "PHY",
      medium: "Physics",
      long: "Physics",
    },
    [CohortAssignmentSubSubject.WorldHistory]: {
      short: "WH",
      medium: "Wrld. Hist.",
      long: "World History",
    },
    [CohortAssignmentSubSubject.AmericanHistory]: {
      short: "AH",
      medium: "Am. History",
      long: "American History",
    },
    [CohortAssignmentSubSubject.Government]: {
      short: "GOV",
      medium: "Government",
      long: "Government",
    },
    [CohortAssignmentSubSubject.English_9]: {
      short: "EN9",
      medium: "Eng. 9",
      long: "English 9",
    },
    [CohortAssignmentSubSubject.English_10]: {
      short: "EN10",
      medium: "Eng. 10",
      long: "English 10",
    },
    [CohortAssignmentSubSubject.English_11]: {
      short: "EN11",
      medium: "Eng. 11",
      long: "English 11",
    },
    [CohortAssignmentSubSubject.English_12]: {
      short: "EN12",
      medium: "Eng. 12",
      long: "English 12",
    },
  };
  return cohortSubSubjectTextMap[cohortSubSubject][mode];
}

export function getCohortSubjectText(
  cohortSubject: CohortAssignmentSubject,
  mode: CohortAssignmentSubjectTextMode = "long",
  cohortSubSubject?: CohortAssignmentSubSubject | null
): string {
  const cohortSubjectTextMap: Record<
    CohortAssignmentSubject,
    { short: string; long: string }
  > = {
    [CohortAssignmentSubject.Math]: { short: "MATH", long: "Math" },
    [CohortAssignmentSubject.Ela]: { short: "ELA", long: "ELA" },
    [CohortAssignmentSubject.General]: { short: "GEN", long: "General" },
    [CohortAssignmentSubject.Science]: { short: "SCI", long: "Science" },
    [CohortAssignmentSubject.SocialStudies]: {
      short: "SS",
      long: "Social Studies",
    },
  };

  return cohortSubSubject &&
    cohortSubSubject !== CohortAssignmentSubSubject.General
    ? `${cohortSubjectTextMap[cohortSubject][mode]}:${getCohortSubSubjectText(
        cohortSubSubject,
        mode
      )}`
    : cohortSubjectTextMap[cohortSubject][mode];
}

/**
 * tiny | short      | long
 * -----|------------|----------------------
 * Ad   | Admin      | Administrator
 * MT   | Mentor     | Mentor Teacher
 * V    | Visitor    | Visitor
 * TT   | Tutor      | Teacher-Tutor
 * IS   | Substitute | Instructional Support
 * XX   | Unauth     | Unauthenticated
 */
export function getAccountRoleText(
  accountRole: BadgeAccountRole | AccountAccessRole,
  mode: AccountRoleTextMode = "long"
): string {
  switch (accountRole) {
    case AccountRole.Admin:
      switch (mode) {
        case "tiny":
          return "ADM";
        case "short":
          return "Admin";
        case "long":
          return "Administrator";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.MentorTeacher:
      switch (mode) {
        case "tiny":
          return "MT";
        case "short":
          return "Mentor";
        case "long":
          return "Mentor Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.Visitor:
      switch (mode) {
        case "tiny":
          return "V";
        case "short":
        case "long":
          return "Visitor";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AccountRole.TutorTeacher:
    case AttendanceScorecardRole.TutorTeacher:
      switch (mode) {
        case "tiny":
          return "TT";
        case "short":
          return "Tutor";
        case "long":
          return "Teacher-Tutor";
        default:
          return assertUnreachable(mode, "mode");
      }
    case AttendanceScorecardRole.InstructionalSupport:
      switch (mode) {
        case "tiny":
          return "IS";
        case "short":
          return "Substitute";
        case "long":
          return "Instructional Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case UnauthenticatedUser.NonAuthedUser: {
      switch (mode) {
        case "tiny":
          return "XX";
        case "short":
          return "Unauth";
        case "long":
          return "Unauthenticated";
        default:
          return assertUnreachable(mode, "mode");
      }
    }
    default:
      return assertUnreachable(accountRole, "accountRole");
  }
}

export function getAccountStatusText(accountStatus: AccountStatus): string {
  switch (accountStatus) {
    case AccountStatus.Active:
      return "Active";
    case AccountStatus.Pending:
      return "Pending";
    case AccountStatus.Inactive:
      return "Inactive";
    default:
      assertUnreachable(accountStatus, "AccountStatusBadge text");
  }
}

/**
 * tiny | short       | long
 * Pri  | Primary     | Primary Teacher
 * Sub  | Substitute  | Substitute Teacher
 */
export function getCohortAssignmentRoleText(
  cohortAssignmentRole: CohortAssignmentRole,
  mode: CohortAssignmentRoleTextMode = "long"
): string {
  switch (cohortAssignmentRole) {
    case CohortAssignmentRole.PrimaryTeacher:
      switch (mode) {
        case "tiny":
          return "Pri";
        case "short":
          return "Primary";
        case "long":
          return "Primary Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case CohortAssignmentRole.SubstituteTeacher:
      switch (mode) {
        case "tiny":
          return "Sub";
        case "short":
          return "Substitute";
        case "long":
          return "Substitute Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(cohortAssignmentRole, "cohortAssignmentRole");
  }
}

export function getAttendanceStatusText(
  studentAttendanceStatus: AttendanceStatus
): string {
  switch (studentAttendanceStatus) {
    case CohortSessionStudentAttendanceStatus.Unknown:
      return "Unknown";
    case CohortSessionStudentAttendanceStatus.Present:
      return "Present";
    case CohortSessionStudentAttendanceStatus.Absent:
      return "Absent";
    case CohortSessionStudentAttendanceStatus.Partial:
      return "Partial";
    default:
      return "Error - Unknown Attendance Status";
  }
}

/**
 * tiny | short | long
 *
 * MT | Mentor | Mentor Teacher
 *
 * IS | IS Teacher | Instructional Support Teacher
 */
export function getEngagementAssignmentRoleText(
  engagementAssignmentRole: EngagementAssignmentRole,
  mode: EngagementAssignmentRoleTextMode = "long"
): string {
  switch (engagementAssignmentRole) {
    case EngagementAssignmentRole.MentorTeacher:
      switch (mode) {
        case "tiny":
          return "MT";
        case "short":
          return "Mentor";
        case "long":
          return "Mentor Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementAssignmentRole.SubstituteTeacher:
      switch (mode) {
        case "tiny":
          return "IS";
        case "short":
          return "IS Teacher";
        case "long":
          return "Instructional Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementAssignmentRole.EngagementManager:
      switch (mode) {
        case "tiny":
          return "EM";
        case "short":
          return "Eng Manager";
        case "long":
          return "Engagement Manager";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(
        engagementAssignmentRole,
        "engagementAssignmentRole"
      );
  }
}

export function getRosterRoleText(
  rosterRole: RosterRole,
  mode: RosterRoleTextMode = "long"
): string {
  switch (rosterRole) {
    case RosterRole.TutorTeacher:
      switch (mode) {
        case "short":
          return "TT";
        case "long":
          return "Teacher-Tutor";
        default:
          return assertUnreachable(mode, "mode");
      }
    case RosterRole.MentorTeacher:
      switch (mode) {
        case "short":
          return "MT";
        case "long":
          return "Mentor Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case RosterRole.SubstituteTeacher:
      switch (mode) {
        case "short":
          return "IS";
        case "long":
          return "Instructional Support Teacher";
        default:
          return assertUnreachable(mode, "mode");
      }
    case RosterRole.EngagementManager:
      switch (mode) {
        case "short":
          return "EM";
        case "long":
          return "Engagement Manager";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(rosterRole, "rosterRole");
  }
}

export function getRosterRecordStatus(
  rosterRecordStatus: RosterRecordStatus
): string {
  switch (rosterRecordStatus) {
    case RosterRecordStatus.Pending:
      return "Pending";
    case RosterRecordStatus.Confirmed:
      return "Confirmed";
    case RosterRecordStatus.Destaffed:
      return "Destaffed";
    default:
      return assertUnreachable(rosterRecordStatus, "rosterRecordStatus");
  }
}

export function archiveAndTestLabelBG(archived: boolean, isTest: boolean) {
  if (archived && isTest) {
    return "bg-gradient-to-r from-green-50 to-amber-50";
  } else if (archived) {
    return "bg-green-50";
  } else if (isTest) {
    return "bg-amber-50";
  } else {
    return "";
  }
}

export function getTimeZoneText(
  timeZone: IANAtzName | string,
  mode: TimeZoneTextMode
): string {
  if (STANDARD_TIME_ZONES[timeZone]) {
    switch (mode) {
      case "iana":
        return timeZone; // Sent right back at you.
      case "short":
        return STANDARD_TIME_ZONES[timeZone].shortTz;
      case "location":
        return STANDARD_TIME_ZONES[timeZone].location;
      default:
        return assertUnreachable(mode, "mode");
    }
  } else {
    switch (mode) {
      case "iana":
        return timeZone; // Sent right back at you.
      case "short":
        const shortMatch = timeZone.match(/^.*\/(.*)$/);
        return shortMatch ? shortMatch[1] : timeZone;
      case "location":
        const locationMatch = timeZone.match(/^.*\/(.*)$/);
        if (locationMatch == null) return timeZone;
        return locationMatch[1].replace(/_/g, " ");
      default:
        return assertUnreachable(mode, "mode");
    }
  }
}

export function getExternalUserAccountTypeText(
  externalUserAccountType: ExternalUserAccountType,
  mode: ExternalUserAccountTypeTextMode = "long"
): string {
  switch (externalUserAccountType) {
    case ExternalUserAccountType.GoogleMeets:
      switch (mode) {
        case "short":
          return "GMeet";
        case "long":
          return "Google Meet";
        default:
          return assertUnreachable(mode, "mode");
      }

    case ExternalUserAccountType.MicrosoftTeams:
      switch (mode) {
        case "short":
          return "Teams";
        case "long":
          return "Microsoft Teams";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Zoom:
      switch (mode) {
        case "short":
        case "long":
          return "Zoom";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Slack:
      switch (mode) {
        case "short":
        case "long":
          return "Slack";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.Tipalti:
      switch (mode) {
        case "short":
        case "long":
          return "Tipalti";
        default:
          return assertUnreachable(mode, "mode");
      }
    case ExternalUserAccountType.TalentLms:
      switch (mode) {
        case "short":
        case "long":
          return "Talent LMS";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      return assertUnreachable(
        externalUserAccountType,
        "externalUserAccountType"
      );
  }
}

export function getEngagementStatusText(
  engagementStatus: EngagementStatus
): string {
  switch (engagementStatus) {
    case EngagementStatus.Managing:
      return "Managing";
    case EngagementStatus.Staffing:
      return "Staffing";
    case EngagementStatus.Archived:
      return "Archived";
    default:
      return assertUnreachable(engagementStatus, "engagementStatus");
  }
}

export function getAdminModeText(adminMode: AdminMode): string {
  switch (adminMode) {
    case AdminMode.Dashboard:
      return "Dashboard";
    case AdminMode.Managing:
      return "Managing";
    case AdminMode.Staffing:
      return "Staffing";
    case AdminMode.ControlPanel:
      return "ControlPanel";
    default:
      return assertUnreachable(adminMode, "adminMode");
  }
}

export const getLanguageText = (language: Language): string => {
  switch (language) {
    case Language.English:
      return "English";
    case Language.Spanish:
      return "Spanish";
    default:
      return assertUnreachable(language, "language");
  }
};

export const getApplicationStatusText = (
  applicationStatus: ApplicationStatus | "NOT_APPLIED"
): string => {
  switch (applicationStatus) {
    case ApplicationStatus.Applied:
      return "Applied";
    case ApplicationStatus.Withdrawn:
      return "Withdrawn";
    case "NOT_APPLIED":
      return "Not Applied";
    default:
      return "";
  }
};
export const getSlotApplicationStatusText = (
  slotApplicationStatus: SlotApplicationStatus,
  mode: SlotApplicationStatusTextMode
): string => {
  switch (slotApplicationStatus) {
    case SlotApplicationStatus.Applied:
      switch (mode) {
        case "verb-past":
          return "Applied";
        case "noun":
          return "Application";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Withdrawn:
      switch (mode) {
        case "verb-past":
          return "Withdrawn";
        case "noun":
          return "Withdrawal";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Accepted:
      switch (mode) {
        case "verb-past":
          return "Accepted";
        case "noun":
          return "Acceptance";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Rejected:
      switch (mode) {
        case "verb-past":
          return "Rejected";
        case "noun":
          return "Rejection";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Offered:
      switch (mode) {
        case "verb-past":
          return "Pending";
        case "noun":
          return "Offer";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Expired:
      switch (mode) {
        case "verb-past":
          return "Expired";
        case "noun":
          return "Expiration";
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Rescinded:
      switch (mode) {
        case "verb-past":
          return "Rescinded";
        case "noun":
          return "Rescindment"; // https://en.wiktionary.org/wiki/rescindment#English
        default:
          return assertUnreachable(mode, "mode");
      }
    case SlotApplicationStatus.Removed:
      switch (mode) {
        case "verb-past":
          return "Removed";
        case "noun":
          return "Removal";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      assertUnreachable(slotApplicationStatus, "applicationStatus");
  }
};

export const getHolidayTypeText = (holidayType: HolidayType): string => {
  switch (holidayType) {
    case HolidayType.Organization:
      return "Organization";
    case HolidayType.Usa:
      return "US";
    default:
      assertUnreachable(holidayType, "holidayType");
  }
};

export function getTutoringPreferencesPositionText(
  position: TutoringPreferencesPosition,
  mode: TutoringPreferencesPositionTextMode = "long"
): string {
  switch (position) {
    case TutoringPreferencesPosition.InstructionalSupport:
      switch (mode) {
        case "short":
          return "IS";
        case "long":
          return "Instructional Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case TutoringPreferencesPosition.TeacherTutor:
      switch (mode) {
        case "short":
          return "TT";
        case "long":
          return "Teacher Tutor";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      assertUnreachable(position, "position");
  }
}

/**
 * Returns the text representation of the day of the week supporting client locale.
 *
 * Please note that "narrow" mode will return the first letter of the day of the week,
 * meaning Tuesday and Thursday will both return "T" and Sunday and Saturday will both return "S".
 *
 * The custom "compact" mode will return the first two characters of the "short" mode
 * no matter the locale.
 *
 * @param day
 * @param mode  "narrow" ("T") | "compact" ("Th") | "short" ("Thu") | "long" ("Thursday")
 * @param locale
 * @returns
 */
export function getDayOfWeekText(
  day: DayOfWeek,
  mode: DayOfWeekTextMode,
  locale?: string
): string {
  // Get the current date.
  const date = new Date();

  // Set the date to the next occurrence of the specified day.
  date.setDate(date.getDate() + ((dayOfWeekMap[day] + 7 - date.getDay()) % 7));

  // Handle our custom "compact" mode.
  const weekday = mode === "compact" ? "short" : mode;

  // Format the date to the specified locale and get the weekday.
  const value = new Intl.DateTimeFormat(locale, { weekday }).format(date);

  return mode === "compact" ? value.slice(0, 2) : value;
}

export function getDayOfWeekArrayOrderedText(
  days: DayOfWeek[],
  mode: DayOfWeekTextMode,
  separator: string,
  locale?: string
): string {
  return sortBy(days, (day) => dayOfWeekMap[day])
    .map((day) => getDayOfWeekText(day, mode, locale))
    .join(separator);
}

export function getEngagementProductTypeText(
  productType: EngagementProductType,
  mode: EngagementProductTypeTextMode
): string {
  switch (productType) {
    case EngagementProductType.CurricularReinforcement:
      switch (mode) {
        case "short":
          return "CR";
        case "long":
          return "Curricular Reinforcement";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.VirtualInterventionModel:
      switch (mode) {
        case "short":
          return "VIM";
        case "long":
          return "Virtual Intervention Model";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.VacancySupport:
      switch (mode) {
        case "short":
          return "VSUP";
        case "long":
          return "Vacancy Support";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.TestPrep:
      switch (mode) {
        case "short":
          return "TP";
        case "long":
          return "Test Prep";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.SummerAcademy:
      switch (mode) {
        case "short":
          return "SA";
        case "long":
          return "Summer Academy";
        default:
          return assertUnreachable(mode, "mode");
      }
    case EngagementProductType.EnglishLanguageDevelopment:
      switch (mode) {
        case "short":
          return "ELD";
        case "long":
          return "English Language Development";
        default:
          return assertUnreachable(mode, "mode");
      }
    default:
      assertUnreachable(productType, "productType");
  }
}

export function getEngagementProgramTypeText(
  programType: EngagementProgramType
): string {
  switch (programType) {
    case EngagementProgramType.SchoolSelected:
      return "School Selected";
    case EngagementProgramType.ParentStudentOptIn:
      return "Parent/Student Opt-in";
    default:
      return assertUnreachable(programType, "programType");
  }
}

export const getSessionConcernTypeText = (
  concernType: SessionConcernType
): string => {
  switch (concernType) {
    case SessionConcernType.Attendance:
      return "Attendance";
    case SessionConcernType.Behavior:
      return "Behavior";
    case SessionConcernType.WellBeing:
      return "Well Being";
    case SessionConcernType.Assessments:
      return "Assessments";
    case SessionConcernType.TechIssues:
      return "Tech Issues";
    case SessionConcernType.TechLiteracy:
      return "Tech Literacy";
    case SessionConcernType.LanguageBarrier:
      return "Language Barrier";
    case SessionConcernType.Other:
      return "Other";
    default:
      return assertUnreachable(concernType, "concernType");
  }
};

export const getYesNoSomewhatText = (yesNoSomewhat: YesNoSomewhat): string => {
  switch (yesNoSomewhat) {
    case YesNoSomewhat.Yes:
      return "Yes";
    case YesNoSomewhat.Somewhat:
      return "Somewhat";
    case YesNoSomewhat.No:
      return "No";
    default:
      return assertUnreachable(yesNoSomewhat, "yesNoSomewhat");
  }
};

export const getSessionReportInstructionTypeText = (
  type: SessionReportInstructionType
): string => {
  switch (type) {
    case SessionReportInstructionType.VimMath:
      return "VIM Math";
    case SessionReportInstructionType.VimEla:
      return "VIM Ela";
    case SessionReportInstructionType.Vsup:
      return "Vacancy Support";
    default:
    case SessionReportInstructionType.Default:
      return "Default";
  }
};
