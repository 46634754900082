import { CountryCode, getAllCountries } from "countries-and-timezones";
import { useMemo } from "react";
import { SelectMenuProps } from ".";
import { SelectMenu, SelectMenuOption } from "./SelectMenu";

type CountrySelectMenuOption = SelectMenuOption<{
  countryCode: CountryCode | null;
}>;

type Props = {
  initialValue: CountryCode | string | null | undefined;
  onSelect: (countryCode: CountryCode | string | null) => void;
  selectMenuProps?: Exclude<SelectMenuProps, "initialIndex">;
};

export function CountrySelectMenu({
  initialValue,
  onSelect,
  selectMenuProps,
}: Props) {
  const options = useMemo(() => {
    const allCountries = getAllCountries();
    const sortedCountries = Object.keys(allCountries).sort((a, b) => {
      if (a === "US") return -1;
      if (b === "US") return 1;
      if (a === "CA") return -1;
      if (b === "CA") return 1;
      const aName = allCountries[a as CountryCode].name;
      const bName = allCountries[b as CountryCode].name;
      return aName.localeCompare(bName);
    }) as CountryCode[];

    const options: CountrySelectMenuOption[] = [
      {
        id: "none",
        value: "--",
        selectedLabel: "None Selected",
        countryCode: null,
      },
    ];

    options.push(
      ...sortedCountries.map((countryCode) => ({
        id: countryCode,
        value: allCountries[countryCode].name,
        countryCode,
      }))
    );
    return options;
  }, []);

  const initialCountryIndex = useMemo(
    () => options.findIndex(({ countryCode }) => countryCode === initialValue),
    [initialValue, options]
  );

  const handleSelect = ({ countryCode }: CountrySelectMenuOption) => {
    onSelect(countryCode);
  };

  return (
    <SelectMenu
      {...selectMenuProps}
      options={options}
      onSelect={handleSelect}
      initialIndex={initialCountryIndex}
    />
  );
}
