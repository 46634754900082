import { gql } from "@apollo/client";
import { SetSpoofUser_UserFragment } from "@generated/graphql";
import { Cookies, getCookieOptions } from "@utils/cookies";

/**
 * Important Note:
 * We adhere to the Principle of Co-location here when it comes to GraphQL fragments.
 * https://www.apollographql.com/docs/react/data/fragments/#colocating-fragments
 * https://kentcdodds.com/blog/colocation#the-principle
 *
 * If you haven't yet discovered how great it is to colocate fragments you will
 * soon.
 *
 * But sometimes, co-locating a fragment with a component might not work. Perhaps
 * you need to write a special data algorithm that could be re-used among many
 * components. Something like that that would normally be a candidate for our
 * collection of utility functions. But when that algorithm works with a certain
 * segment of data it's good to colocate that fragment with the function itself
 * and place them all in a dedicated spot: this withFragments directory.
 *
 * Utilize this file as a home for __spoofing__ fragments, functions, and
 * types. If you want to work with another concern of our project (ex: users)
 * then start a new file.
 */

setSpoofUser.fragments = {
  user: gql`
    fragment SetSpoofUser_User on User {
      id
      fullName
      email
      accountRole
      accountStatus
    }
  `,
};

/**
 * Set the Spoofed user in the browser's cookies with the relevant user data.
 * Make sure to utilize this function's User fragment within any component this
 * function is utilized.
 * @param userData
 */
export function setSpoofUser(userData: SetSpoofUser_UserFragment): void {
  const spoofUser: SetSpoofUser_UserFragment = {
    id: userData.id,
    fullName: userData.fullName,
    email: userData.email,
    accountRole: userData.accountRole,
    accountStatus: userData.accountStatus,
  };
  Cookies.set("spoof-user", JSON.stringify(spoofUser), getCookieOptions());
}

/**
 * Get the current Spoofed user (if there currently is one).
 * @returns the current Spoofed user or undefined if there is none.
 */
export function getSpoofUser(): SetSpoofUser_UserFragment | undefined {
  try {
    const spoofUser: SetSpoofUser_UserFragment = JSON.parse(
      Cookies.get("spoof-user") ?? ""
    );
    return spoofUser;
  } catch (_) {
    return undefined;
  }
}
