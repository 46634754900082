import { gql, useLazyQuery } from "@apollo/client";
import { GetAttendanceDataQuery } from "@generated/graphql";
import { SECOND_MS } from "@utils/dateTime";
import { useEffect, useRef, useState } from "react";
import { AttendanceData } from "./types";
import { AttendanceContext } from "./useAttendanceData";

const ATTENDANCE_DATA_QUERY = gql`
  query GetAttendanceData($cohortIds: [ID!]!) {
    cohortMeetings(cohortIds: $cohortIds) {
      id
      hosts
      participants
    }
  }
`;

type Props = { children: React.ReactNode };

export const AttendanceDataProvider = ({ children }: Props) => {
  const [attendanceData, setAttendanceData] = useState<AttendanceData>({});
  const cohortIdsToFetch = useRef<Set<string>>(new Set());

  const [getAttendanceData] = useLazyQuery<GetAttendanceDataQuery>(
    ATTENDANCE_DATA_QUERY
  );

  useEffect(() => {
    const fetchAll = async () => {
      if (cohortIdsToFetch.current.size === 0) return;

      const { data } = await getAttendanceData({
        variables: {
          cohortIds: Array.from(cohortIdsToFetch.current),
        },
        fetchPolicy: "network-only",
      });

      if (!data) return;

      const newAttendanceData = data.cohortMeetings.reduce<AttendanceData>(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );

      setAttendanceData((prevData) => ({ ...prevData, ...newAttendanceData }));
    };

    let intervalId: NodeJS.Timeout;

    const initialTimeoutId = setTimeout(() => {
      fetchAll();

      intervalId = setInterval(fetchAll, SECOND_MS * 10);
    }, SECOND_MS);

    return () => {
      clearTimeout(initialTimeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [getAttendanceData]);

  const includeCohortInDataFetch = (id: string) => {
    cohortIdsToFetch.current.add(id);
  };

  return (
    <AttendanceContext.Provider
      value={{ attendanceData, includeCohortInDataFetch }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};
