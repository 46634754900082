import { createContext, useContext, useState } from "react";
import { Breadcrumb, VisitorDetails } from "types/global";
import { initVisitorDetails } from "./constants";

const initialState = {
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  visitorDetails: initVisitorDetails,
  setVisitorDetails: () => {},
};

interface BreadcrumbsContextT {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  visitorDetails: VisitorDetails;
  setVisitorDetails: (visitorDetails: VisitorDetails) => void;
}

const BreadcrumbsContext = createContext<BreadcrumbsContextT>(initialState);

type Props = { children: React.ReactNode };

export const BreadcrumbsProvider = ({ children }: Props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(
    initialState.breadcrumbs
  );
  const [visitorDetails, setVisitorDetails] = useState<VisitorDetails>(
    initialState.visitorDetails
  );

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
        visitorDetails,
        setVisitorDetails,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
