import { PdCourseStatus } from "@generated/graphql";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  courseStatus: PdCourseStatus;
  className?: string;
};

export const PDCourseStatusBadge = ({ courseStatus, className }: Props) => (
  <Badge className={clsx(courseStatusColors[courseStatus], className)}>
    {courseStatusText[courseStatus]}
  </Badge>
);

const courseStatusColors: { [key in PdCourseStatus]: string } = {
  [PdCourseStatus.Complete]: "text-emerald-900 bg-emerald-200",
  [PdCourseStatus.NotStarted]: "text-amber-900 bg-amber-200",
  [PdCourseStatus.InProgress]: "text-orange-900 bg-orange-200",
  [PdCourseStatus.Overdue]: "text-rose-900 bg-rose-200",
  [PdCourseStatus.Cancelled]: "text-gray-900 bg-gray-200",
  [PdCourseStatus.Expired]: "text-gray-900 bg-gray-200",
};

const courseStatusText: { [key in PdCourseStatus]: string } = {
  [PdCourseStatus.Complete]: "Complete",
  [PdCourseStatus.NotStarted]: "Not Started",
  [PdCourseStatus.InProgress]: "In Progress",
  [PdCourseStatus.Overdue]: "Overdue",
  [PdCourseStatus.Cancelled]: "Cancelled",
  [PdCourseStatus.Expired]: "Expired",
};
