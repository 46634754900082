import { Editor } from "@tiptap/react";
import clsx from "clsx";
import { SelectMenu, SelectMenuOption } from "components/shared/Inputs";
import { HEADING_EVENT_MAP, HEADING_OPTIONS } from "./constants";

type Props = {
  editor: Editor | null;
  className?: string;
};

export const HeadingSelect = ({ editor, className }: Props) => {
  const onSelect = (option: SelectMenuOption) => {
    if (!option) return;
    if (editor) HEADING_EVENT_MAP[option.id](editor);
  };

  if (!editor) return null;

  return (
    <div className={clsx(className)}>
      <SelectMenu
        options={HEADING_OPTIONS}
        onSelect={onSelect}
        initialIndex={3}
      />
    </div>
  );
};
