import clsx from "clsx";
import { Icon, IconType } from "./Icon";

interface Props {
  icon: IconType;
  forHeader?: boolean;
  child: React.ReactNode | string;
  link?: boolean;
}

export const IconText = ({
  icon,
  child,
  link = false,
  forHeader = false,
}: Props) => (
  <div
    className={clsx(
      "flex items-center text-sm",
      link && forHeader && "text-cyan-700 font-bold",
      link && !forHeader && "text-blue-600 font-semibold",
      !link && forHeader && "text-cyan-900 font-bold",
      !link && !forHeader && "text-gray-600",
      link && "cursor-pointer"
    )}
  >
    <Icon
      icon={icon}
      size={5}
      color={clsx(forHeader ? "text-cyan-900" : "text-slate-600")}
      className={clsx(forHeader ? "mr-[3px]" : "flex-shrink-0 mr-1.5")}
    />
    {child}
  </div>
);
