import { createContext, useContext } from "react";
import { AttendanceContextType } from "./types";

export const AttendanceContext = createContext<
  AttendanceContextType | undefined
>(undefined);

export const useAttendanceData = (): AttendanceContextType => {
  const context = useContext(AttendanceContext);
  if (!context) {
    throw new Error(
      "useAttendanceData must be used within an AttendanceProvider"
    );
  }
  return context;
};
