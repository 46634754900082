import { AccountRole } from "@generated/graphql";
import { AdminMode } from "types/global";
import { UnauthenticatedUser } from "./types";

const { Staffing, Managing, ControlPanel, Dashboard } = AdminMode;
const { MentorTeacher, TutorTeacher, Admin, Visitor } = AccountRole;

export const PRIMARY_MARKETING_URL = "https://tutored.live";

export const AllAccessPermitted = [
  MentorTeacher,
  TutorTeacher,
  Visitor,
  Admin,
  UnauthenticatedUser.NonAuthedUser,
];

export const AllAuthedRolesPermitted = [
  MentorTeacher,
  TutorTeacher,
  Visitor,
  Admin,
];

export const AllAdminModesPermitted = [
  Staffing,
  Managing,
  Dashboard,
  ControlPanel,
];
