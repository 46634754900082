import { Routes } from "@utils/routes";

export const unauthenticated = {
  cognitoUser: null,
  user: null,
  token: null,
  isAuthenticating: false,
};

export const loginReroute = { route: Routes.login };
