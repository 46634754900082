import { AccountRole } from "@generated/graphql";
import { AccountRoleTextMode, getAccountRoleText } from "@utils/labels";
import { useAuth } from "contexts/AuthProvider";

type Props = {
  accountRole?: AccountRole;
  className?: string;
  mode?: AccountRoleTextMode;
};

export function AccountRoleText({
  accountRole,
  className = "text-gray-700",
  mode = "long",
}: Props) {
  const { accountRole: currRole } = useAuth();
  const accountRoleText = getAccountRoleText(accountRole || currRole, mode);
  return <span className={className}>{accountRoleText}</span>;
}
