import { Tooltip } from "components/shared";
import { useAttendanceData } from "contexts/AttendanceDataProvider";
import { subMinutes } from "date-fns";
import { useEffect } from "react";
import { TbAlertTriangle, TbMoodHappy } from "react-icons/tb";

interface Props {
  cohortId: string;
  cohortSessionStartTime: Date;
  cohortSessionEndTime: Date;
}

const MEETING_SOON_BUFFER = 15;

export const HostAttendanceIndicator = ({
  cohortId,
  cohortSessionStartTime,
  cohortSessionEndTime,
}: Props) => {
  const { attendanceData, includeCohortInDataFetch } = useAttendanceData();

  useEffect(() => {
    includeCohortInDataFetch(cohortId);
  }, [cohortId, includeCohortInDataFetch]);

  const data = attendanceData[cohortId];

  const currentDateTime = new Date();

  const fifteenMinutesBeforeStart = subMinutes(
    cohortSessionStartTime,
    MEETING_SOON_BUFFER
  );

  const meetingIsStartingSoon =
    currentDateTime > fifteenMinutesBeforeStart &&
    currentDateTime < cohortSessionStartTime;

  const meetingIsOngoing =
    currentDateTime > cohortSessionStartTime &&
    currentDateTime < cohortSessionEndTime;

  const hostCount = data?.hosts;
  if (hostCount === undefined || hostCount === null) return null;

  let icon, content;
  switch (true) {
    case hostCount === 0 && meetingIsStartingSoon:
      content = "Session is starting soon and no teachers are present.";
      icon = <TbAlertTriangle className="mt-2 h-7 w-7 text-orange-500" />;
      break;
    case hostCount === 0 && meetingIsOngoing:
      content =
        "Urgent: There are no teachers present in this ongoing session.";
      icon = <TbAlertTriangle className="mt-2 h-7 w-7 text-rose-500" />;
      break;
    case hostCount > 0 && (meetingIsOngoing || meetingIsStartingSoon):
      content = "There is currently a teacher present in this session.";
      icon = <TbMoodHappy className="mt-2 h-7 w-7 text-green-500" />;
      break;
    default:
      return null;
  }

  return (
    <Tooltip
      content={content}
      tooltipProps={{ place: "left" }}
      className="flex items-center cursor-pointer"
    >
      {icon}
    </Tooltip>
  );
};
