import { AbsentType, GradeType, StudentGrades } from "./types";

export const hasSomeTruthyGrades = (
  grades: StudentGrades,
  gradeOptions: GradeType[]
) =>
  gradeOptions.some(
    (type) => grades[type] !== null && grades[type] !== undefined
  );

export const hasSomeAbsentGrades = (
  grades: StudentGrades,
  absentOptions: AbsentType[]
) => absentOptions.some((type) => grades[type] !== false);
