import { AccountStatus } from "@generated/graphql";
import { getAccountStatusText } from "@utils/labels";
import clsx from "clsx";
import { Badge } from "./Badge";

type Props = {
  accountStatus: AccountStatus;
  className?: string;
};

export const AccountStatusBadge = ({ accountStatus, className }: Props) => (
  <Badge className={clsx(accountStatusColors[accountStatus], className)}>
    {getAccountStatusText(accountStatus)}
  </Badge>
);

const accountStatusColors: { [key in AccountStatus]: string } = {
  [AccountStatus.Active]: "text-emerald-900 bg-emerald-200",
  [AccountStatus.Pending]: "text-orange-900 bg-orange-200",
  [AccountStatus.Inactive]: "text-rose-900 bg-rose-200",
};
