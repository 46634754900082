import { ApolloProvider } from "@apollo/client";
import { Session } from "@lib/apollo-client";
import { Layout } from "components/shared";
import { Spinner } from "components/shared/Loading";
import React from "react";

type Props = {
  session: Session | null;
  children: React.ReactNode;
};

export const ApolloWrapper = ({ session, children }: Props) =>
  session ? (
    <ApolloProvider client={session.client}>{children}</ApolloProvider>
  ) : (
    <Layout>
      <div className="flex h-screen w-full items-center justify-center pb-[25%]">
        <Spinner size={8} color="text-blue-500" />
      </div>
    </Layout>
  );
