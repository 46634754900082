import Bugsnag, { NotifiableError } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import isString from "lodash/isString";
import { PHASE_PRODUCTION_BUILD } from "next/constants";

export function startBugsnag() {
  // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
  // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
  // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L19-L23 for
  // more details on NEXT_PHASE
  if (
    process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD &&
    process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
  ) {
    Bugsnag.start({
      appType: "web",
      appVersion: process.env.NEXT_BUILD_ID,
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.NEXT_PUBLIC_BUGSNAG_STAGE,
      enabledReleaseStages: ["production", "staging"],
      enabledErrorTypes: {
        unhandledExceptions: true,
        unhandledRejections: false,
      },
    });
  }
}

export function isBugsnagNotifiableError(
  error: unknown
): error is NotifiableError {
  if (error instanceof Error) {
    return true;
  }

  if (isString(error)) {
    return true;
  }

  const errorAsObject = error as Record<string, string>;

  if (
    errorAsObject.errorClass !== undefined &&
    errorAsObject.errorMessage !== undefined
  ) {
    return true;
  }

  if (errorAsObject.name !== undefined && errorAsObject.message !== undefined) {
    return true;
  }

  return false;
}

type BugsnagUser = { id?: string; email?: string; fullName?: string } | null;

export type BugsnagNotifyInput<U extends BugsnagUser> = {
  error: unknown;
  componentStack?: string;
  user?: U;
  unhandled?: boolean;
};

export function notifyBugsnag<U extends BugsnagUser>({
  error,
  componentStack,
  user,
  unhandled = false,
}: BugsnagNotifyInput<U>) {
  if (isBugsnagNotifiableError(error)) {
    const reactComponentStack = componentStack
      ? formatComponentStack(componentStack)
      : undefined;

    Bugsnag.notify(error, function onError(event) {
      event.unhandled = unhandled;

      if (reactComponentStack) {
        event.addMetadata("react", { componentStack: reactComponentStack });
      }

      event.setUser(
        user?.id ?? "User's ID not available",
        user?.email ?? "User's email not available",
        user?.fullName ?? "User's name not available"
      );
    });
  } else {
    console.warn("[🐛 Bugsnag] - Encountered an unnotifiable error", error);
  }
}

// Lifted from bugsnag source
function formatComponentStack(str: string): string {
  const lines = str.split(/\s*\n\s*/g);
  let ret = "";
  for (let line = 0, len = lines.length; line < len; line++) {
    if (lines[line].length) {
      ret += `${ret.length ? "\n" : ""}${lines[line]}`;
    }
  }
  return ret;
}
