import { useGroupFilterInputs } from "contexts/FilterInputsProvider";
import { Filters } from "../Filters";
import { FilterType } from "../types";

type Props = { tableName: string; filterType: FilterType };

export const ActiveTableFilters = ({ tableName, filterType }: Props) => {
  const { getFilterBoolean, setFilterBoolean } =
    useGroupFilterInputs(tableName);

  return (
    <Filters
      filters={getFilters(getFilterBoolean, setFilterBoolean, filterType)}
    />
  );
};

const getFilters = (
  getFilterBoolean: (key: string) => boolean | null,
  setFilterBoolean: (key: string, value: boolean) => void,
  filterType: FilterType
) => [
  {
    name: "All",
    selected: !getFilterBoolean("activeCohortsOnly"),
    tooltip: `Show All ${filterType}`,
    onClick: () => {
      setFilterBoolean("activeCohortsOnly", false);
    },
  },
  {
    name: "Active",
    selected: getFilterBoolean("activeCohortsOnly") ?? false,
    tooltip: getTooltip(filterType),
    onClick: (newState: boolean) => {
      setFilterBoolean("activeCohortsOnly", newState);
    },
  },
];

const getTooltip = (filterType: FilterType) => {
  switch (filterType) {
    case FilterType.Organizations:
      return "Show only the Organizations with Cohorts that have recently been active.";
    case FilterType.Engagements:
      return "Show only the Engagements with Cohorts that recently have been active.";
    case FilterType.Cohorts:
      return "Show only Cohorts that have recently been active.";
    default:
      return "Show only Cohorts that have recently been active.";
  }
};
