import { capitalizeFirstLetter, lowerString } from "@utils/strings";
import { Icon, IconType } from "./Icon";

export type ChevronDirection = "UP" | "DOWN" | "LEFT" | "RIGHT";

export type Props = {
  direction: ChevronDirection;
  size?: number;
  color?: string;
  className?: string;
};

export const Chevron = ({
  direction,
  className,
  size = 6,
  color = "text-blue-500",
}: Props) => (
  <Icon
    icon={`chevron${capitalizeFirstLetter(lowerString(direction))}` as IconType}
    size={size}
    color={color}
    className={className}
  />
);
