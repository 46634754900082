import { Details, Tooltip } from "components/shared";
import { ExcludedDaysScrollContainer } from "components/shared/ExcludedDays/ExcludedDaysScrollContainer";

type Props = {
  excludedDays: Date[];
  onClick: () => void;
  title: string;
  tooltip: string;
  emptyMessage?: string;
};

export const ExcludedDayPanel = ({
  excludedDays,
  onClick,
  title,
  tooltip,
  emptyMessage = "No Days Selected",
}: Props) => (
  <Details.Line>
    <Details.Term>
      <div className="flex items-center text-sm font-medium text-gray-500 gap-2">
        {title}
        <Tooltip
          className="w-5 h-5 text-blue-600 mr-2 cursor-help"
          content={
            <div className="block w-48 text-sm text-center">{tooltip}</div>
          }
        />
      </div>
    </Details.Term>
    <div className="flex w-full pr-4 sm:col-span-2 max-w-[450px]">
      <ExcludedDaysScrollContainer
        onClick={onClick}
        excludedDays={excludedDays}
        noDaysMessage={emptyMessage}
      />
    </div>
  </Details.Line>
);
