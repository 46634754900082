import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { TablePagination } from "components/shared";
import { CONTEXT_MENU_ID } from "components/shared/Table/constants";
import { useState } from "react";
import {
  Cell,
  Column,
  Row,
  UseTableOptions,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { EmptyTableState } from "./EmptyTableState";

// NEEDS TO BE UPDATED (BY KEVIN) ==> START OF ATTEMPT TO MERGE MANUAL TABLE AND TABLE

type Props<D extends Record<string, unknown>> = {
  loading: boolean;
  className?: string;
  hasData: boolean;
  showPagination?: boolean;
  onCellClick?: (row: Row<D>, cell: Cell<D>) => void;
  onRowClick?: (row: Row<D>) => void;
  dataName?: string;
  preventRowClick: boolean;
  columns: Column<D>[];
  data: D[];
  padCells?: boolean;
  emptyIcon?: React.ReactNode;
  initialState?: UseTableOptions<D>["initialState"];
  verticalDividers?: boolean;
};

export const RenderedTable = <D extends { id: string }>({
  dataName,
  className,
  loading,
  columns,
  data,
  padCells = true,
  preventRowClick = false,
  showPagination = true,
  verticalDividers = false,
  emptyIcon,
  initialState,
  onRowClick,
  onCellClick,
}: Props<D>) => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    //Pagination
    page,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    usePagination
  );
  const hasData = page.length > 0;
  const hasPagination = pageCount > 1;

  const onRowSelection = (row: Row<D>) => {
    onRowClick && setSelectedRow(row.original.id);
    onRowClick && onRowClick(row);
  };

  const onCellSelection = (row: Row<D>, cell: Cell<D>) => {
    onCellClick && onCellClick(row, cell);
  };

  const renderTableRows = page.map((row) => {
    prepareRow(row);
    const isRowSelected = row.original.id === selectedRow;
    const onRowClickHandler = onRowClick
      ? { onClick: () => onRowClick(row) }
      : {};

    return (
      // eslint-disable-next-line react/jsx-key
      <tr
        {...row.getRowProps()}
        className={clsx(
          onRowClick ? "cursor-pointer" : "cursor-default",
          isRowSelected ? "bg-blue-100" : "hover:bg-gray-50",
          verticalDividers && "divide-gray-200 divide-x"
        )}
        {...onRowClickHandler}
      >
        {row.cells.map((cell) => {
          const cellToRender =
            cell.column.id === CONTEXT_MENU_ID ? (
              <div className="flex justify-center">{cell.render("Cell")}</div>
            ) : (
              cell.render("Cell")
            );

          return (
            // eslint-disable-next-line react/jsx-key
            <td
              {...cell.getCellProps()}
              className={clsx(
                cell.column.id === CONTEXT_MENU_ID || !padCells
                  ? "px-0 py-0"
                  : "px-4 py-3",
                "text-gray-500 text-sm",
                cell.value?.cellClassNameFunc &&
                  cell.value.cellClassNameFunc(cell.value.shiftCellData)
              )}
              onClick={(e) => {
                if (preventRowClick || cell.column.id === CONTEXT_MENU_ID) {
                  e.stopPropagation();
                }
                onCellSelection(row, cell);
              }}
            >
              {cellToRender}
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <div className={clsx("w-full h-fit rounded-lg shadow-sm")}>
      <div
        className={clsx(
          "bg-white border-gray-200 overflow-x-auto border rounded-lg",
          hasData && !className ? "min-h-[570px]" : className,
          hasData &&
            showPagination &&
            !hasPagination &&
            "border-b-0 rounded-b-none",
          getScrollbarStyle("gray")
        )}
      >
        {hasData && (
          <table
            className="min-w-full divide-gray-200 divide-y"
            {...getTableProps()}
          >
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={clsx(
                    verticalDividers && "divide-gray-200 divide-x"
                  )}
                >
                  {headerGroup.headers.map((column) => (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      scope="col"
                      className={clsx(
                        column.id === CONTEXT_MENU_ID
                          ? "w-14"
                          : "w-auto px-4 py-3",
                        "text-left text-gray-500 text-xs font-medium tracking-wider uppercase",
                        column.width
                      )}
                      {...column.getHeaderProps()} //key is in here.
                    >
                      <div className="flex">
                        <div
                          className="flex"
                          {...(column.id === CONTEXT_MENU_ID
                            ? {}
                            : column.getSortByToggleProps())}
                        >
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownIcon className="ml-2 h-4 w-4" />
                            ) : (
                              <ArrowUpIcon className="ml-2 h-4 w-4" />
                            )
                          ) : (
                            <div className="ml-2 w-4" />
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody
              className="bg-white divide-gray-200 divide-y"
              {...getTableBodyProps()}
              data-test="table"
            >
              {renderTableRows}
            </tbody>
          </table>
        )}

        {!hasData ||
          (loading && (
            <EmptyTableState
              dataName={dataName}
              emptyIcon={emptyIcon}
              loading={loading}
            />
          ))}
      </div>

      {hasData && showPagination && hasPagination && (
        <TablePagination
          pageIndex={pageIndex}
          totalResultCount={rows.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      )}
    </div>
  );
};
