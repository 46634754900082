import { gql } from "@apollo/client";
import { CreatorUpdaterLabel_UserFragment } from "@generated/graphql";
import { AccountRoleBadge } from "./Badges";

CreatorUpdaterLabel.fragments = {
  user: gql`
    fragment CreatorUpdaterLabel_User on User {
      id
      fullName
      email
      accountRole
    }
  `,
};

type Props = {
  user?: CreatorUpdaterLabel_UserFragment | null;
  className?: string;
  fallbackName?: string;
};

export function CreatorUpdaterLabel({
  user,
  className,
  fallbackName = "System",
}: Props) {
  return (
    <div className={className}>
      {user ? (
        <div className="flex items-center gap-x-1 flex-wrap">
          {`${user.fullName} (${user.email})`}
          <AccountRoleBadge
            accountRole={user.accountRole}
            mode="short"
            className="border border-slate-200 my-[-1px]"
          />
        </div>
      ) : (
        fallbackName
      )}
    </div>
  );
}
