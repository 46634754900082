import { optionsArrayContainerItemStyle } from "@utils/styleStrings";
import { DatePicker } from "antd";
import { DatePickerProps } from "antd/lib/date-picker";
import clsx from "clsx";
import { ErrorBox, Icon, Modal } from "components/shared";
import { addMonths, format, isSameDay } from "date-fns";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { disabledDay } from "../Calendars/utils";
import { OptionsArrayContainer } from "../Inputs/OptionsArrayContainer";
import { excludedDaysCellRenderer } from "./helpers";

type Props = {
  show: boolean;
  endDate: Date;
  startDate: Date;
  loading: boolean;
  errorMsg: string | null;
  tripleCalendarView: boolean;
  primaryExcludedDays: Date[];
  secondaryExcludedDays?: Date[];
  dayType?: "No-Show" | "Assessment";
  closeModal: () => void;
  onEdit: (excludedDays: Date[]) => void;
};

export const ExcludedDaysModalBody = ({
  show,
  endDate,
  loading,
  errorMsg,
  startDate,
  tripleCalendarView,
  dayType = "No-Show",
  primaryExcludedDays,
  secondaryExcludedDays,
  onEdit,
  closeModal,
}: Props) => {
  const [showCalendars, setShowCalendars] = useState(true);
  const calendarArray = tripleCalendarView ? [-1, 0, 1] : [0];
  const [targetMonth, setTargetMonth] = useState<Date>(new Date());
  const [updatedPrimaryExcludedDays, setUpdatedPrimaryExcludedDays] =
    useState(primaryExcludedDays);

  useEffect(() => {
    if (!show) setShowCalendars(false);
  }, [show]);

  const handleModalClose = () => {
    closeModal();
    setUpdatedPrimaryExcludedDays(primaryExcludedDays);
  };

  const handleDayChange: DatePickerProps["onChange"] = (date) => {
    if (date) {
      updatedPrimaryExcludedDays.some((d) => isSameDay(d, date?.toDate()))
        ? removeDate(date?.toDate())
        : addDate(date?.toDate());
    }
  };

  const removeDate = (date: Date) => {
    setUpdatedPrimaryExcludedDays(
      updatedPrimaryExcludedDays.filter((d) => !isSameDay(date, d))
    );
  };

  const addDate = (date: Date) => {
    setUpdatedPrimaryExcludedDays([...updatedPrimaryExcludedDays, date]);
  };

  const cellRender = (current: dayjs.Dayjs, monthOffset: number) =>
    excludedDaysCellRenderer(
      current,
      monthOffset,
      targetMonth,
      updatedPrimaryExcludedDays,
      secondaryExcludedDays ?? []
    );

  const renderExcludedDayItems = updatedPrimaryExcludedDays
    .sort((a, b) => a.getTime() - b.getTime())
    .map((day, i) => (
      <div
        key={i}
        onClick={() => removeDate(day)}
        className={clsx("!w-[130px]", optionsArrayContainerItemStyle("remove"))}
      >
        <div className="pl-1">{format(day, "MMM d, yyyy")}</div>
        <Icon icon="remove" size={5} color="text-rose-500" />
      </div>
    ));

  return (
    <div className="flex flex-col h-auto gap-6 w-full justify-start mt-8 lg:mt-0">
      <div className="flex h-[275px] relative justify-center items-center w-full gap-1">
        {showCalendars && (
          <>
            <div
              className={clsx(
                "flex items-center justify-center h-full text-slate-700 z-10",
                "w-[35px] rounded-md hover:bg-slate-200 cursor-pointer"
              )}
              onClick={() => setTargetMonth(addMonths(targetMonth, -1))}
            >
              <Icon icon="chevronLeft" size={12} color="text-slate-600" />
            </div>

            <div className="flex h-full flex-row justify-center items-start w-full gap-4">
              {calendarArray.map((_, i) => (
                <div key={i} className="flex relative w-[289px]">
                  <DatePicker
                    autoFocus
                    open={show}
                    prevIcon={null}
                    showNow={false}
                    nextIcon={null}
                    superPrevIcon={null}
                    superNextIcon={null}
                    popupStyle={{ zIndex: 40 }}
                    value={dayjs(addMonths(targetMonth, i))}
                    defaultValue={dayjs(addMonths(targetMonth, i))}
                    style={{ visibility: "hidden", height: 0, top: "-13px" }}
                    onChange={handleDayChange}
                    cellRender={(current) => cellRender(dayjs(current), i)}
                    disabledDate={(date) =>
                      disabledDay(date, startDate, endDate)
                    }
                  />
                </div>
              ))}
            </div>

            <div
              className={clsx(
                "flex items-center justify-center h-full text-slate-700 z-10",
                "w-[35px] rounded-md hover:bg-slate-200 cursor-pointer"
              )}
              onClick={() => setTargetMonth(addMonths(targetMonth, 1))}
            >
              <Icon icon="chevronRight" size={12} color="text-slate-600" />
            </div>
          </>
        )}
      </div>

      <OptionsArrayContainer
        hasItems={updatedPrimaryExcludedDays.length > 0}
        label={`Selected ${dayType} Days`}
        minHeight="min-h-[90px]"
        noItemsText={`Zero ${dayType} days have been selected`}
        className="lg:px-10"
        items={renderExcludedDayItems}
      />

      <ErrorBox msg={errorMsg} className="px-1 lg:px-10" />

      <Modal.Buttons>
        <Modal.Button
          type="confirm"
          onClick={() => onEdit(updatedPrimaryExcludedDays)}
          loading={loading}
          disabled={isEqual(primaryExcludedDays, updatedPrimaryExcludedDays)}
        >
          Save
        </Modal.Button>
        <Modal.Button type="cancel" onClick={handleModalClose}>
          Cancel
        </Modal.Button>
      </Modal.Buttons>
    </div>
  );
};
