import clsx from "clsx";
import { PropsWithChildren } from "react";

type Props = { className?: string };

export const PageHeaderDescriptionText = ({
  children,
  className,
}: PropsWithChildren<Props>) => (
  <div className={clsx("text-sm font-medium text-gray-500", className)}>
    {children}
  </div>
);
