import clsx from "clsx";

type Props = {
  className?: string;
};
export function LoadingText({ className }: Props) {
  return (
    <div className={clsx("h-8 relative", className)}>
      <div className="loader rounded-full h-[18px] bg-slate-300" />
      <div className="loaderDelayed rounded-full bg-slate-200 h-[10px] ml-1 mt-2" />
    </div>
  );
}
