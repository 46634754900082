import { CohortAssignmentSubject } from "@generated/graphql";
import { getCohortSubjectText } from "@utils/labels";

type Props = {
  cohortSubject: CohortAssignmentSubject;
  className?: string;
};

export const CohortSubjectText = ({ cohortSubject, className }: Props) => (
  <span className={className}>{getCohortSubjectText(cohortSubject)}</span>
);
