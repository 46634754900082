import clsx from "clsx";
import { CgSpinnerTwo } from "react-icons/cg";
import { HiCheck } from "react-icons/hi2";
import { TbKeyboard } from "react-icons/tb";

type Props = {
  initializeChange: boolean;
  finalizeChange: boolean;
  className?: string;
  reverse?: boolean;
};

export function AttendanceUpdateStatusIndicator({
  initializeChange,
  finalizeChange,
  className,
  reverse,
}: Props) {
  const keyboard = (
    <div className="relative">
      <TbKeyboard className="w-full h-full absolute animate-ping opacity-50" />
      <TbKeyboard className="w-full h-full" />
    </div>
  );
  const spinner = <CgSpinnerTwo className="w-full h-full animate-spin" />;

  return (
    <div className={clsx("w-6 h-6", className ? className : "text-yellow-900")}>
      {initializeChange ? (
        reverse ? (
          spinner
        ) : (
          keyboard
        )
      ) : finalizeChange ? (
        reverse ? (
          keyboard
        ) : (
          spinner
        )
      ) : (
        <HiCheck className="w-full h-full opacity-80" />
      )}
    </div>
  );
}
