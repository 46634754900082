import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { forwardRef, MouseEventHandler } from "react";
import { FieldError } from "../Error/FieldError";

type Props = {
  id: string;
  name?: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  description?: string;
  defaultValue?: string;
  rows?: number;
  placeholder?: string;
  leftIcon?: (props: React.ComponentProps<"svg">) => JSX.Element;
  className?: string;
  textAreaClassName?: string;
  onClick?: MouseEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  error?: string;
};

function TextAreaForwardRef(
  {
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    rows = 5,
    required = false,
    description,
    placeholder,
    defaultValue,
    textAreaClassName,
    leftIcon: LeftIcon,
    className,
    onClick,
    disabled = false,
    error,
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>
) {
  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {label ? (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}{" "}
          {required && (
            <span className="text-gray-800 text-sm font-semibold">*</span>
          )}
        </label>
      ) : (
        // Screen reader only
        <label htmlFor={id} className="sr-only">
          {placeholder ?? "TextArea"}
        </label>
      )}

      <div className="relative">
        {LeftIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <LeftIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <textarea
          id={id}
          className={clsx(
            disabled && "bg-gray-50 text-gray-400 ",
            "shadow-sm block w-full h-150 sm:text-sm border-gray-300 rounded-md",
            "focus:ring-blue-500 focus:border-blue-500",
            "placeholder-gray-500 placeholder-opacity-50",
            LeftIcon && " pl-10 ",
            getScrollbarStyle("gray"),
            textAreaClassName
          )}
          aria-describedby={`${id}-input-description`}
          required={required}
          ref={ref}
          disabled={disabled}
          defaultValue={defaultValue}
          name={name}
          rows={rows}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={onBlur}
        />
      </div>
      {description && (
        <p className={clsx("mt-2 text-sm ")} id={`${id}-input-description`}>
          {description}
        </p>
      )}
      <FieldError msg={error} />
    </div>
  );
}

TextAreaForwardRef.displayName = "TextArea";

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  TextAreaForwardRef
);
