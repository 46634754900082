import { Pixel } from "@utils/numbers";
import { useEffect, useRef, useState } from "react";

type Props = {
  src: string;
  title: string;
  height: Pixel;
};

export function AutoWidthIFrame({ src, title, height }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<Pixel>(0);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    // Initial update
    updateDimensions();

    // Add event listener for window resize
    window.addEventListener("resize", updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <div ref={containerRef} className="w-full h-full border rounded-sm">
      <iframe
        src={src}
        title={title}
        width={Math.max(width - 3, 200)}
        height={height}
      />
    </div>
  );
}
