import clsx from "clsx";
import { forwardRef, MouseEventHandler, useId } from "react";
import { FieldError } from "../Error/FieldError";

type Props = {
  id: string;
  name?: string;
  label?: React.ReactNode;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  type?: HTMLInputElement["type"];
  required?: boolean;
  description?: React.ReactNode;
  defaultValue?: string;
  placeholder?: string;
  leftIcon?: (props: React.ComponentProps<"svg">) => JSX.Element;
  className?: string;
  inputClassName?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  disabled?: boolean;
  highlightOnInput?: boolean;
  error?: string;
};

function InputForwardRef(
  {
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    type = "text",
    required = false,
    description,
    placeholder,
    defaultValue,
    leftIcon: LeftIcon,
    className,
    onClick,
    inputClassName,
    disabled = false,
    highlightOnInput = false,
    error,
  }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const descriptionId = useId();

  const highlightActive = highlightOnInput && value?.trim();

  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {label ? (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}{" "}
          {required && (
            <span className="text-gray-500 text-sm font-light">*</span>
          )}
        </label>
      ) : (
        // Screen reader only
        <label htmlFor={id} className="sr-only">
          {placeholder ?? "Input"}
        </label>
      )}

      <div className="relative">
        {LeftIcon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <LeftIcon
              className={clsx(
                "h-5 w-5",
                highlightActive
                  ? "text-indigo-500 animate-bob"
                  : "text-gray-400"
              )}
              aria-hidden="true"
            />
          </div>
        )}
        <input
          id={id}
          type={type}
          className={clsx(
            disabled && "bg-gray-50 text-gray-400",
            "shadow-sm block w-full sm:text-sm border-gray-300 rounded-md",
            "focus:ring-blue-500 focus:border-blue-500",
            highlightActive &&
              "bg-gradient-to-r from-blue-50 from-0% via-transparent via-25%",
            LeftIcon && "pl-10",
            inputClassName
          )}
          aria-describedby={descriptionId}
          required={required}
          ref={ref}
          disabled={disabled}
          defaultValue={defaultValue}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={onBlur}
        />
      </div>
      <FieldError msg={error} />
      {description && (
        <div
          className={clsx(
            "ml-1 mt-2",
            typeof description === "string" && "text-sm"
          )}
          id={descriptionId}
        >
          {description}
        </div>
      )}
    </div>
  );
}

InputForwardRef.displayName = "Input";

export const Input = forwardRef<HTMLInputElement, Props>(InputForwardRef);
