type Props = { rows?: number };

export const LoadingTableSkeleton = ({ rows = 6 }: Props) => (
  <div className="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg p-6">
    <div className="flex space-x-4 animate-pulse">
      <div className="flex-1 py-1 space-y-6">
        {[...Array(rows)].map((_, i) => (
          <div key={i} className="w-full h-4 bg-gray-200 rounded" />
        ))}
      </div>
    </div>
  </div>
);
