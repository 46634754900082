import clsx from "clsx";

type Props = {
  className: string;
  children: React.ReactNode;
};

export const Badge = ({ className, children }: Props) => (
  <span
    className={clsx(
      "flex items-center justify-center text-center h-fit w-fit px-3 py-0.5 rounded-full text-xs font-medium",
      className
    )}
  >
    {children}
  </span>
);
