import { ApolloProvider } from "@apollo/client";
import { UnauthenticatedSession } from "@lib/apollo-client";

type Props = {
  session: UnauthenticatedSession;
  children: React.ReactNode;
};

export const UnauthenticatedApolloWrapper = ({ session, children }: Props) => (
  <ApolloProvider client={session.client}>{children}</ApolloProvider>
);
